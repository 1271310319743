import clsx from 'clsx';
import React, { useRef } from 'react';
import Modal from 'react-modal';

import Button from '@/components/global/Button';

import Icon from '../Icon';
import styles from './Modal.module.scss';
import { GetInTouchModalTemplate } from './partials';
import type { ModalProps } from './types';

Modal.setAppElement('#root');

const customStyles = ({ splitModal, cardModal, modalPadding, template }) => {
  if (splitModal)
    return {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '24px',
        border: 'none',
        padding: '0'
      }
    };

  if (cardModal)
    return {
      content: {}
    };

  return {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '24px',
      border: 'none',
      ...(!template && {
        padding: modalPadding || 'var(--spacing-m, 24px)'
      }),
      maxWidth: '90vw',
      maxHeight: '90vh'
    }
  };
};

const ModalComponent: React.FC<ModalProps> = ({
  ModalTitle,
  ModalButtonLabel,
  splitModal,
  controlled,
  maxWidth = '500px',
  controlledOpen,
  controlledSetOpen,
  modalPadding,
  buttonSize = 's',
  buttonVariant = 'secondary',
  buttonPosition = 'left',
  closeButtonColor = 'var(--color-primary)',
  children,
  cardModal,
  template,
  asWrapper,
  showWrapperVerticalScroll = false
}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    controlled ? controlledSetOpen(true) : setIsOpen(true);
  }

  function closeModal() {
    controlled ? controlledSetOpen(false) : setIsOpen(false);
  }

  const modalButton = splitModal ? (
    <Button callBack={openModal} className={styles.modalTriggerSplit} type="button">
      {ModalButtonLabel}
    </Button>
  ) : (
    <Button
      callBack={openModal}
      variant={buttonVariant}
      size={buttonSize}
      align={buttonPosition}
      type="button"
    >
      {ModalButtonLabel}
    </Button>
  );

  return (
    <div>
      {!controlled && modalButton}
      <Modal
        isOpen={controlled ? controlledOpen : modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles({ splitModal, cardModal, modalPadding, template })}
        contentLabel={ModalTitle}
        className={clsx(cardModal && styles.cardModal, template && styles[`modal-${template}`])}
        overlayClassName={styles.modalOverlay}
      >
        {splitModal && (
          <>
            <Button
              type="button"
              className={styles.modalCloseSplit}
              callBack={closeModal}
              variant="ghost"
            >
              <Icon iconName="close" color={closeButtonColor} />
            </Button>
            {children}
          </>
        )}
        {cardModal && (
          <div className={styles.content} style={{ maxWidth: '88vw', width: maxWidth }}>
            <Button type="button" className={styles.close} callBack={closeModal} variant="primary">
              <Icon iconName="close" color="var(--color-background)" />
            </Button>
            {children}
          </div>
        )}

        {!(splitModal || cardModal) && !template && (
          <>
            {!asWrapper && (
              <div className={styles.modalCloseContainer}>
                <Button callBack={closeModal} variant="ghost" type="button">
                  <Icon iconName="close" color={closeButtonColor} />
                </Button>
              </div>
            )}
            <div
              className={clsx(
                styles.modalContent,
                !showWrapperVerticalScroll && styles.noScrollbar
              )}
              style={{ maxWidth: '88vw', width: maxWidth }}
            >
              <h2>{ModalTitle}</h2>
              {children}
            </div>
          </>
        )}

        {template && template === 'get-in-touch' && (
          <GetInTouchModalTemplate closeModal={closeModal}>{children}</GetInTouchModalTemplate>
        )}
      </Modal>
    </div>
  );
};

export default ModalComponent;
