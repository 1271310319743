import clsx from 'clsx';

import styles from '../Toast.module.scss';
import type { MessageBodyProps } from '../types';

export const ToastMessageBody: React.FC<MessageBodyProps> = ({
  children,
  className,
  borderBottom,
  withScroll
}) => {
  return (
    <div
      className={clsx(styles.Toast__MessageBody, className, {
        [styles['Toast__MessageBody--borderBottom']]: borderBottom,
        ['with-scrollbar']: withScroll
      })}
    >
      {children}
    </div>
  );
};

export default ToastMessageBody;
