import Link from 'next/link';
import { TypefaceLicence, TypefaceVariant } from 'types/common';

import CheckboxPanel from '@/components/global/CheckboxPanel';
import { useTypefaceAddToCart } from '@/components/providers/TypefaceProvider';

import LicenceTypeModal from './LicenceTypeModal';
import styles from './ModalStyles.module.scss';
import { LicenceVariantInput } from './partials';

const LicenceStep = ({
  licenceArray,
  register,
  content,
  isChecked,
  capitalizeFirstLetter,
  updateSelectedLicences,
  errors
}) => {
  const { state } = useTypefaceAddToCart();

  const { selectedLicences, maxUsageError } = state;

  const ContactButton = () => (
    <Link href="https://www.monotype.com/colophon-speak-sales">contact us</Link>
  );

  return (
    <div data-cy="licencePlatforms">
      <div className={styles.stepContent}>{content}</div>
      <div className={styles.licenceGroup}>
        {licenceArray.map((licence) => (
          <div key={licence.slug}>
            <CheckboxPanel
              isChecked={
                isChecked.hasOwnProperty(licence.slug) ? isChecked[licence.slug].checked : false
              }
              value={licence.slug}
              id={licence.slug}
              name="licences"
              label={licence.slug}
              icon={licence.slug}
              iconVariant="onWhite"
              onChange={() => {
                updateSelectedLicences(licence);
              }}
            />
          </div>
        ))}
      </div>
      <LicenceTypeModal />
      {selectedLicences.length > 0 && (
        <div className={styles.selectedLicence} data-cy="licencePlatformsConfig">
          {selectedLicences.map((licence: TypefaceLicence) => {
            const variantArray = Object.values(licence.variants);
            return (
              <div key={licence.name}>
                {variantArray.map((licenceVariant: TypefaceVariant) => {
                  return (
                    <LicenceVariantInput
                      key={licence.name}
                      register={register}
                      capitalizeFirstLetter={capitalizeFirstLetter}
                      licenceVariant={licenceVariant}
                      licence={licence}
                      errors={errors}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      )}
      {maxUsageError && (
        <p className={styles.maxUsage}>
          ⁕ We offer additional licenses above the selected tier. The purchase process for these is
          on a case-by-case basis. Please <ContactButton /> so we can discuss your requirements
          further.
        </p>
      )}
    </div>
  );
};

export default LicenceStep;
