import clsx from 'clsx';
import React, { ReactNode } from 'react';

import styles from './Icon.module.scss';

/*
 *  during development : see icons on /test/icons
 *
 *  icon names come from Figma UI kit /icons
 *  https://www.figma.com/file/CdwF7DLIQlJMyhMxwobYBZ/Colophon?node-id=692%3A65906
 *
 *  or check the id names in ./Iconsprite.tsx
 *  leave out "icon-" so
 *  e.g. <symbol id="icon-arrow-right" ... > becomes "arrow-right"
 *
 */

type Props = {
  iconName?: string;
  width?: string;
  height?: string;
  color?: string;
  label?: ReactNode;
};

const Icon: React.FC<Props> = ({ iconName, width, height, label, color }) => {
  const _style = {
    '--icon-color': color,
    width: width ? `${width}px` : `var(--icon-width, 24px)`,
    height: height ? `${height}px` : `var(--icon-height, 24px)`
  } as React.CSSProperties;

  const component = (
    <svg
      className={styles.svg}
      viewBox={`0 0 ${width || 24} ${height || 24}`}
      width={width || 24}
      height={height || 24}
      xmlns="http://www.w3.org/2000/svg"
    >
      <use href={`#icon-${iconName}`} />
    </svg>
  );

  if (!label)
    return (
      <span style={_style} className={styles.container}>
        {component}
      </span>
    );

  const labelStyle: React.CSSProperties = {
    position: 'relative'
  };

  const dim = Math.min(parseInt(width), parseInt(height));

  if (iconName === 'number') {
    labelStyle.fontSize = `min( ${(dim * 2) / 3}px, 16px)`;
    labelStyle.lineHeight = '1.2';
    if (color) labelStyle.color = color;
  }

  return (
    <div className={clsx(styles.iconWithLabel)} style={_style}>
      <div>{component}</div>
      <div className={styles.label}>
        <span style={labelStyle}>{label}</span>
      </div>
    </div>
  );
};

export default Icon;
