import { useRouter } from 'next/router';

import InputField from '@/components/global/InputField';

import Button from '../../Button';
import {
  ToastMessageBody,
  ToastMessageBodyBlock,
  ToastMessageBodyColumn,
  ToastMessageScrollCarousel,
  ToastMessageTwoColumnBody
} from '../partials';
import { Toast } from '../Toast';
import styles from '../Toast.module.scss';
import type { SuccessToastProps } from '../types';

export const BasicExample: React.FC<SuccessToastProps> = ({ successToastAction }) => {
  const router = useRouter();

  const submit = () => {
    successToastAction();
    document.body.classList.remove('ReactModal__Body--open');
  };

  return (
    <Toast
      title={'Your Session Expired'}
      message={
        <>
          <ToastMessageBody borderBottom>
            <p>
              Need to share the cart with your collaborator, colleague or client to checkout and
              license? Please copy and paste the link below.
            </p>
          </ToastMessageBody>
          <ToastMessageScrollCarousel slides={exampleData} />
        </>
      }
      //////////////////////////
      // Single Column Example
      //////////////////////////

      // message={
      //   <>
      //     <ToastMessageBody borderBottom>
      //       <p>
      //         Need to share the cart with your collaborator, colleague or client to checkout and
      //         license? Please copy and paste the link below.
      //       </p>
      //     </ToastMessageBody>
      //     <ToastMessageBodyColumn withScroll>
      //       {exampleData.map((e, i) => (
      //         <ToastMessageBodyBlock key={i} title={e.title}>
      //           {e.body}
      //         </ToastMessageBodyBlock>
      //       ))}
      //     </ToastMessageBodyColumn>
      //   </>
      // }

      //////////////////////////
      // Dual Scroll Column Example
      //////////////////////////

      // message={
      //   <>
      //     <ToastMessageBody borderBottom>
      //       <p>
      //         Need to share the cart with your collaborator, colleague or client to checkout and
      //         license? Please copy and paste the link below.
      //       </p>
      //     </ToastMessageBody>
      //     <ToastMessageTwoColumnBody>
      //       <ToastMessageBodyColumn withScroll>
      //         {exampleData.map((e, i) => (
      //           <ToastMessageBodyBlock key={i} title={e.title}>
      //             {e.body}
      //           </ToastMessageBodyBlock>
      //         ))}
      //       </ToastMessageBodyColumn>
      //       <ToastMessageBodyColumn withScroll>
      //         {exampleData.map((e, i) => (
      //           <ToastMessageBodyBlock key={i} title={e.title}>
      //             {e.body}
      //           </ToastMessageBodyBlock>
      //         ))}
      //       </ToastMessageBodyColumn>
      //     </ToastMessageTwoColumnBody>
      //   </>
      // }

      //////////////////////////
      // Dual Column Example
      //////////////////////////

      // message={
      //   <>
      //     <ToastMessageBody borderBottom>
      //       <p>
      //         Need to share the cart with your collaborator, colleague or client to checkout and
      //         license? Please copy and paste the link below.
      //       </p>
      //     </ToastMessageBody>
      //     <ToastMessageTwoColumnBody>
      //       <div style={{ background: '#dcdcdc' }}>Col 1</div>
      //       <ToastMessageBodyColumn withScroll>
      //         {exampleData.map((e, i) => (
      //           <ToastMessageBodyBlock key={i} title={e.title}>
      //             {e.body}
      //           </ToastMessageBodyBlock>
      //         ))}
      //       </ToastMessageBodyColumn>
      //     </ToastMessageTwoColumnBody>
      //   </>
      // }

      // actions={
      //   <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
      //     <InputField onWhite label="shareUrl" type="text" name="shareUrl" value={'shareUrl'} />
      //     {true && (
      //       <Button
      //         className={styles.CodeBlock__button}
      //         type="button"
      //         variant="ghost"
      //         // callBack={}
      //       >
      //         
      //       </Button>
      //     )}
      //   </div>
      // }

      position="center"
      onClose={submit}
      asModal
      type="basic"
    />
  );
};

export default BasicExample;

export const exampleData = [
  {
    title: <h4>Professional</h4>,
    body: (
      <p>
        Professional (PRO) variants include the full CF-Standard character set, but may also contain
        support for Cyrillic and Greek scripts. Furthermore, extension stylistic sets and OpenType
        features will be included within PRO versions to give further flexibility and options for
        all type users. Every family has different stylistic sets and OpenType features within their
        Standard and Professional variants. Please check individual family pages to see the extent
        of features available.
      </p>
    ),
    img: '/img/Image.jpg'
  },
  {
    title: <h4>Standard </h4>,
    body: (
      <p>
        Standard (STD) variants contain the CF-Standard character map and no additional OpenType
        features. Most Standard variants will only contain basic ligatures. Please check individual
        family pages to see the extent of features available.
      </p>
    ),
    img: '/img/Image.md.jpg'
  },
  {
    title: <h4>Professional</h4>,
    body: (
      <p>
        Professional (PRO) variants include the full CF-Standard character set, but may also contain
        support for Cyrillic and Greek scripts. Furthermore, extension stylistic sets and OpenType
        features will be included within PRO versions to give further flexibility and options for
        all type users. Every family has different stylistic sets and OpenType features within their
        Standard and Professional variants. Please check individual family pages to see the extent
        of features available.
      </p>
    ),
    img: '/img/Image.sm.jpg'
  },
  {
    title: <h4>Standard </h4>,
    body: (
      <p>
        Standard (STD) variants contain the CF-Standard character map and no additional OpenType
        features. Most Standard variants will only contain basic ligatures. Please check individual
        family pages to see the extent of features available.
      </p>
    ),
    img: '/img/Image.lg.jpg'
  }
];
