import clsx from 'clsx';
import { ReactChildren, ReactNode, useCallback, useEffect, useState } from 'react';

import styles from '@/components/global/Header/Header.module.scss';
import panelStyles from '@/components/global/Header/HeaderPanel.module.scss';
import createSafeContext from '@/lib/useSafeContext';

export interface HeaderThemeState {
  theme: string;
  fixed: boolean;
  menuOpen: boolean;
}

export type HeaderTheme = string;
export type HeaderFixed = boolean;
export type MenuState = boolean;

export interface HeaderThemeProps {
  headerState: HeaderThemeState;
  headerFixedScroll: () => void;
  setHeaderTheme: (theme: string) => void;
  setMenuOpen: (menuOpen: boolean) => void;
  headerClasses: string;
}

export const [useHeaderTheme, Provider] = createSafeContext<HeaderThemeProps>();

export const HeaderThemeProvider = ({ children }: { children: ReactChildren | ReactNode }) => {
  const initialHeaderClasses = () => {
    return clsx(styles.header);
  };

  const [menuOpen, setMenuOpen] = useState<MenuState>(false);
  const [headerFixed, setHeaderFixed] = useState<HeaderFixed>(false);
  const [headerTheme, setHeaderTheme] = useState<HeaderTheme>('');

  const [headerClasses, setHeaderClasses] = useState(initialHeaderClasses());

  const headerFixedScroll = useCallback(() => {
    const isFixed = window.scrollY > 2;
    setHeaderFixed(isFixed);
  }, [setHeaderFixed]);

  useEffect(() => {
    window.addEventListener('scroll', headerFixedScroll);
    return () => window.removeEventListener('scroll', headerFixedScroll);
  }, [headerFixedScroll]);

  useEffect(() => {
    document.body.classList.remove('mobile-overflow-hidden');

    if (menuOpen) {
      document.body.classList.add('mobile-overflow-hidden');
    }
  }, [menuOpen]);

  // header classes from state
  useEffect(() => {
    const classes = clsx(
      styles.header,
      `th-${headerTheme}`,
      headerFixed ? styles.headerFixed : '',
      menuOpen ? styles.headerMenuOpen : ''
    );

    setHeaderClasses(classes);
  }, [menuOpen, headerFixed, headerTheme]);

  // check window scroll on first page load
  useEffect(() => {
    const fix = window.scrollY > 2;
    setHeaderFixed(fix);
  }, []);

  // listener
  useEffect(() => {
    if (!menuOpen) return;

    const loopThroughParents = (el, check) => {
      check(el);
      if (el.parentElement) loopThroughParents(el.parentElement, check);
    };

    const clickListen = (ev) => {
      if (!menuOpen) return;
      let headerClick = false;
      loopThroughParents(ev.target, (el) => {
        const cls = el.getAttribute('class');
        if (cls?.includes(panelStyles.headerPanel)) headerClick = true;
        if (['Open Menu', 'Close Menu'].includes(el.title)) headerClick = true; // so that menu icon click does not immediately close
      });
      if (!headerClick) setMenuOpen(false);
    };

    window.addEventListener('click', clickListen);
    window.addEventListener('touchstart', clickListen);
    return () => {
      window.removeEventListener('click', clickListen);
      window.removeEventListener('touchstart', clickListen);
    };
  }, [setMenuOpen, menuOpen]);

  const providerValues: HeaderThemeProps = {
    headerState: {
      theme: headerTheme,
      fixed: headerFixed,
      menuOpen: menuOpen
    },
    headerFixedScroll,
    setHeaderTheme,
    setMenuOpen,
    headerClasses
  };

  return <Provider value={providerValues}>{children}</Provider>;
};
