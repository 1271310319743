import { isNotFamilyVariant } from '../productHelpers';

export const getAllVariantsNonPurchaseable = (variants) => {
  const nonFamilyVariants = variants.filter((variant) => isNotFamilyVariant(variant));
  const nonFamilyVariantsCount = nonFamilyVariants.length;

  const variantsNonPurchaseable = nonFamilyVariants.filter((variant) => {
    const variantTypeAttribute = variant.attributes.find(
      (attribute) => attribute.attribute.slug === 'variant-type'
    );
    const variantTypeAttributeValues = variantTypeAttribute.values;
    const isNoPurchase = variantTypeAttributeValues.some((value) => value.name === 'NoPurchase');
    return isNoPurchase;
  });

  const variantsNonPurchaseableCount = variantsNonPurchaseable.length;

  return nonFamilyVariantsCount === variantsNonPurchaseableCount;
};
