import 'styles/globals.css';
import 'styles/globals.scss';
import 'styles/colours.css';

import { ApolloProvider } from '@apollo/client';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Spinner } from '@/components';
import { ExpiredToast } from '@/components/global/Toast';
import { AddressValidationProvider } from '@/components/providers/AddressValidationProvider';
import { CheckoutProvider } from '@/components/providers/CheckoutProvider';
import { HeaderThemeProvider } from '@/components/providers/HeaderThemeProvider';
import RegionsProvider from '@/components/providers/RegionsProvider';
import { SiteTickerProvider } from '@/components/providers/SiteTickerProvider';
import { TypefaceProvider } from '@/components/providers/TypefaceProvider';
import { useAuthChange } from '@/lib/auth';
import { SaleorAuthProvider } from '@/lib/auth/SaleorAuthProvider';
import { useApolloClient } from '@/lib/auth/useApolloClient';
import { useSaleorAuthClient } from '@/lib/auth/useSaleorAuthClient';
import { start } from '@/lib/bugsnag';
import { GA_4_TRACKING_ID, GTM_TRACKING_ID, LOGROCKET_ID } from '@/lib/constants';
import checkoutDataLayer from '@/lib/datalayer/checkout';

start();

if (typeof window !== 'undefined' && LOGROCKET_ID) {
  LogRocket.init(LOGROCKET_ID);
  setupLogRocketReact(LogRocket);
}

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  err?: any;
};

const MyApp = ({ Component, pageProps, err }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);
  const uri = process.env.NEXT_PUBLIC_API_URI!;
  const awsUri = process.env.NEXT_PUBLIC_AWS_API_URL!;
  const { hostname } = new URL(uri);
  const router = useRouter();
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const useSaleorAuthClientProps = useSaleorAuthClient({
    saleorApiUrl: process.env.NEXT_PUBLIC_API_URI,
    storage: typeof window !== 'undefined' ? window.localStorage : undefined
  });

  const { saleorAuthClient } = useSaleorAuthClientProps;
  const { apolloClient, resetClient } = useApolloClient(saleorAuthClient.fetchWithAuth);

  const handleExpiry = async () => {
    await resetClient();
    setSessionExpired(true);
  };

  const handleExpiryToastClose = () => {
    // document.body.classList.remove('ReactModal__Body--open');
    setSessionExpired(false);
    router.push({
      pathname: '/'
    });
  };

  useAuthChange({
    onSignedOut: () => resetClient(),
    onExpiry: () => handleExpiry(),
    onSignedIn: () => resetClient()
  });

  useEffect(() => {
    const handleStart = (url) => {
      if (error) {
        setError(false);
        return;
      }

      setShowLoader(true);
    };

    const handleStop = (url) => {
      setShowLoader(false);
    };

    const handleError = () => {
      setShowLoader(false);
      setError(true);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleError);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleError);
    };
  }, [setShowLoader, router, error]);

  return (
    <>
      <Head>
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <link rel="preconnect" href={`//${hostname}`} crossOrigin="true" />
        <link rel="preconnect" href={awsUri} crossOrigin="true" />
        <link rel="dns-prefetch" href={`//${hostname}`} />
        <link rel="dns-prefetch" href={awsUri} />
        <link
          rel="preload"
          href="/fonts/CFBasis-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/CFBasisMonoPro-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Head>

      {GA_4_TRACKING_ID && (
        <>
          <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_4_TRACKING_ID}`} />
          <Script
            id="GA4"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', ${GA_4_TRACKING_ID}, { 'anonymize_ip': true });
              `
            }}
          />
        </>
      )}
      {GTM_TRACKING_ID && (
        <Script
          id="GTM"
          strategy="afterInteractive"
          onLoad={() => {
            LogRocket.getSessionURL(function (sessionURL) {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: 'add_to_cart',
                eventCategory: 'LogRocket',
                eventAction: sessionURL
              });
            });
          }}
          dangerouslySetInnerHTML={{
            __html: `
                (function(w,d,s,l,i){w[l]=w[l] || [];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');
                  `
          }}
        />
      )}

      <SaleorAuthProvider {...useSaleorAuthClientProps}>
        <ApolloProvider client={apolloClient}>
          <SiteTickerProvider>
            <HeaderThemeProvider>
              <CheckoutProvider>
                <AddressValidationProvider>
                  <RegionsProvider>
                    <TypefaceProvider>
                      {showLoader && <Spinner theme="white" />}
                      {sessionExpired && (
                        <ExpiredToast successToastAction={handleExpiryToastClose} />
                      )}
                      {getLayout(<Component {...pageProps} err={err} />)}
                    </TypefaceProvider>
                  </RegionsProvider>
                </AddressValidationProvider>
              </CheckoutProvider>
            </HeaderThemeProvider>
          </SiteTickerProvider>
        </ApolloProvider>
      </SaleorAuthProvider>
    </>
  );
};

export default MyApp;
