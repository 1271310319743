import { useEffect, useState } from 'react';

import Button from '@/components/global/Button';

import styles from './CopyToClipboardButton.module.scss';

interface Props {
  shareUrl?: string;
  content?: string;
  className?: string;
}

export const CopyToClipboardButton: React.FC<Props> = ({ shareUrl, content, className }) => {
  const clipboardButtonStateToTextMap = {
    default: 'Copy To Clipboard',
    success: 'Copied!',
    error: 'Error!'
  };

  const [clipboardSupported, setClipboardSupported] = useState<Boolean>(true);
  const [clipboardButtonState, setClipboardButtonState] =
    useState<keyof typeof clipboardButtonStateToTextMap>('default');

  const copyContentToClipboard = async () => {
    setClipboardButtonState('default');
    try {
      await navigator.clipboard.writeText(shareUrl || content);

      setClipboardButtonState('success');
    } catch (error) {
      console.log(error);
      setClipboardButtonState('error');
    }
  };

  useEffect(() => {
    setClipboardSupported(
      window.navigator !== undefined && window.navigator.clipboard !== undefined
    );
  }, []);

  if (!clipboardSupported) return null;

  return (
    <Button
      className={[
        styles.CopyButton,
        className,
        clipboardButtonState === 'success' ? styles.Clicked : ''
      ].join(' ')}
      type="button"
      size="xl"
      variant="ghost"
      callBack={copyContentToClipboard}
    >
      
    </Button>
  );
};

export default CopyToClipboardButton;
