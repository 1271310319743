export const isMigratedOrder = (order) =>
  order.metadata.find((item) => item.key === 'migrated_order')?.value === 'true';

export const getMigratedOrderLinesData = (order) =>
  order.metadata.find((item) => item.key === 'migratedOrderLines')?.value;

export const getMigratedOrderLineTransactionId = (order) =>
  order.metadata.find((item) => item.key === 'transactionId')?.value;

export const getMigratedOrderLineBraintreeId = (order) =>
  order.metadata.find((item) => item.key === 'braintree_id')?.value;

export const getMigratedOrderTotals = (order) => {
  const migratedOrderSubtotal = order.metadata.find((item) => item.key === 'subtotal')?.value;
  const migratedOrderTotal = order.metadata.find((item) => item.key === 'total')?.value;

  return {
    subtotal: {
      amount: migratedOrderSubtotal,
      currency: 'GBP'
    },
    total: {
      amount: migratedOrderTotal,
      currency: 'GBP'
    }
  };
};

export const getMigratedOrderLines = (migratedOrderLinesData) => {
  let migratedOrderLinesDataArray = [];
  try {
    migratedOrderLinesDataArray = JSON.parse(migratedOrderLinesData);
  } catch {
    return [];
  }

  return migratedOrderLinesDataArray;
};
