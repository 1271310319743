import clsx from 'clsx';
import React from 'react';

import styles from './Grid.module.scss';

type TTags = 'div' | 'section' | 'article' | 'aside' | 'figure' | 'footer' | 'header';

interface IProps {
  as?: TTags;
  theme?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  rowSpacing?: number;
  rowAlign?: string;
  className?: string;
  rowPadding?: string;
  columnSpacing?: string;
  gutter?: string;
  reverseOnMobile?: 'horizontal' | 'vertical';
  background?: string;
}

export const Grid: React.FC<IProps> = ({
  as,
  theme,
  children,
  fullWidth,
  fullHeight,
  rowSpacing,
  rowAlign,
  className,
  rowPadding,
  columnSpacing,
  gutter,
  reverseOnMobile,
  background,
  ...rest
}) => {
  const Component = as || 'div';
  const rowStyles = {
    rowGap: rowSpacing ? `${rowSpacing}px` : '0',
    ...(rowAlign ? { alignItems: rowAlign } : ''),
    paddingTop: rowPadding ? rowPadding : 'var(--row-padding)',
    ...(columnSpacing ? { justifyContent: columnSpacing } : ''),
    paddingBottom: rowPadding ? rowPadding : 'var(--row-padding)'
  };

  return (
    <Component
      className={clsx(styles.grid, theme && `u-${theme.toLowerCase()}`, {
        [styles.gridFullWidth]: fullWidth,
        [styles.gridFullHeight]: fullHeight,
        [styles.background]: background,
        [className]: className
      })}
      style={
        ({ ...(gutter ? { '--custom-gutter': gutter } : '') } as React.CSSProperties,
        { ...(background && { '--grid-background': background }) } as React.CSSProperties)
      }
      {...rest}
    >
      <div
        className={clsx(styles.row, {
          [styles['reverse-mobile-vertical']]: reverseOnMobile === 'vertical',
          [styles['reverse-mobile-horizontal']]: reverseOnMobile === 'horizontal'
        })}
        style={rowStyles}
      >
        {children}
      </div>
    </Component>
  );
};
