import React, { ChangeEventHandler, forwardRef } from 'react';

import InputErrorMessage from '@/components/global/InputErrorMessage';

import styles from './Radio.module.scss';

interface RadioProps {
  name: string;
  value: string;
  id: string;
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  isRequired?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  hasBorder?: boolean;
  error?: string;
  subLabel?: string;
  tag?: string;
  isPanel?: boolean;
}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      name,
      value,
      id,
      label,
      onChange,
      onFocus,
      onBlur,
      isRequired,
      isChecked,
      isDisabled,
      error,
      subLabel,
      isPanel,
      tag
    },
    ref
  ) => (
    <div className={isPanel ? styles.RadioPanel : styles.Radio}>
      <input
        className={`${styles.RadioInput} visually-hidden`}
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={isRequired}
        defaultChecked={isChecked}
        disabled={isDisabled}
        ref={ref}
        data-cy={`radio-${value}`}
      />
      <label htmlFor={id}>
        <span></span>
        <div className={styles.labelContainer}>
          <div className={styles.mainLabel}>
            {label}
            {` `}
            {tag && <div className={styles.tag}>{tag}</div>}
          </div>
          {subLabel && <div className={styles.subLabel}>{subLabel}</div>}
        </div>
      </label>
      {error && <InputErrorMessage error={error} />}
    </div>
  )
);

Radio.displayName = 'Radio';

export default Radio;
