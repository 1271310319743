import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

import styles from './InputLabel.module.scss';

interface InputLabelProps {
  name: string;
  label: string;
  isVisible?: boolean;
  error?: string | ReactNode;
}

const InputLabel: FC<InputLabelProps> = ({ name, label, isVisible, error }) => (
  <label
    className={clsx(styles.InputLabel, {
      'visually-hidden': !isVisible,
      [styles.error]: error
    })}
    htmlFor={name}
  >
    {label}
  </label>
);

export default InputLabel;
