import {
  Accordion as AccordionWrapper,
  AccordionItem as AccordionItemWrapper,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

import Checkbox from '@/components/global/Checkbox';
import Icon from '@/components/global/Icon';
import { useCheckout } from '@/components/providers/CheckoutProvider';
import { getAllVariantsNonPurchaseable } from '@/lib/fonts/getAllVariantsNonPurchaseable';
import variantNameFormatted from '@/lib/fonts/variantName';
import { useScreenSize } from '@/lib/hooks/mediaQuery';
import { isFamilyVariant, isVariableFont } from '@/lib/productHelpers';

import FontGroup from './FontGroup';
import styles from './VariantGroupings.module.scss';

const VariantGroupingLabels = (group) => {
  const Labels = {
    rd: 'Round',
    st: 'Straight'
  };

  return Labels[group] || group.replace('-', '');
};

const VariantGroupings = ({
  product,
  variantGroupings,
  variants,
  register,
  content,
  errors,
  fontsByGroup,
  watchFonts,
  groupVariantsByMeta,
  fontFamilyGroups
}) => {
  const { checkout } = useCheckout();

  const Arrow = ({ expanded }: any) => {
    const screen = useScreenSize();
    const iconSize = screen === 'Mobile' ? '24' : '32';
    return (
      <div className={styles.arrow}>
        {expanded ? (
          <Icon iconName="arrow-up" color="currentColor" width={iconSize} height={iconSize} />
        ) : (
          <Icon iconName="arrow-down" color="currentColor" width={iconSize} height={iconSize} />
        )}
      </div>
    );
  };

  const expanded = variantGroupings.length < 2 ? [variantGroupings[0]] : [];

  const fonts = (variants, variantGroup) => {
    return fontsByGroup({ checkout, variants, variantGroup, groupVariantsByMeta });
  };

  const fontFamilyGroupsArray = fontFamilyGroups;
  const familySelected =
    watchFonts[0].length > 0 ? watchFonts[0].some((font) => isFamilyVariant({ sku: font })) : false;
  const allowMultipleFamilyBundles =
    product.metadata?.find(({ key }) => key === 'allowMultipleFamilyBundles')?.value === 'true' ||
    false;
  const hasSelectedOtherThanFamily = !!watchFonts[0].filter(
    (sku) => !sku.includes('-family') && !sku.includes('-subfamily')
  ).length;
  const disableFamily = (familySku) => {
    if (watchFonts[0].length < 1 || familySelected) {
      return false;
    }

    return allowMultipleFamilyBundles ? hasSelectedOtherThanFamily : familySku !== watchFonts[0][0];
  };

  const onlyGroupFamillies = getAllVariantsNonPurchaseable(variants);

  const variableFontVariants = variants.filter((variant) => isVariableFont(variant));
  const staticFontVariants = variants.filter((variant) => !isVariableFont(variant));

  return (
    <div data-cy="typeface-add-to-cart-variants">
      <div className={styles.stepContent + ' u-h5'}>{content}</div>
      {variableFontVariants.length > 0 && (
        <div
          className={styles.variableFontSection}
          data-cy="typeface-add-to-cart-variants-variable"
        >
          <p className={styles.variableFontSection__heading}>Select a variable font:</p>
          <p>
            Variable font purchases will include the relevant static font files within your download
          </p>

          <div>
            <FontGroup
              variantGroup={null}
              register={register}
              errors={errors}
              fonts={variableFontVariants}
              disable={false}
            />
          </div>
        </div>
      )}
      {fontFamilyGroupsArray.length > 0 && (
        <div className={styles.fontFamilyGroup} data-cy="typeface-add-to-cart-variants-family">
          <p>Select a complete family:</p>

          {fontFamilyGroupsArray.map((fontFamilyGroup, index) => {
            const isDisabled = disableFamily(fontFamilyGroup?.sku);
            const count =
              fontFamilyGroup.metadata
                .find((m) => m.key === 'familySkus')
                ?.value.split(',')
                .length.toString() || 0;
            const discount =
              fontFamilyGroup.metadata.find((m) => m.key === 'discount')?.value || null;
            const recommended =
              fontFamilyGroup.metadata.find((m) => m.key === 'recommended')?.value || false;
            return (
              <div
                key={fontFamilyGroup.sku}
                className={isDisabled ? styles.fontFamilyGroupDisabled : ''}
              >
                <Checkbox
                  value={fontFamilyGroup.sku}
                  id={fontFamilyGroup.sku}
                  name="fonts"
                  {...register('fonts', { required: true })}
                  label={variantNameFormatted(fontFamilyGroup)}
                  error={errors.fonts && 'This field is Required'}
                  isPanel
                  isLarge
                  isDisabled={isDisabled}
                  count={count}
                  discount={discount}
                  recommended={recommended}
                />
              </div>
            );
          })}
          <p>
            {onlyGroupFamillies
              ? 'These fonts can only be purchased as part of a family. Browse the families:'
              : 'Or select individual styles:'}
          </p>
        </div>
      )}
      {variantGroupings.length > 1 ? (
        <AccordionWrapper allowZeroExpanded allowMultipleExpanded={true} preExpanded={expanded}>
          {variantGroupings.map((variantGroup) => {
            return (
              <AccordionItemWrapper uuid={variantGroup} key={variantGroup}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className={styles.heading}>
                      <div className={styles.title}>
                        <h5>{VariantGroupingLabels(variantGroup)}</h5>
                        <p>{`${fonts(staticFontVariants, variantGroup).length} styles`}</p>
                      </div>
                      <AccordionItemState>
                        {(state) => <Arrow expanded={state.expanded} />}
                      </AccordionItemState>
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  <AccordionItemState>
                    {(state) => (
                      <div className={state.expanded ? styles.expanded : styles.hidden}>
                        <FontGroup
                          variantGroup={variantGroup}
                          register={register}
                          errors={errors}
                          fonts={fonts(staticFontVariants, variantGroup)}
                          disable={familySelected}
                        />
                      </div>
                    )}
                  </AccordionItemState>
                </AccordionItemPanel>
              </AccordionItemWrapper>
            );
          })}
        </AccordionWrapper>
      ) : (
        variantGroupings.map((variantGroup) => {
          return (
            <div key={variantGroup} data-cy="typeface-add-to-cart-variants-static">
              <FontGroup
                variantGroup={variantGroup}
                register={register}
                errors={errors}
                fonts={fonts(staticFontVariants, variantGroup)}
                disable={familySelected}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default VariantGroupings;
