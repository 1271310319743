import clsx from 'clsx';

import styles from '../Toast.module.scss';
import type { MessageBodyProps } from '../types';

export const ToastMessageTwoColumnBody: React.FC<MessageBodyProps> = ({
  children,
  className,
  withScroll
}) => {
  return (
    <div
      className={clsx(styles.Toast__MessageTwoColumnBody, className, {
        ['with-scrollbar']: withScroll
      })}
    >
      {children}
    </div>
  );
};

export default ToastMessageTwoColumnBody;
