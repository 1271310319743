import { AddressDetailsFragment } from '@/saleor/api';

import Button from '../global/Button';
import Panel from '../global/Panel';
import styles from './CheckoutForm.module.scss';

export interface IAddressDisplayProps {
  address: AddressDetailsFragment;
  setStep?: (number) => void;
  vatNumber?: string;
  vatValidityResponse?: string;
}

export const AddressDisplay = ({
  address,
  setStep,
  vatNumber,
  vatValidityResponse
}: IAddressDisplayProps) => {
  return (
    <Panel className={styles.AddressDisplay}>
      <div className={styles.AddressDisplay__header}>
        <p>Billing Details</p>
        {setStep && (
          <Button
            variant="secondary"
            callBack={() => {
              setStep(1);
            }}
          >
            Edit
          </Button>
        )}
      </div>
      <div className={styles.AddressDisplay__body}>
        <div className={styles.AddressDisplay__bodyRow}>
          <p className={styles.AddressDisplay__bodyLabel}>Name</p>
          <p>
            {address?.firstName} {address?.lastName}
          </p>
        </div>
        <div className={styles.AddressDisplay__bodyRow}>
          <p className={styles.AddressDisplay__bodyLabel}>Address</p>
          <address>
            {address?.streetAddress1}
            <br />
            {address?.city.toLowerCase()}
            <br />
            {address?.postalCode} <br />
            {address?.countryArea}
            <br />
            {address?.country.country}
          </address>
        </div>
        <div className={styles.AddressDisplay__bodyRow}>
          <p className={styles.AddressDisplay__bodyLabel}>Telephone</p>
          <p>{address?.phone}</p>
        </div>
        {vatNumber && (
          <div className={styles.AddressDisplay__bodyRow}>
            <p className={styles.AddressDisplay__bodyLabel}>VAT Number</p>
            <p>
              {vatNumber}
              {vatValidityResponse && (
                <>
                  <br />
                  <small>{vatValidityResponse}</small>
                </>
              )}
            </p>
          </div>
        )}
      </div>
    </Panel>
  );
};

export default AddressDisplay;
