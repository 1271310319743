import {
  getFeatureChars,
  getFeatureGroupFeaturesByFeatureChars
} from '@/components/customiser/helpers';
import { DEFAULT_LOCALE } from '@/lib/regions';

import { AWSClient } from './aws/client';
import { getIgnoredOpentype } from './customiser/getIgnoredProperties';
import variantNameFormatted from './fonts/variantName';
import uniq from './isUniq';

export const formatAsMoney = (amount: number = 0, currency = 'USD', locale = DEFAULT_LOCALE) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency
  }).format(amount);

// Returns true for non nullable values
export function notNullable<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const isBrowser = typeof window !== 'undefined';

export const inputFieldValidation = {
  emailFormat: {
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'ⓘ Entered value does not match email format'
    }
  }
};

// can get a list of conditional errors
// only the first existing error
// (ignores false and null)
export const formErrorParser = (...args) => {
  for (let err of args) {
    if (err) return err;
  }
};

export const fontDisplayAttribute = (attributes) => {
  const attributeData = attributes?.find(
    (attribute) => attribute.attribute.slug === 'display-font'
  );
  return attributeData?.values?.length > 0 ? attributeData.values[0].name.toLowerCase() : null;
};

export const getFontVersion = (variants) => {
  const firstVariant = variants[0];
  const firstVariantSku = firstVariant?.sku;
  const skuIsPro = firstVariantSku.endsWith('pro');
  return skuIsPro ? 'pro' : 'std';
};

export const entitify = (code) => {
  return <div dangerouslySetInnerHTML={{ __html: `&#x${code};` }} />;
};

export const convertSnakeToCamelCase = (str: string) =>
  str.replace(/-([a-z])/g, (x, up) => up.toUpperCase());

export const characterToHex = (str) => {
  if (!str) return;

  let hex,
    result = '';

  for (let i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += ('000' + hex).slice(-4);
  }

  return result;
};

export const capitalizeFirstLetter = (string) => {
  let input = string.replace(/\W/g, '');
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const updateFontsToWebfont = ({ font, extension = '.woff2' }) =>
  `/${font}`.replace('.ttf', extension).replace('.otf', extension);

export const onlyFontsInCart = (checkout) =>
  checkout.lines.every((line) => {
    const isTypeface = line?.variant?.product?.productType?.slug === 'font-family';
    return isTypeface;
  });

// TEMP: Due to Saleor bug, multiples of the same variant cannot be purchased in the same cart
export const isVariantInCart = (checkout) => (variantSku) => {
  return false;
  if (!checkout || !checkout?.lines || checkout?.lines.length === 0) return false;

  return checkout.lines.some((line) => {
    const isTypeface = line?.variant?.product?.productType?.slug === 'font-family';

    return isTypeface
      ? line?.variant?.sku.replace(/-pro$/, '') === variantSku.replace(/-pro$/, '')
      : false;
  });
};

// Format the font data so we can separate the pro/std versions
export const formatAwsS3FontData = (awsS3FontData) => {
  const awsS3fontDataFormatted = awsS3FontData.reduce((acc, variantFontStyle) => {
    const variantFontStyleSkuWithoutVersion = variantFontStyle.sku.replace(/-pro$/, '');
    const skuIsPro = variantFontStyle.sku.endsWith('pro');

    acc[variantFontStyleSkuWithoutVersion] = {
      name: variantNameFormatted(variantFontStyle),
      versions: acc[variantFontStyleSkuWithoutVersion]
        ? [
            ...acc[variantFontStyleSkuWithoutVersion].versions,
            { ...variantFontStyle, version: skuIsPro ? 'pro' : 'std' }
          ]
        : [{ ...variantFontStyle, version: skuIsPro ? 'pro' : 'std' }]
    };

    return acc;
  }, {});

  return awsS3fontDataFormatted;
};

export const generateAwsFontDataPromise = async (fontStyle, hasFeatureGroups = false) => {
  if (!fontStyle) {
    return false;
  }

  const fontStyleFamilyName = fontStyle.product.slug;
  const skuIsPro = fontStyle.sku.endsWith('pro');
  const fontStyleSkuWithoutVersion = fontStyle.sku.replace(/-pro$/, '');

  const awsPath = `${fontStyleFamilyName}/${fontStyleSkuWithoutVersion}`;
  const params = {
    properties: 'slug,isVariable,variable,features,featureChars,categorisedCharacters,summary',
    version: skuIsPro ? 'pro' : 'std'
  };
  const awsS3FontData = await AWSClient.fontData('fondue', { params, pathAppend: awsPath });

  // Format data structure for use, consistent with typeface pages
  // N.B. Features data ise used in subsequent step
  const { features } = awsS3FontData;
  const ignoredFeatures = getIgnoredOpentype(fontStyle, { isCustomiser: true });
  const featuresWithoutIgnoredFeatures = features.filter(
    (feature) => !ignoredFeatures.includes(feature.tag)
  );

  const featureChars = getFeatureChars(awsS3FontData);
  const getFeatureGroupFeatures = getFeatureGroupFeaturesByFeatureChars(featureChars);

  const featuresStylisticSets = featuresWithoutIgnoredFeatures
    ? featuresWithoutIgnoredFeatures.filter((feature) =>
        feature?.name?.toLowerCase().includes('stylistic set')
      )
    : [];
  const featuresCharacterVariants = featuresWithoutIgnoredFeatures
    ? featuresWithoutIgnoredFeatures.filter((feature) =>
        feature?.name?.toLowerCase().includes('character variants')
      )
    : [];

  return {
    ...fontStyle,
    fondue: {
      ...awsS3FontData,
      isVariable: awsS3FontData.isVariable || null,
      variable: awsS3FontData.variable || null,
      ...(hasFeatureGroups && {
        featuresGroups: [
          { name: 'Stylistic Sets', features: getFeatureGroupFeatures(featuresStylisticSets) },
          {
            name: 'Character Variants',
            features: getFeatureGroupFeatures(featuresCharacterVariants)
          }
        ]
      })
    }
  };
};

export const slugify = (str): string =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const getVersionScripts = (selectedVersion) =>
  selectedVersion
    ? uniq(
        selectedVersion?.fondue?.categorisedCharacters
          ?.map((character) => {
            return character.script;
          })
          ?.filter((script) => script !== null)
      )
    : [];

export function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

// Function to remove accents from letter https://stackoverflow.com/a/37511463
export function normalize(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

// Function to get the max usage that applies to input field
interface GetMaxUsageAppliesProps {
  (maxUsageValues: any, optionItem: any, multipliers: any, updatedLicences?: any): boolean;
}

export const getMaxUsageApplies: GetMaxUsageAppliesProps = (
  maxUsageValues,
  optionItem,
  multipliers,
  updatedLicences
) => {
  let isValid = false;

  // validating when updateSelectedLicences
  if (!optionItem && updatedLicences) {
    const includedLicences = updatedLicences.map((l) => l.name);
    const includedMultipliers = multipliers.filter(
      (m) =>
        // Validate if any of the selected multipliers
        // are exceeding the limit
        includedLicences.includes(m.licenceName) && maxUsageValues.includes(m.licenceMultiplierName)
    );
    if (includedMultipliers.length > 0) isValid = true;
    return isValid;
  }

  const item = JSON.parse(optionItem.value);
  if (maxUsageValues.includes(optionItem.text)) return true;

  if (multipliers) {
    for (let i = 0; i < multipliers.length; i++) {
      const multiplier = multipliers[i];

      // check if is multiplier max usage
      // and if its an update to an existing license
      if (
        maxUsageValues.includes(multiplier.licenceMultiplierName) &&
        multiplier.licenceVariant !== item.licenceVariant
      ) {
        isValid = true;
        break;
      }
    }
  }

  return isValid;
};
export const addZeroes = (num, precision = 1) => num.toFixed(precision);

export function round(value, precision = 0) {
  var multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
}
