import Vimeo from '@u-wave/react-vimeo';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import LazyLoad from 'react-lazyload';

import { SITE_URL } from '@/lib/constants';

import styles from './Video.module.scss';

export type StoryblokVideo = {
  filename: string;
  alt?: string;
  id: number;
};

interface props {
  url?: string;
  autoplay: boolean;
  controls: boolean;
  file?: StoryblokVideo;
  cover?: boolean;
  loop?: boolean;
  hasBorder?: boolean;
  borderColor?: string;
  borderRadius?: string;
}

const Video: React.FC<props> = ({
  url,
  file,
  autoplay = true,
  controls = false,
  cover = false,
  loop = false,
  hasBorder,
  borderColor,
  borderRadius
}) => {
  const videoRef = useRef(null);
  const progressRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [muted, setMuted] = useState(false);
  const inDev = process.env.NODE_ENV === 'development';
  const host = `${SITE_URL}cdn/`;

  let filePath = '';
  if (url) {
    filePath = inDev ? url : url.replace('https://a.storyblok.com/', host);
  }

  const onPlay = (control) => {
    if (control === 'play') {
      videoRef.current?.play()?.then(() => setPlaying(true));
    } else if (control === 'pause') {
      videoRef.current?.pause();
      setPlaying(false);
    }
  };

  const onProgressChange = (e) => {
    const value = Number(e.currentTarget.value);
    setProgress(value);
    if (videoRef.current) {
      videoRef.current.currentTime = (value * videoRef.current?.duration) / 100;
    }
  };

  const onMute = () => {
    if (videoRef.current) {
      const isMuted = !videoRef.current.muted;
      videoRef.current.muted = isMuted;
      setMuted(isMuted);
    }
  };

  useEffect(() => {
    if (autoplay && !url) {
      onPlay('play');
      setMuted(autoplay);
    }
  }, [autoplay, url]);

  if (!url && !file.filename)
    return <div className={styles.placeholder}>Please add a Vimeo URL or Video File</div>;

  return (
    <LazyLoad once>
      <div className={cover ? styles.containerCover : styles.container}>
        {url ? (
          <Vimeo
            video={filePath}
            autoplay={autoplay}
            muted={autoplay}
            loop={loop}
            controls={controls}
            responsive={true}
            className={clsx({
              [styles.hasBorder]: hasBorder,
              [styles['border--white']]: borderColor === 'white',
              [styles['border--normal-radius']]: borderRadius === 'normal'
            })}
          />
        ) : (
          <React.Fragment>
            {controls && (
              <React.Fragment>
                <div className={styles.controls} data-state={controls ? 'visible' : 'hidden'}>
                  <button
                    type="button"
                    data-state={playing ? 'pause' : 'play'}
                    onClick={() => onPlay(playing ? 'pause' : 'play')}
                  >
                    Play/Pause
                  </button>
                  <div className={styles.progress}>
                    <input
                      ref={progressRef}
                      className={styles.seek}
                      type="range"
                      min="0"
                      max="100"
                      step="0.01"
                      value={progress}
                      autoComplete="off"
                      role="slider"
                      aria-label="Seek"
                      onInput={onProgressChange}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      aria-valuenow={progress}
                    />
                  </div>
                </div>
                <div className={styles.soundControls}>
                  <button type="button" data-state={muted ? 'unmute' : 'mute'} onClick={onMute}>
                    Mute/Unmute
                  </button>
                </div>
              </React.Fragment>
            )}
            <video
              ref={videoRef}
              id={`${file.id}`}
              autoPlay={autoplay}
              muted={autoplay ? autoplay : muted}
              preload="metadata"
              loop={loop}
              playsInline
              className={clsx({
                [styles.hasBorder]: hasBorder,
                [styles['border--white']]: borderColor === 'white',
                [styles['border--normal-radius']]: borderRadius === 'normal'
              })}
              {...(controls && {
                onTimeUpdate: () => {
                  // For mobile browsers, ensure that the progress element's max attribute is set
                  if (!progressRef.current.getAttribute('max'))
                    progressRef.current.setAttribute('max', 100);

                  setProgress(
                    Math.floor((videoRef.current.currentTime / videoRef.current.duration) * 100)
                  );

                  if (videoRef.current.ended) {
                    if (loop) {
                      onPlay('play');
                    } else {
                      // Reset progress
                      videoRef.current.currentTime = 0;
                      progressRef.current.value = 0;
                      setProgress(0);
                      onPlay('pause');
                    }
                  }
                }
              })}
            >
              <source src={file.filename} type="video/mp4" />
            </video>
          </React.Fragment>
        )}
      </div>
    </LazyLoad>
  );
};
export default Video;
