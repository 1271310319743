import clsx from 'clsx';

import styles from './Spinner.module.scss';

export interface ISpinnerProps {
  theme?: 'white' | 'default' | 'grey' | 'white-charcoal';
  contextual?: boolean;
  relative?: boolean;
  className?: string;
}

export const Spinner = ({
  theme = 'default',
  contextual = false,
  relative = false,
  className = ''
}: ISpinnerProps) => {
  return (
    <div
      className={clsx(styles.Spinner, className, {
        [styles.SpinnerWhite]: theme === 'white',
        [styles.SpinnerGrey]: theme === 'grey',
        [styles.SpinnerWhiteCharcoal]: theme === 'white-charcoal',
        [styles.SpinnerContextual]: contextual,
        [styles.SpinnerRelative]: relative
      })}
    >
      <svg
        version="1.1"
        id="L1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 100 100"
        xmlSpace="preserve"
      >
        <path
          className={styles.disc}
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        />
      </svg>
      {/* <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 32 32"
        xmlSpace="preserve"
      >
        <path
          className={styles.st2}
          d="M4.9,6.2v11.9V30l5.6-1.6l5.6-1.6l5.5,1.6l5.5,1.6V18.1V6.2l-5.5-1.6l-5.5-1.7l-5.6,1.7L4.9,6.2z"
        />
        <path className={styles.st0} d="M16.1,2.9v23.8" />
        <path className={styles.st1} d="M4.9,6.2c0,0,1,10.9,11.1,10.9c11.1,0,11.1-10.9,11.1-10.9" />
      </svg> */}
    </div>
  );
};
