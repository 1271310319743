import { TypefaceLicence, TypefaceLicences, TypefaceVariant } from 'types/common';

const fontLicences: TypefaceLicences = {
  desktop: {
    slug: 'desktop',
    name: 'Desktop',
    variants: {
      desktop: {
        slug: 'desktop',
        name: 'Desktop',
        text: 'How many people will use the typeface?',
        selected: {
          name: '1 desktop user',
          multiply: 1
        },
        options: [
          { name: '1 desktop user', short: '1 people', included_discount: '0%', multiply: 1.0 },
          { name: '2 desktop users', short: '2 people', included_discount: '5%', multiply: 1.25 },
          { name: '3 desktop users', short: '3 people', included_discount: '5%', multiply: 1.5 },
          { name: '4 desktop users', short: '4 people', included_discount: '5%', multiply: 1.75 },
          { name: '5 desktop users', short: '5 people', included_discount: '5%', multiply: 2.0 },
          { name: '6 desktop users', short: '6 people', included_discount: '10%', multiply: 2.25 },
          { name: '7 desktop users', short: '7 people', included_discount: '10%', multiply: 2.5 },
          { name: '8 desktop users', short: '8 people', included_discount: '10%', multiply: 2.65 },
          { name: '9 desktop users', short: '9 people', included_discount: '10%', multiply: 2.8 },
          { name: '10 desktop users', short: '10 people', included_discount: '10%', multiply: 3.0 },
          {
            name: '20 desktop users',
            short: '20 people',
            included_discount: '15%',
            multiply: 3.197
          },
          {
            name: '30 desktop users',
            short: '30 people',
            included_discount: '21%',
            multiply: 4.615
          },
          {
            name: '40 desktop users',
            short: '40 people',
            included_discount: '31%',
            multiply: 5.696
          },
          {
            name: '50 desktop users',
            short: '50 people',
            included_discount: '38%',
            multiply: 6.55
          },
          { name: '75 desktop users', short: '75 people', included_discount: '68%', multiply: 8.1 },
          {
            name: '100 desktop users',
            short: '100 people',
            included_discount: '73%',
            multiply: 11.0
          },
          {
            name: '125 desktop users',
            short: '125 people',
            included_discount: '73%',
            multiply: 11.792
          },
          {
            name: '150 desktop users',
            short: '150 people',
            included_discount: '73%',
            multiply: 12.971
          },
          {
            name: '175 desktop users',
            short: '175 people',
            included_discount: '73%',
            multiply: 14.268
          },
          {
            name: '200 desktop users',
            short: '200 people',
            included_discount: '74%',
            multiply: 15.0
          },
          {
            name: '201+ desktop users',
            short: '201+ people',
            included_discount: '74%',
            multiply: 0
          }
        ]
      }
    }
  },
  web: {
    slug: 'web',
    name: 'Web',
    variants: {
      web: {
        slug: 'web',
        name: 'Web',
        text: 'How many page views does your website have?',
        selected: { name: '10,000 webpage views/month', multiply: 1 },
        options: [
          { name: '10,000 webpage views/month', short: '10k views/month', multiply: 1 },
          { name: '25,000 webpage views/month', short: '25k views/month', multiply: 2.5 },
          { name: '100,000 webpage views/month', short: '100k views/month', multiply: 5 },
          { name: '250,000 webpage views/month', short: '250k views/month', multiply: 10 },
          { name: '500,000 webpage views/month', short: '500k views/month', multiply: 15 },
          { name: '1,000,000 webpage views/month', short: '1m views/month', multiply: 20 },
          { name: '1,500,000 webpage views/month', short: '1.5m views/month', multiply: 25 },
          { name: '2,000,000 webpage views/month', short: '2m views/month', multiply: 30 },
          { name: '2,000,000+ webpage views/month', short: '2m+ views/month', multiply: 30 }
        ]
      }
    }
  },
  app: {
    slug: 'app',
    name: 'App',
    variants: {
      app: {
        slug: 'app',
        name: 'App',
        text: 'How many titles do you wish to download?',
        selected: { name: '1 title/year', long: '1 title for one year', multiply: 17 },
        options: [
          { name: '1 title for one year', long: '1 title for one year', multiply: 17 },
          { name: '2 titles for one year', long: '2 titles for one year', multiply: 29 },
          { name: '3 titles for one year', long: '3 titles for one year', multiply: 43 },
          { name: '4+ titles for one year', long: '4+ titles for one year', multiply: 0 }
        ]
      }
    }
  }
  // broadcast: {
  //   slug: 'broadcast',
  //   name: 'Broadcast',
  //   variants: {
  //     broadcast: {
  //       slug: 'broadcast',
  //       name: 'Broadcast',
  //       text: 'How many continents are you broadcasting to?',
  //       selected: { long: '250k annual impressions', name: '250k impressions', multiply: 1 },
  //       options: [
  //         { long: '250k annual impressions', name: '250k impressions', multiply: 1 },
  //         { long: '500k annual impressions', name: '500k impressions', multiply: 1.5 },
  //         { long: '1m annual impressions', name: '1m impressions', multiply: 2 },
  //         { long: '2m annual impressions', name: '2m impressions', multiply: 2.5 },
  //         { long: '4m annual impressions', name: '4m impressions', multiply: 3 },
  //         { long: '6m annual impressions', name: '6m impressions', multiply: 4 },
  //         { long: '8m annual impressions', name: '8m impressions', multiply: 5 },
  //         { long: '10m annual impressions', name: '10m impressions', multiply: 6 }
  //       ]
  //     }
  //   }
  // },
  // electronic: {
  //   slug: 'electronic',
  //   name: 'Electronic',
  //   variants: {
  //     electronic: {
  //       slug: 'electronic',
  //       name: 'Electronic',
  //       text: 'How many titles do you wish to download?',
  //       selected: { name: '1 title', multiply: 2 },
  //       options: [
  //         { name: '1 title', multiply: 2 },
  //         { name: '2 titles', multiply: 4 },
  //         { name: '4 titles', multiply: 8 },
  //         { name: '6 titles', multiply: 12 },
  //         { name: '8 titles', multiply: 16 },
  //         { name: '10 titles', multiply: 20 },
  //         { name: '12 titles', multiply: 24 }
  //       ]
  //     }
  //   }
  // }
};

export const multiSkuDiscounts = (variants) => {
  const cartItemsCount = variants.length;

  let multiply;
  if (cartItemsCount >= 5 && cartItemsCount <= 10) {
    multiply = 0.75;
  }

  if (cartItemsCount > 10) {
    multiply = 0.5;
  }

  return multiply;
};

export default fontLicences;
