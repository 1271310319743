import clsx from 'clsx';

import type { CloseButtonProps } from '../types';
import styles from './CloseButton.module.scss';

export const CloseButton: React.FC<CloseButtonProps> = ({ handleClose, type }) => {
  return (
    <button
      className={clsx(styles.CloseButton, {
        [styles['CloseButton--basic']]: type === 'basic',
        [styles['CloseButton--twoColumn']]: type === 'twoColumn'
      })}
      type="button"
      onClick={handleClose}
    >
      <p className={styles.CloseButton__buttonInner}>
        <span className={styles.CloseButton__closeIcon}>❌</span>
        <span className="visually-hidden">Close</span>
      </p>
    </button>
  );
};

export default CloseButton;
