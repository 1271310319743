import { ApolloError, ApolloQueryResult } from '@apollo/client';
import { useRouter } from 'next/router';
import { ReactChildren, ReactNode, useEffect, useState } from 'react';

import { getEUCountry } from '@/lib/checkout/getEUCountry';
import { CHECKOUT_TOKEN } from '@/lib/constants';
import { useLocalStorage } from '@/lib/hooks/useLocalStorage';
import { DEFAULT_LOCALE, localeToEnum } from '@/lib/regions';
import createSafeContext from '@/lib/useSafeContext';
import { getVatValidity, resetVatNumber, toggleVatRemoval } from '@/lib/vat/service';
import {
  CheckoutByTokenQuery,
  CheckoutDetailsFragment,
  useCheckoutByTokenQuery
} from '@/saleor/api';

type CheckoutFormError = {
  error: string;
};

type VatValidity = {
  message?: string;
  vatValid?: boolean;
};

type Vat = {
  country?: string;
  number?: string;
  currentChannel?: any;
  userId: string;
};

type CheckoutFormErrorUpdateTypes = 'add' | 'remove' | 'clear';

export interface CheckoutConsumerProps {
  checkoutToken: string;
  setCheckoutToken: (token: string) => void;
  resetCheckoutToken: () => void;
  loadCheckoutById: (token: string) => void;
  checkout: CheckoutDetailsFragment | undefined | null;
  checkoutError: ApolloError | undefined;
  checkoutFormErrors: Array<CheckoutFormError>;
  loading: boolean;
  refetch: ({
    checkoutToken
  }?: {
    checkoutToken: string;
  }) => Promise<ApolloQueryResult<CheckoutByTokenQuery>>;
  shippingAddress: string;
  setShippingAddress: (string) => void;
  setAddNewBillingAddress: (string) => void;
  setAddNewShippingAddress: (string) => void;
  updateCheckoutFormErrors: (CheckoutFormErrorUpdateTypes, errors?: Array<string>) => void;
  addNewBillingAddress: boolean;
  addNewShippingAddress: boolean;
  checkVatNumber: (Vat) => Promise<any>;
  vatChecking: boolean;
  setVatValidityResponse: (VatValidity) => void;
  vatValidityResponse: VatValidity;
  toggleVatOrNotVat: (vat: any, checkout: any, channel: any) => Promise<any>;
  resetAndRemoveVAT: (channel: any) => void;
  networkStatus: number;
  fetchMore: (variables) => Promise<any>;
}

export const [useCheckout, Provider] = createSafeContext<CheckoutConsumerProps>();

const checkVat = async (checkout, vatCountry, vatNumber, channel, userId) => {
  const vatResult = await getVatValidity({
    countryCode: vatCountry,
    vatNumber: vatNumber,
    checkoutToken: checkout.token,
    email: checkout.email,
    checkoutId: checkout.id,
    channel: channel,
    userId
  });

  return vatResult;
};

const resetVat = async (checkout, channel, userId) =>
  await resetVatNumber({
    checkoutToken: checkout.token,
    email: checkout.email,
    checkoutId: checkout.id,
    channel: channel,
    userId
  });

export const CheckoutProvider = ({ children }: { children: ReactChildren | ReactNode }) => {
  const router = useRouter();
  const locale = router.query.locale?.toString() || DEFAULT_LOCALE;
  const [checkoutToken, setCheckoutToken] = useLocalStorage(CHECKOUT_TOKEN, '', { sync: true });

  const [shippingAddress, setShippingAddress] = useState('sameAsBillingAddress');
  const [addNewBillingAddress, setAddNewBillingAddress] = useState(true);
  const [addNewShippingAddress, setAddNewShippingAddress] = useState(true);
  const [checkoutFormErrors, setCheckoutFormErrors] = useState([]);

  const [vatValidityResponse, setVatValidityResponse] = useState({});
  const [vatChecking, setVatChecking] = useState(false);

  const updateCheckoutFormErrors = (type, errors = []) => {
    if (type === 'clear') {
      return setCheckoutFormErrors([]);
    }

    if (type === 'add') {
      return setCheckoutFormErrors([...checkoutFormErrors, ...errors]);
    }

    if (type === 'remove') {
      const errorArray = checkoutFormErrors.filter((arrError) => {
        return !errors.includes(arrError);
      });

      return setCheckoutFormErrors(errorArray);
    }
  };

  const {
    data,
    loading,
    error: checkoutError,
    networkStatus,
    refetch,
    fetchMore
  } = useCheckoutByTokenQuery({
    variables: { checkoutToken, locale: localeToEnum(locale) },
    skip: !checkoutToken || typeof window === 'undefined',
    fetchPolicy: 'network-only'
  });

  const resetCheckoutToken = () => setCheckoutToken('');

  // Every time the country/vat number fields change
  // If the country is EU, we check the number
  // Otherwise reset/do nothing
  const checkVatNumber = async ({ number, country, currentChannel, userId }: Vat) => {
    setVatChecking(true);
    setVatValidityResponse({});
    const euCountry = getEUCountry(country);

    if (euCountry.length > 0 && number && country !== 'GB') {
      const { success, errors } = await checkVat(
        data?.checkout,
        country,
        number,
        currentChannel,
        userId
      );

      setVatValidityResponse({
        vatValid: success,
        message: success ? 'Your VAT number is valid.' : errors[0]?.message
      });
    } else {
      resetVat(data?.checkout, currentChannel, userId);
      setVatValidityResponse({});
    }

    setVatChecking(false);
  };

  // Ping VAT Number to Saleor to remove VAT or, if no number, add it back
  const toggleVatOrNotVat = async (vat, checkout, currentChannel) => {
    const userId = checkout?.user?.id;

    const vatRemovalResponse = await toggleVatRemoval({
      checkoutToken: checkout.token,
      email: checkout.email,
      checkoutId: checkout.id,
      channel: currentChannel.slug,
      countryCode: vat?.country,
      vatNumber: vat?.number,
      userId
    });

    const success = vatRemovalResponse?.taxableObject?.taxExemption;

    setVatValidityResponse({
      vatValid: success,
      message: success ? 'Your VAT number is valid.' : vatRemovalResponse?.errors[0]?.message
    });

    await fetchMore({
      variables: { checkoutToken: checkout.token, locale: localeToEnum(locale) }
    });

    return true;
  };

  // Reset and remove VAT, reinstate tax
  const resetAndRemoveVAT = async ({ currentChannel }) => {
    const userId = data?.checkout?.user?.id;
    await resetVat(data?.checkout, currentChannel, userId);

    await fetchMore({
      variables: { checkoutToken: data.checkout.token, locale: localeToEnum(locale) }
    });
    return true;
  };

  const loadCheckoutById = async (token) => {
    if (token) {
      setCheckoutToken(token);
      await fetchMore({
        variables: { checkoutToken: token, locale: localeToEnum(locale) }
      });
    }
  };

  const providerValues: CheckoutConsumerProps = {
    checkoutToken,
    setCheckoutToken,
    resetCheckoutToken,
    loadCheckoutById,
    checkout: data?.checkout,
    loading,
    checkoutError,
    checkoutFormErrors,
    updateCheckoutFormErrors,
    refetch,
    shippingAddress,
    setShippingAddress,
    setAddNewBillingAddress,
    setAddNewShippingAddress,
    addNewBillingAddress,
    addNewShippingAddress,
    checkVatNumber,
    vatChecking,
    setVatValidityResponse,
    resetAndRemoveVAT,
    toggleVatOrNotVat,
    vatValidityResponse,
    networkStatus,
    fetchMore
  };

  return <Provider value={providerValues}>{children}</Provider>;
};
