import clsx from 'clsx';
import React from 'react';

import styles from './Table.module.scss';

interface TableProps {
  header?: React.ReactElement;
  children: React.ReactElement | React.ReactElement[];
  layout?: 'fixed' | 'auto';
  variant?: string;
  cssVars?: cssVarsI;
}

interface cssVarsI {
  '--set-table-row-padding'?: string;
  '--set-table-border-color'?: string;
}

export const Table: React.FC<TableProps> = ({
  header,
  children,
  layout = 'fixed',
  variant,
  cssVars
}) => {
  const _style = { tableLayout: layout, ...cssVars };

  return (
    <table
      style={_style as React.CSSProperties}
      className={clsx(styles.table, variant && styles[variant])}
    >
      <thead>{header}</thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export const HeaderRow = ({ children }) => {
  return (
    <tr className={styles.header}>
      {React.Children.map(children, (child, i) => (
        <th key={i}>{child}</th>
      ))}
    </tr>
  );
};

interface RowProps {
  children?: React.ReactNode;
  labelColumn?: boolean;
  className?: string;
}

export const TableRow: React.FC<RowProps> = ({ children, labelColumn, className }) => {
  return (
    <tr className={clsx(styles.row, className)}>
      {children &&
        React.Children.map(children, (child, i) =>
          labelColumn && i === 0 ? <th key={i}>{child}</th> : <td key={i}>{child}</td>
        )}
    </tr>
  );
};
