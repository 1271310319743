import Image from 'next/image';

import Card from '@/components/global/Card';
import { usePaths } from '@/lib/paths';
import { ProductCardFragment } from '@/saleor/api';

import Tag from '../global/Tag';
import styles from './ProductCard.module.scss';

export interface IProductCardProps {
  product: ProductCardFragment;
  letterbox?: boolean;
}

const ProductCard = ({ product, letterbox }: IProductCardProps) => {
  const paths = usePaths();

  const { media } = product;

  const highResThumb = (thumbnailUrl) => {
    const productsInUrl = thumbnailUrl.includes('/products');
    if (!productsInUrl) {
      return media[0].url;
    }

    const thumbUrlArray = thumbnailUrl.split('/products/');
    const thumbFile = thumbUrlArray[1];
    const thumbName = thumbFile.split('_')[0];

    const matchingFullImage = media.find((image) => image.url.includes(thumbName)) || media[0];

    const letterBoxImage = media.find((image) => image.url.includes('letterbox'));

    return letterbox && letterBoxImage ? letterBoxImage.url : media[0]?.url;
  };

  return (
    <>
      <div>
        <Card
          variant="goods-card"
          link={paths.goods._slug(product.slug).$url()}
          cardHoverEffect
          renderCardAsLink
          tags={
            <>
              <Tag label={product?.category?.name} />
              {!product?.isAvailable && <Tag variant="error" label="Sold Out" />}
            </>
          }
        >
          <div className={styles.cardImage}>
            {product?.thumbnail && (
              <Image
                loading={letterbox ? 'eager' : 'lazy'}
                src={highResThumb(product?.media)}
                alt={product.name}
                width={719}
                height={480}
                objectFit="cover"
              />
            )}
          </div>
          <h4 className={styles.ProductCard__Title}>{product.name}</h4>
        </Card>
      </div>
    </>
  );
};

export default ProductCard;
