import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import Video from '@/components/cms/Video';
import { useIsVisible } from '@/lib/hooks';

import styles from '../Toast.module.scss';
import type { SlideProps, ToastMessageScrollCarouselProps } from '../types';
import { ToastMessageBodyBlock, ToastMessageBodyColumn, ToastMessageTwoColumnBody } from './';

export const ToastMessageScrollCarousel: React.FC<ToastMessageScrollCarouselProps> = ({
  slides,
  className
}) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  return (
    <ToastMessageTwoColumnBody className={className}>
      <div
        className={clsx(styles.Toast__ScrollCarouselImageContainer, {
          [styles['Toast__ScrollCarouselImageContainer--image']]: slides[0].img,
          [styles['Toast__ScrollCarouselImageContainer--video']]: slides[0].video
        })}
      >
        {slides.map((e, i) => {
          if (e.img) {
            return (
              <img
                className={clsx(styles.Toast__ScrollCarouselImage, {
                  [styles['Toast__ScrollCarouselImage--active']]: i === activeSlide
                })}
                loading={'lazy'}
                src={e.img as string}
                alt={e.title as string}
                width={480}
                height={480}
                key={i}
              />
            );
          }
          if (e.video) {
            return (
              <div
                key={i}
                className={clsx(styles.Toast__ScrollCarouselVideo, {
                  [styles['Toast__ScrollCarouselVideo--active']]: i === activeSlide
                })}
              >
                <Video controls={false} loop autoplay={i === activeSlide} file={e.video} />
              </div>
            );
          }

          if (e.card) {
            return (
              <div
                key={i}
                className={clsx(styles.Toast__ScrollCarouselCard, {
                  [styles['Toast__ScrollCarouselImage--active']]: i === activeSlide
                })}
              >
                {e.card}
              </div>
            );
          }
        })}
      </div>
      <ToastMessageBodyColumn
        withScroll
        className={clsx(styles.Toast__ScrollCarouselBody, {
          [styles['Toast__ScrollCarouselBody--image']]: slides[0].img,
          [styles['Toast__ScrollCarouselBody--video']]: slides[0].video
        })}
      >
        {slides.map((e, i) => (
          <Slide
            key={i}
            refId={i}
            title={e.title}
            body={e.body}
            setActiveSlide={setActiveSlide}
            activeSlide={activeSlide}
            isLastElement={slides.length === i + 1}
          />
        ))}
      </ToastMessageBodyColumn>
    </ToastMessageTwoColumnBody>
  );
};

export default ToastMessageScrollCarousel;

export const Slide: React.FC<SlideProps> = ({
  title,
  body,
  refId,
  setActiveSlide,
  isLastElement
}) => {
  const ref = useRef();
  const isVisible = useIsVisible({
    ref,
    options: {
      threshold: 0.5
    }
  });

  useEffect(() => {
    if (isVisible) {
      setActiveSlide(refId);
    }
  }, [isVisible]);

  return (
    <div ref={ref} className={styles.Toast__ScrollCarouselSlideWrapper}>
      <ToastMessageBodyBlock title={title} borderBottom={!isLastElement}>
        {body}
      </ToastMessageBodyBlock>
    </div>
  );
};
