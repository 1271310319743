import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { ChangeEventHandler, forwardRef } from 'react';

import InputErrorMessage from '@/components/global/InputErrorMessage';

import styles from './CheckboxPanel.module.scss';

interface CheckboxPanelProps {
  name: string;
  id?: string;
  value: string;
  label: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  isRequired?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  error?: string;
  image?: string;
  padding?: 's' | 'm';
  icon?: string;
  iconVariant?: 'onDark' | 'onWhite';
}

const CheckboxPanel = forwardRef<HTMLInputElement, CheckboxPanelProps>(
  (
    {
      name,
      id,
      value,
      label,
      onChange,
      onFocus,
      onBlur,
      isRequired,
      isChecked,
      isDisabled,
      error,
      image,
      padding,
      icon,
      iconVariant = 'onWhite'
    },
    ref
  ) => {
    const PanelIcons = dynamic(() => import('./PanelIcons'));
    return (
      <>
        <div
          className={clsx(styles.CheckboxPanel, {
            [styles[`padding-${padding}`]]: padding,
            [styles[`iconVariant-${iconVariant}`]]: iconVariant
          })}
          onClick={(ev) => ev.stopPropagation()}
          data-cy={name}
        >
          <input
            className="visually-hidden"
            type="checkbox"
            name={name}
            id={id}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            required={isRequired}
            checked={isChecked}
            disabled={isDisabled}
            ref={ref}
            aria-label={label}
            data-cy={label}
          />
          <label className={styles.CheckboxPanelLabel} htmlFor={id}>
            <div className={styles.CheckboxPanel__ImageWrapper}>
              <div className={styles.CheckboxPanel__ImageLabel}>{label}</div>
              <div className={styles.CheckboxPanel__Image}>
                {icon ? <PanelIcons iconName={icon} /> : null}
                {image ? (
                  <Image src={image} alt={value} layout="fill" loading="eager" priority />
                ) : null}
              </div>
            </div>
          </label>
        </div>
        {error && <InputErrorMessage error={error} />}
      </>
    );
  }
);

CheckboxPanel.displayName = 'CheckboxPanel';

export default CheckboxPanel;
