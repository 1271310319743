import { CUSTOMISER_PRODUCT_VARIANT_ID_DEFAULT } from './constants';
import variantNameFormatted from './fonts/variantName';
import { normalize } from './util';

export const searchMatch = (text, searchString) => {
  if (!text || !searchString) return false;
  const preparedText = normalize(text);
  const preparedSearch = normalize(searchString.trim());
  const pattern =
    preparedSearch
      .split(' ')
      .map((term) => `(?=.*${term})`)
      .join('') + '.*';
  return preparedText.search(new RegExp(pattern, 'i')) !== -1;
};

const getAttribute = (prod, name) => {
  return prod.attributes.find((attr) => attr.attribute.name === name);
};

export const getProductCategory = (prod) => {
  const attr = getAttribute(prod, 'FontCategory');
  if (!attr || attr.values.length === 0) return;
  return attr.values[0].name;
};

export const getProductLanguage = (prod) => {
  const attr = getAttribute(prod, 'FontLanguages');
  const langs = attr.values.map((val) => val.name);
  return langs;
};

export const filterProducts = (products, { search, category, language }) => {
  let filtered = [...products];

  // Filter by Category
  filtered = filtered.filter((prod) => {
    if (category === 'All Categories') return true;
    const cat = getProductCategory(prod);
    return cat ? cat === category : false;
  });

  // Filter by Language
  filtered = filtered.filter((prod) => {
    if (language === 'All Languages') return true;
    const lang = getProductLanguage(prod);
    return lang.includes(language);
  });

  // Filter Search
  filtered = filtered.filter((prod) => {
    if (!search) return true;
    return searchMatch(prod.name, search);
  });

  return filtered;
};

export const getProductCategories = (products) => {
  let categories: any[] = ['All Categories'];

  products.forEach((prod) => {
    let cat = getProductCategory(prod);
    if (cat && !categories.includes(cat)) categories.push(cat);
  });
  categories = categories.map((li) => {
    return { name: li };
  });
  return categories;
};

export const filterProductsByCategoryAndSearch = (list, category, search) => {
  // filter by category
  let filtered = list.filter((prod) => {
    if (category === 'All Categories') return true;
    const cat = getProductCategory(prod);
    return cat === category;
  });
  // filter search
  filtered = filtered.filter((prod) => {
    if (!search) return true;
    return searchMatch(prod.name, search);
  });
  return filtered;
};

export const getProductFontSizeAdjust = ({ metadata }) => {
  const fsa = metadata.find((meta) => meta.key === 'font-size-adjust');
  if (fsa) return fsa.value;
};

export const getFontDisplayName = ({ name, metadata }) => {
  const displayName = metadata.find((meta) => meta.key === 'displayName');
  if (displayName) return displayName.value;
  return name;
};

export const isFamilyVariant = (variant) =>
  variant.sku.includes('-family') ||
  variant.sku.includes('-family-pro') ||
  variant.sku.includes('-subfamily') ||
  variant.sku.includes('-subfamily-pro');

export const isNotFamilyVariant = (variant) =>
  !variant.sku.includes('-family') &&
  !variant.sku.includes('-family-pro') &&
  !variant.sku.includes('-subfamily') &&
  !variant.sku.includes('-subfamily-pro');

export const isIgnoredCustomiser = (variant) => {
  const ignoreCustomiserMetadata = variant.metadata.find(
    ({ key }) => key === 'ignored-customiser'
  )?.value;

  return ignoreCustomiserMetadata === 'true';
};

export const isVariableFont = (variant) => variant.sku.includes('-variable-');

export const getFamilyMetaSkus = (product) => {
  const familySkusMeta = product.metadata.find((metadataItem) => metadataItem.key === 'familySkus');
  const familySkus = familySkusMeta ? familySkusMeta.value.split(',') : [];

  return familySkus;
};

export const isFontItem = (lineItem) =>
  lineItem?.variant?.product?.productType?.slug === 'font-family';

export const isFontGroupItem = (lineItem) =>
  isFontItem(lineItem) && isFamilyVariant(lineItem.variant);

export const isCustomisedFont = (lineItem) =>
  lineItem?.variant?.id === CUSTOMISER_PRODUCT_VARIANT_ID_DEFAULT;

export const isPhysicalItem = (lineItem) => !isFontItem(lineItem) && !isCustomisedFont(lineItem);

export const hasCustomisedFont = (lineItems) =>
  !!lineItems.filter((lineItem) => isCustomisedFont(lineItem)).length;

export const getCustomFontName = (lineItem) => {
  const fontName = lineItem.metadata.find(({ key }) => key === 'itemCustomName')?.value;
  return fontName || variantNameFormatted(lineItem.variant);
};

export const getMetaDataValueByKey =
  ({ item }) =>
  (metaDataKey = '') => {
    return item.metadata.find(({ key }) => key === metaDataKey)?.value;
  };

export const getFontStyleBySku = (fontStyles: any[], sku: string) =>
  fontStyles.find((option) => option.sku === sku);

export const getFontMetaS3Family = (product) => {
  const s3FamilyMeta = product.metadata.find((metadataItem) => metadataItem.key === 's3Family');
  return s3FamilyMeta ? s3FamilyMeta.value.split(',').map((item) => item.trim()) : [];
};
