import { useRouter } from 'next/router';

import Button from '../../Button';
import Icon from '../../Icon';
import { Toast } from '../Toast';
import type { SuccessToastProps } from '../types';
import styles from './SuccessToast.module.scss';

export const ExpiredToast: React.FC<SuccessToastProps> = ({ successToastAction }) => {
  const router = useRouter();

  const submit = () => {
    successToastAction();
    document.body.classList.remove('ReactModal__Body--open');
  };

  return (
    <Toast
      message={
        <div>
          <div className={styles.SuccessToast__header}>
            <Icon iconName="info" color="var(--header-icon-color)" />
            {/* <Icon iconName="globe" color="var(--header-icon-color)" /> */}
          </div>

          <div className={styles.SuccessToast__body}>
            <h3 className={[styles.SuccessToast__title, styles.desktop].join(' ')}>
              Your Session Expired
            </h3>
            <h3 className={[styles.SuccessToast__title, styles.mobile].join(' ')}>
              Session Expired
            </h3>
            <p>Unfortunately your session expired and you are required to re-login.</p>
          </div>
        </div>
      }
      actions={
        <>
          <Button
            callBack={() => {
              successToastAction();
              router.push('/account/login');
            }}
            title="Log in"
            size="l"
          >
            Log in
          </Button>
          <Button variant="secondary" callBack={submit} title="Dismiss" size="l">
            Dismiss
          </Button>
        </>
      }
      // size="medium"
      position="center"
      onClose={submit}
      image="expired-modal.svg"
      asModal
      type="twoColumn"
      className={styles.SuccessToast_ExpiredSessionImage}
    />
  );
};

export default ExpiredToast;
