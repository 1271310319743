import clsx from 'clsx';
import Link from 'next/link';
import React, { VFC } from 'react';
import { DropdownItemType } from 'types/common';

import styles from './DropdownItem.module.scss';

interface DropdownItemProps {
  item: DropdownItemType;
  onSelect?: (item: DropdownItemType) => void;
  size?: 'regular' | 'small' | 'wide';
  active: boolean;
  scrollOnChange?: boolean;
}

const DropdownItem: VFC<DropdownItemProps> = ({
  item,
  onSelect,
  size,
  active,
  scrollOnChange = true
}) => {
  const handleClick = () => {
    if (onSelect) onSelect(item);
  };

  if (item?.url || item?.path)
    return (
      <Link href={item.url || item.path} scroll={scrollOnChange}>
        <a
          className={clsx(styles.DropdownItem, {
            [styles.DropdownItemSmall]: size === 'small',
            [styles.DropdownItemWide]: size === 'wide',
            [styles['DropdownItem--active']]: active
          })}
        >
          {item.name}
          {item.label && <span>{item.label}</span>}
        </a>
      </Link>
    );

  return (
    <button
      className={clsx(styles.DropdownItem, {
        [styles.DropdownItemSmall]: size === 'small',
        [styles.DropdownItemWide]: size === 'wide',
        [styles['DropdownItem--active']]: active
      })}
      type="button"
      onClick={handleClick}
    >
      {item.customContent ? item.customContent : item.name}
      {item.label && <span className={styles.DropdownItem__label}>{item.label}</span>}
    </button>
  );
};

export default DropdownItem;
