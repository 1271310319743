import { useState } from 'react';

import createSafeContext from '@/lib/useSafeContext';
import { CountryCode, useAddressValidationRulesQuery } from '@/saleor/api';

import { useCheckout } from './CheckoutProvider';

export const [useAddressValidationRules, Provider] = createSafeContext<{
  addressValidationData: any;
  setSelectedCountry: (countryCode: CountryCode) => void;
  setSelectedCountryArea: (countryArea: any) => void;
}>();

export const AddressValidationProvider = ({ children }) => {
  const { checkout } = useCheckout();

  const existingCountry = checkout?.billingAddress?.country?.code;
  const [selectedCountry, setSelectedCountry] = useState<null | CountryCode>(null);
  const activeCountry = selectedCountry || existingCountry;

  const existingCountryArea = checkout?.billingAddress?.countryArea;
  const [selectedCountryArea, setSelectedCountryArea] = useState<null | string>(null);
  const activeCountryArea = selectedCountryArea || existingCountryArea;

  const { data: addressValidationData } = useAddressValidationRulesQuery({
    variables: {
      countryCode: (activeCountry as CountryCode) || 'GB',
      countryArea: activeCountryArea
    }
  });

  return (
    <Provider value={{ addressValidationData, setSelectedCountry, setSelectedCountryArea }}>
      {children}
    </Provider>
  );
};
