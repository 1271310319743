import clsx from 'clsx';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useState } from 'react';
import ToastModal from 'react-modal';

import { BasicToastContent, DefaultToastContent, TwoColumnToastContent } from './partials';
import styles from './Toast.module.scss';
import type { ToastProps } from './types';

ToastModal.setAppElement('#root');

export const Toast: React.FC<ToastProps> = ({
  message = '',
  autoClose = false,
  autoCloseTime = 5000,
  className = '',
  onClose,
  isError = false,
  actions,
  position = 'top',
  size = 'regular',
  asModal = false,
  image,
  icon,
  type = 'default',
  title,
  autoHeight = false
}) => {
  const router = useRouter();
  const [toastMessage, setToastMessage] = useState(message);

  const handleClose = useCallback(() => {
    setToastMessage('');
    if (onClose) onClose();
    document.body.classList.remove('ReactModal__Body--open');
  }, [onClose]);

  useEffect(() => {
    const handleRouteChange = (_url, { shallow }) => {
      if (shallow) return;
      handleClose();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (!autoClose || !toastMessage) return;

    const autoCloseInterval = setInterval(() => {
      // close state
      handleClose();

      if (onClose) onClose();
    }, autoCloseTime);

    return () => clearInterval(autoCloseInterval);
  }, [autoClose, autoCloseTime, handleClose, onClose, toastMessage]);

  const ToastContentComponents = {
    default: (
      <DefaultToastContent
        size={size}
        position={position}
        icon={icon}
        image={image}
        isError={isError}
        toastMessage={toastMessage}
        actions={actions}
        handleClose={handleClose}
      />
    ),
    basic: (
      <BasicToastContent
        size={size}
        position={position}
        icon={icon}
        image={image}
        isError={isError}
        toastMessage={toastMessage}
        actions={actions}
        handleClose={handleClose}
        type={type}
        title={title}
      />
    ),
    twoColumn: (
      <TwoColumnToastContent
        size={size}
        position={position}
        icon={icon}
        image={image}
        isError={isError}
        toastMessage={toastMessage}
        actions={actions}
        handleClose={handleClose}
        type={type}
        autoHeight={autoHeight}
      />
    )
  };

  const getToastContent = () => {
    return ToastContentComponents[type];
  };

  const ToastComponent = () =>
    asModal ? (
      <ToastModal
        isOpen
        className={[styles.Toast, className].join(' ')}
        overlayClassName={clsx(styles.ToastWrapper, {
          [styles.ToastWrapperCenter]: position === 'center',
          [styles.ToastWrapperMedium]: size === 'medium',
          [styles.ToastWrapperOverlay]: true
        })}
      >
        {getToastContent()}
      </ToastModal>
    ) : (
      <div
        className={clsx(styles.ToastWrapper, {
          [styles.ToastWrapperCenter]: position === 'center',
          [styles.ToastWrapperMedium]: size === 'medium'
        })}
      >
        <div role="alert" className={[styles.Toast, className].join(' ')}>
          {getToastContent()}
        </div>
      </div>
    );

  return toastMessage ? <ToastComponent /> : null;
};

export default memo(Toast);
