import clsx from 'clsx';
import React from 'react';

import styles from './Panel.module.scss';

type Props = {
  as?: React.ElementType;
  className?: string;
};

const Panel: React.FC<Props> = ({ children, as, className }) => {
  const Tag = as || 'div';

  return (
    <Tag
      className={clsx(styles.panel, {
        [className]: className
      })}
    >
      {children}
    </Tag>
  );
};

export default Panel;
