import clsx from 'clsx';
import React from 'react';

import styles from './Panel.module.scss';

type Props = {
  variant?: 'md' | 'lg' | 'xs';
};

const PanelHeader: React.FC<Props> = ({ children, variant = 'md' }) => {
  return <header className={clsx(styles.panelHeader, styles[variant])}>{children}</header>;
};

export default PanelHeader;
