import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button from '@/components/global/Button';
import InputField from '@/components/global/InputField';
import Panel from '@/components/global/Panel/Panel';
import PanelContent from '@/components/global/Panel/PanelContent';
import { Toast, ToastMessageBody } from '@/components/global/Toast';
import { useRegions } from '@/components/providers/RegionsProvider';
import checkoutDataLayer from '@/lib/datalayer/checkout';
import { usePaths } from '@/lib/paths';
import { onlyFontsInCart } from '@/lib/util';
import { CheckoutDetailsFragment } from '@/saleor/api';

import { DEFAULT_ARROW_ICON_SIZES } from '../global/Icon/defaultIconSizes';
import PanelHeader from '../global/Panel/PanelHeader';
import { messages } from '../translations';
import { CartDiscount } from './CartDiscount';
import styles from './CartSummary.module.scss';
import { CopyToClipboardButton } from './partials';

export interface IPromoCodeFormData {
  promoCode: string;
}

export interface ICartSummaryProps {
  checkout: CheckoutDetailsFragment;
  checkoutToken: string;
  panelVariant?: 'md' | 'lg' | 'zero';
  isCheckout?: boolean;
}

let didInit = false;

export const CartSummary = ({
  checkout,
  checkoutToken,
  isCheckout,
  panelVariant = 'md'
}: ICartSummaryProps) => {
  const router = useRouter();
  const t = useIntl();
  const paths = usePaths();
  const { subtotalPrice, shippingPrice, shippingMethod, totalPrice, discount, voucherCode } =
    checkout;

  const {
    register: registerForm,
    handleSubmit: handleSubmitForm,
    formState: { errors: errorsForm },
    setError: setErrorForm
  } = useForm<IPromoCodeFormData>({ defaultValues: { promoCode: voucherCode } });
  const { formatPrice } = useRegions();

  const clipboardButtonStateToTextMap = {
    default: 'Copy To Clipboard',
    success: 'Copied!',
    error: 'Error!'
  };

  const [isShowingShareToast, setIsShowingShareToast] = useState<Boolean>(false);

  const shareUrl =
    typeof window !== 'undefined'
      ? `${window.location.origin}${paths.sharecart
          ._cartId(checkoutToken)
          .$url()
          .pathname.replace(
            '[cartId]',
            `${paths.sharecart._cartId(checkoutToken).$url().query.cartId}`
          )}`
      : '';

  const onlyFontsInTheCart = onlyFontsInCart(checkout);

  const toggleShareToast = () => {
    setIsShowingShareToast(!isShowingShareToast);
  };

  return (
    <div
      className={[
        styles.cartSummary,
        isCheckout ? styles.cartSummaryCheckout : '',
        panelVariant === 'zero' ? styles.noCartSummaryPadding : ''
      ].join(' ')}
    >
      <Panel>
        {!isCheckout && (
          <PanelHeader variant="xs">
            <h3 className="u-h6">Summary</h3>
          </PanelHeader>
        )}
        <PanelContent variant={panelVariant}>
          <section>
            <div>
              <div>
                <dl className={styles.cartTotals}>
                  <div>
                    <dt>{t.formatMessage(messages.subtotal)}</dt>
                    <dd>{formatPrice(subtotalPrice?.net)}</dd>
                  </div>
                  <div className={styles.cartTotals__Shipping}>
                    <dt>{`${t.formatMessage(messages.shipping)} ${
                      shippingMethod?.name ? `-  (${shippingMethod?.name})` : ''
                    }`}</dt>
                    <dd>{onlyFontsInTheCart ? 'N/A' : formatPrice(shippingPrice?.gross)}</dd>
                  </div>
                  <div className={styles.cartTotals__Tax}>
                    <dt>{t.formatMessage(messages.tax)}</dt>
                    <dd>{formatPrice(subtotalPrice?.tax)}</dd>
                  </div>
                  {!!discount?.amount && (
                    <div className={styles.cartTotals__Discount}>
                      <dt>Discount Code {voucherCode && `[${voucherCode}]`}</dt>
                      <dd>{formatPrice(discount)}</dd>
                    </div>
                  )}
                  <div className={styles.cartTotals__grandTotal}>
                    <dt>{t.formatMessage(messages.total)}</dt>
                    <dd>{formatPrice(totalPrice?.gross)}</dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </PanelContent>
      </Panel>
      {!isCheckout && (
        <CartDiscount
          promoCode={voucherCode}
          formErrors={errorsForm}
          setErrorForm={setErrorForm}
          checkoutToken={checkoutToken}
          handleSubmitForm={handleSubmitForm}
          registerForm={registerForm}
          panelVariant={panelVariant}
        />
      )}
      {router.pathname !== '/checkout' && (
        <div className={styles.cartActions}>
          {isShowingShareToast && (
            <Toast
              title={'Share your Cart'}
              message={
                <>
                  <ToastMessageBody>
                    <p>
                      Need to share the cart with your collaborator, colleague or client to checkout
                      and license? Please copy and paste the link below.
                    </p>
                  </ToastMessageBody>
                  <ToastMessageBody className={styles.CartSummary__CopyUrlRow}>
                    <InputField
                      onWhite
                      label="shareUrl"
                      type="text"
                      name="shareUrl"
                      value={shareUrl}
                    />
                    <CopyToClipboardButton shareUrl={shareUrl} />
                  </ToastMessageBody>
                </>
              }
              position="center"
              onClose={() => {
                toggleShareToast();
              }}
              asModal
              type="basic"
            />
          )}
          <Button
            callBack={() => {
              toggleShareToast();
            }}
            className={styles.modalTriggerSplit}
            type="button"
            size="l"
            variant="secondary"
          >
            Share
          </Button>
          <Button
            // add 'begin_checkout' to the dataLayer
            callBack={() => checkoutDataLayer.beginCheckout(checkout?.lines || [])}
            href={paths.checkout.$url()}
            variant="primary"
            size="l"
          >
            {t.formatMessage(messages.checkoutButton)}
          </Button>
        </div>
      )}
    </div>
  );
};
