type PurchaseTracking = {
  lines: Array<any>;
};

declare global {
  interface Window {
    dataLayer: any;
  }
}

const pushAddToCartDataLayer = ({ lines }: PurchaseTracking) => {
  if (typeof window === 'undefined') return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: lines
    }
  });
};

export default pushAddToCartDataLayer;
