import styles from './Button.module.scss';

const generateClasses = ({
  variant,
  size,
  muted,
  align,
  fit_width_to_content,
  className,
  textColor,
  isActive
}: any) => {
  const classes = [styles.buttonText, className];

  classes.push(styles[`size-${size}`]);
  if (variant === 'primary') classes.push(styles.isPrimary);
  if (variant === 'secondary') classes.push(styles.isSecondary);
  if (variant === 'secondary-filled') classes.push(styles['isSecondary--filled']);
  if (variant === 'tertiary') classes.push(styles['isTertiary']);
  if (variant === 'ghost') classes.push(styles.isGhost);
  if (variant === 'asLink') classes.push(styles.asLink);
  if (variant === 'asLinkActive') classes.push(styles.asLinkActive);
  if (variant === 'error') classes.push(styles.isError);

  if (muted) classes.push(styles.isMuted);

  if (align) classes.push(styles[`align-${align}`]);
  if (fit_width_to_content) classes.push(styles.fitWidth);
  if (textColor) classes.push(styles.forceWhite);
  if (isActive) classes.push(styles.isActive);

  return classes.join(' ');
};

export default generateClasses;
