import clsx from 'clsx';
import React, { ReactNode, VFC } from 'react';

import styles from './InputErrorMessage.module.scss';

interface InputErrorMessageProps {
  error: string | ReactNode;
}

const InputErrorMessage: VFC<InputErrorMessageProps> = ({ error }) => (
  <p
    className={clsx(styles.InputErrorMessage, {
      [styles.error]: error
    })}
    data-testid="InputErrorMessage"
  >
    {error}
  </p>
);

export default InputErrorMessage;
