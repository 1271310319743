import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '@/components/global/Button';
import CustomFont from '@/components/global/CustomFont';
import FontLoader from '@/components/global/FontLoader';
import Icon from '@/components/global/Icon';
import InputField from '@/components/global/InputField';
import { useCheckout } from '@/components/providers/CheckoutProvider';
import { useRegions } from '@/components/providers/RegionsProvider';
import { messages } from '@/components/translations';
import checkoutDataLayer from '@/lib/datalayer/checkout';
import { generateFamilyVariantS3Path, generateVariantS3Path } from '@/lib/fonts/s3FontPath';
import variantNameFormatted, { variantNameWithVersionSuffix } from '@/lib/fonts/variantName';
import { usePaths } from '@/lib/paths';
import { isCustomisedFont, isFamilyVariant, isFontItem } from '@/lib/productHelpers';
import { updateFontsToWebfont } from '@/lib/util';
import {
  CheckoutLineDetailsFragment,
  ErrorDetailsFragment,
  useCheckoutLineUpdateMutation,
  useRemoveProductFromCheckoutMutation
} from '@/saleor/api';

import CustomisedFontMetadata from '../CustomisedFontMetadata';
import styles from './CheckoutLineItem.module.scss';

interface CheckoutLineItemProps {
  line: CheckoutLineDetailsFragment;
  disableChanges?: boolean;
  licencingButton?: any;
  isCartOwner?: boolean;
  isCheckout?: boolean;
  isOrder?: boolean;
}

export const CheckoutLineItem = ({
  line,
  disableChanges,
  licencingButton,
  isCartOwner = false,
  isCheckout,
  isOrder
}: CheckoutLineItemProps) => {
  const paths = usePaths();
  const t = useIntl();
  const { query, formatPrice } = useRegions();
  const { checkoutToken: token } = useCheckout();
  const [checkoutLineUpdateMutation, { loading: loadingLineUpdate }] =
    useCheckoutLineUpdateMutation();
  const [removeProductFromCheckout] = useRemoveProductFromCheckoutMutation();

  const [quantity, setQuantity] = useState<number>();
  const [changed, setChanged] = useState<boolean>(null);
  const [errors, setErrors] = useState<ErrorDetailsFragment[] | null>(null);

  useEffect(() => {
    if (!line) return;
    setQuantity(line.quantity);
  }, [line]);

  const changeLineState = (event: any) => {
    if (!event?.target?.validity?.valid) return;
    setQuantity(event.target.value);
  };

  const onQuantityUpdate = async (event: any, value?: number) => {
    if (!value) {
      changeLineState(event);
      if (!event?.target?.validity?.valid || event?.target?.value === '') return;
    }
    const result = await checkoutLineUpdateMutation({
      variables: {
        token: token,
        lines: [
          {
            quantity: value ? value : parseFloat(event.target.value),
            variantId: line?.variant.id || ''
          }
        ],
        locale: query.locale
      }
    });
    const errors = result.data?.checkoutLinesUpdate?.errors;
    if (errors && errors.length > 0) {
      setErrors(errors);
    }
  };

  const onMobileKeyboardClick = (method: 'plus' | 'minus') => {
    if (method === 'plus') {
      setQuantity(quantity + 1);
    } else if (method === 'minus') {
      setQuantity(quantity > 1 ? quantity - 1 : 1);
    }
    setChanged(true);
  };

  useEffect(() => {
    if (changed) {
      const timer = setTimeout(() => {
        setChanged(false);
        onQuantityUpdate(undefined, quantity);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [quantity, changed]);

  if (!line) return null;

  const productHasImage = (line) => line.variant?.product?.thumbnail?.url;
  const productIsFont = (line) => isFontItem(line) || isCustomisedFont(line);
  const productIsFontFamily = (line) => productIsFont(line) && isFamilyVariant(line.variant);

  const productLineItemClass =
    isOrder || isCheckout ? styles.lineItemProduct : styles.lineItemProductCart;

  const getFontS3Path = (line) => {
    if (productIsFontFamily(line)) {
      return generateFamilyVariantS3Path(line?.variant?.product.slug, line?.variant);
    }

    if (isCustomisedFont(line)) {
      const itemCustomiserMetadata = line?.metadata.find(({ key }) => key === 'itemCustomiser');
      const itemCustomiserMetadataValue = JSON.parse(itemCustomiserMetadata.value);
      const [itemCustomiser] = itemCustomiserMetadataValue;

      return updateFontsToWebfont({ font: itemCustomiser.fontPathSrc });
    }

    return generateVariantS3Path(line?.variant?.product.slug, line?.variant.sku);
  };

  const getFontName = (line) => {
    if (isCustomisedFont(line)) {
      const itemCustomiserMetadata = line?.metadata.find(({ key }) => key === 'itemCustomiser');
      const itemCustomiserMetadataValue = JSON.parse(itemCustomiserMetadata.value);
      const [itemCustomiser] = itemCustomiserMetadataValue;

      return itemCustomiser.fontStyle;
    }

    return line?.variant.sku;
  };

  const fontVariantName = (variant, variantName) =>
    variantNameWithVersionSuffix(variant, variantName);

  return (
    <article className={!productIsFont(line) ? productLineItemClass : styles.lineItem}>
      {!productIsFont(line) ? (
        <>
          <div className={styles.physicalItem}>
            <div className={styles.physicalItem__header}>
              <div className={styles.lineItem__image}>
                {productHasImage(line) && (
                  <Image
                    src={line.variant.product.thumbnail.url || ''}
                    alt={line.variant?.product?.thumbnail?.alt || ''}
                    layout="fill"
                    objectFit="cover"
                  />
                )}
              </div>
              <div>
                <p className={styles.physicalItem__name}>
                  <Link href={paths.goods._slug(line?.variant?.product?.slug).$url()}>
                    <a>{line?.variant?.product.name}</a>
                  </Link>
                </p>
                {line?.variant?.product?.productType?.hasVariants && (
                  <p className={styles.physicalItem__variant}>
                    <span className={styles.physicalItem__type}>Variant: </span>
                    {variantNameFormatted(line?.variant)}
                  </p>
                )}
                <div className={styles.physicalItem__sub}>
                  {disableChanges && (
                    <p className={styles.physicalItem__variant}>
                      <span className={styles.physicalItem__type}>Quantity</span> {line?.quantity}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className={styles.lineItem__actions}>
                <div
                  className={
                    isOrder || isCheckout ? styles.lineItem__price : styles.lineItem__priceCart
                  }
                >
                  <p>{formatPrice(line?.totalPrice?.gross)}</p>
                </div>

                {!disableChanges && (
                  <>
                    <Button
                      variant="ghost"
                      callBack={() => {
                        removeProductFromCheckout({
                          variables: {
                            checkoutToken: token,
                            lineId: line?.id,
                            locale: query.locale
                          }
                        });
                        // add 'remove_from_cart' to the dataLayer
                        checkoutDataLayer.removeFromCart(line);
                      }}
                    >
                      <Icon iconName="close-circle" />
                      <span className="sr-only">{t.formatMessage(messages.removeButton)}</span>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>

          {!isCheckout && (
            <div className={styles.physicalItem__Bottom}>
              {!disableChanges && (
                <div className={styles.physicalItemQuantity}>
                  <InputField
                    className={styles.quantityInput}
                    hasLabel
                    name="qty"
                    label="Quantity"
                    type="number"
                    value={`${quantity}`}
                    onFocus={() => {
                      setErrors(null);
                    }}
                    onChange={(ev) => changeLineState(ev)}
                    onBlur={(ev) => onQuantityUpdate(ev)}
                    onKeyPress={(ev: KeyboardEvent) => {
                      if (ev.key === 'Enter') {
                        onQuantityUpdate(ev);
                      }
                    }}
                    min={1}
                    isRequired
                    isDisabled={loadingLineUpdate}
                    pattern="[0-9]*"
                    disablePointerEvents
                    hasMobileKeyboard
                    mobileKeyboardClickEvent={onMobileKeyboardClick}
                  />
                </div>
              )}

              <div className={styles.physicalItemTotalPrice}>
                <p>{formatPrice(line?.totalPrice?.gross)}</p>
              </div>
            </div>
          )}

          {errors && (
            <div className={styles.errors}>
              {errors.map((err) => (
                <span key={err.field}>{err.message}</span>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          <FontLoader fullPath fontName={getFontName(line)} s3Path={getFontS3Path(line)} />
          {productIsFont(line) && (
            <CustomFont uuid={line.id.replace(/=/g, '')} fontName={getFontName(line)}>
              <div className={styles.fontSample}>
                <span>Aa</span>
              </div>
            </CustomFont>
          )}
          <div className={styles.lineItem__info}>
            <h3 className={styles.lineItem__name}>
              {isFontItem(line) && (
                <Link href={paths.typefaces._slug(line?.variant?.product?.slug).$url()}>
                  <a>{fontVariantName(line?.variant, variantNameFormatted(line?.variant))}</a>
                </Link>
              )}
            </h3>
            {isCustomisedFont(line) && (
              <CustomisedFontMetadata metadata={line.metadata} variant={line.variant} />
            )}
          </div>
          <div className={styles.lineItem__actions}>
            {licencingButton && <div className={styles.lineItem__licence}>{licencingButton}</div>}
            <div className={styles.lineItem__price}>
              <p>{formatPrice(line?.totalPrice?.gross)}</p>
            </div>
            {isCartOwner && !disableChanges && (
              <>
                <Button
                  variant="ghost"
                  callBack={() => {
                    removeProductFromCheckout({
                      variables: {
                        checkoutToken: token,
                        lineId: line?.id,
                        locale: query.locale
                      }
                    });
                    // add 'remove_from_cart' to the dataLayer
                    checkoutDataLayer.removeFromCart(line);
                  }}
                >
                  <Icon iconName="close-circle" />
                  <span className="sr-only">{t.formatMessage(messages.removeButton)}</span>
                </Button>
                {errors && (
                  <div>
                    {errors.map((err) => (
                      <span key={err.field}>{err.message}</span>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          {licencingButton && (
            <div className={styles.lineItem__licenceMobile}>{licencingButton}</div>
          )}
        </>
      )}
    </article>
  );
};

export default CheckoutLineItem;
