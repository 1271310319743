import { useEffect, useState } from 'react';

import createSafeContext from '@/lib/useSafeContext';

export const [useSiteTicker, Provider] = createSafeContext<{
  showTicker: string;
  setShowTicker: (string) => void;
  hideTicker: () => void;
}>();

export const SiteTickerProvider = ({ children }) => {
  const [showTicker, setShowTicker] = useState('false');

  useEffect(() => {
    const saved = typeof window !== 'undefined' ? localStorage.getItem('site-ticker') : 'false';

    setShowTicker(saved || 'true');
  }, []);

  useEffect(() => {
    const tickerHeight = showTicker === 'false' ? '0px' : '40px';

    document.documentElement.style.setProperty('--ticker-height', tickerHeight);
  }, [showTicker]);

  const hideTicker = () => {
    localStorage.setItem('site-ticker', 'false');
    setShowTicker('false');
  };

  const providerValue = {
    showTicker: showTicker,
    setShowTicker,
    hideTicker
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
