import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import { DropdownItemType } from 'types/common';

import DropdownItem from '@/components/global/DropdownItem';
import Icon from '@/components/global/Icon';

import { DEFAULT_ARROW_ICON_SIZES } from '../Icon/defaultIconSizes';
import styles from './Dropdown.module.scss';

interface DropdownProps {
  items: DropdownItemType[];
  defaultValue?: DropdownItemType;
  onSelect?: (item: DropdownItemType) => DropdownItemType;
  size?: 'regular' | 'small' | 'wide';
  transparency?: Boolean;
  color?: string;
  align?: string;
  id?: string;
  fullWidth?: boolean;
  inline?: boolean;
  direction?: 'up' | 'down';
  disabled?: boolean;
  active?: boolean;
  showDropdown?: boolean;
  transparentMenu?: boolean;
  scrollOnChange?: boolean;
}

const Dropdown: FC<DropdownProps> = ({
  items,
  defaultValue,
  onSelect,
  size,
  transparency,
  color,
  align,
  id,
  direction = 'down',
  fullWidth = false,
  inline = false,
  disabled = false,
  active = false,
  showDropdown = true,
  transparentMenu = false,
  scrollOnChange = true
}) => {
  const router = useRouter();
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(items?.length);
  const selectedValue = defaultValue ? defaultValue.name : 'Choose...';

  const handleSelect = (item: DropdownItemType) => {
    setIsOpen(false);
    onSelect(item);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    const selectedIndex = items.findIndex((item) => item.name === selectedValue);

    if (event.key === 'ArrowDown' && selectedIndex !== items.length - 1) {
      onSelect(items[selectedIndex + 1]);
    } else if (event.key === 'ArrowUp' && selectedIndex !== 0) {
      onSelect(items[selectedIndex - 1]);
    } else if (event.key === 'Tab') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const handleOpen = () => {
      setIsOpen(false);
    };

    router.events.on('routeChangeStart', handleOpen);

    return () => {
      router.events.off('routeChangeStart', handleOpen);
    };
  }, []);

  function createStyles() {
    return {
      __html: `
      #${id} {
        --dropdown-color: ${color};
      }
    `
    };
  }

  if (!items?.length) return null;

  const selectedOption = (
    <>
      {color && <style dangerouslySetInnerHTML={createStyles()} />}
      <button
        type="button"
        className={clsx(styles.Button, {
          [styles.ButtonSmall]: size === 'small',
          [styles.ButtonWide]: size === 'wide',
          [styles.ButtonFullWidth]: fullWidth,
          [styles.DropdownIsActive]: active
        })}
        {...buttonProps}
        disabled={disabled}
        onKeyDown={onKeyDown}
      >
        {defaultValue?.customContent ? (
          defaultValue.customContent
        ) : (
          <span className={styles.ButtonText}>{selectedValue}</span>
        )}
        {showDropdown && (
          <span className={styles.ButtonIcon}>
            <Icon
              color={color || 'var(--dropdown-color)'}
              iconName={`arrow-${isOpen ? 'up' : 'down'}`}
              {...DEFAULT_ARROW_ICON_SIZES}
            />
          </span>
        )}
      </button>
    </>
  );

  return (
    <div
      id={id}
      className={clsx(styles.DropdownContainer, {
        [styles.DropdownContainerTransparent]: transparency,
        [styles.DropdownContainerInline]: inline,
        [styles.DropdownContainerWide]: size === 'wide'
      })}
    >
      {selectedOption}
      <div
        className={clsx(styles.DropdownListWrapper, {
          [styles.DropdownListVisible]: isOpen,
          [styles.DropdownListWrapperSmall]: size === 'small',
          [styles.DropdownListWrapperWide]: size === 'wide',
          [styles.DropdownListWrapperUpwards]: direction === 'up',
          [styles.DropdownListWrapperFilled]: !transparentMenu
        })}
        role="menu"
      >
        {selectedOption}
        <ul
          className={clsx(styles.DropdownList, {
            [styles.DropdownListSmall]: size === 'small',
            [styles.DropdownListWide]: size === 'wide'
          })}
        >
          {items.map((item) => {
            return (
              <li
                key={item.name}
                className={clsx(styles.DropdownItem, {
                  [styles['DropdownItem__align-left']]: align === 'left'
                })}
              >
                <DropdownItem
                  item={item}
                  onSelect={handleSelect}
                  size={size}
                  active={selectedValue === item.name}
                  scrollOnChange={scrollOnChange}
                  {...itemProps}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
