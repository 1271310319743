import { memo } from 'react';

import Tag from '@/components/global/Tag';
import variantNameFormatted from '@/lib/fonts/variantName';

import styles from './ModalStyles.module.scss';

const AddToCartInstruction = ({ variants, cartFontsList, watchFontVersion }) => {
  const singleVersionFonts = cartFontsList.filter(
    (variant) =>
      !variants.some(
        (oppVariant) => oppVariant.sku.replace(/-pro$/, '') === variant.sku.replace(/-pro$/, '')
      )
  );

  if (singleVersionFonts.length === 0) return;

  const variables = singleVersionFonts.map((font) => {
    return <Tag key={font.id} label={variantNameFormatted(font)} />;
  });

  return (
    <>
      <div className={styles.instructions}>
        The following fonts are not available as {watchFontVersion}: {variables}
      </div>
    </>
  );
};

export default memo(AddToCartInstruction);
