import { ReactChildren, ReactNode, useReducer } from 'react';

import createSafeContext from '@/lib/useSafeContext';

export interface TypefaceConsumerProps {
  state: any;
  setStep: (string) => void;
  setSelectedLicences: (array) => void;
  setMultipliers: (array) => void;
  setMultiplierValue: (number) => void;
  setCartFontsList: (array) => void;
  setCartSubTotalAmount: (number) => void;
  setCartTotalAmount: (number) => void;
  setAddToCartResponse: (object) => void;
  setAddToCartError: (boolean) => void;
  setMaxUsageAmountError: (boolean) => void;
  setAddToCartResult: (string) => void;
  updateMultiplier: (multipliers: Array<any>, licence: string) => void;
  removeLicenceMultiplier: (multipliers: Array<any>, licence: string) => void;
  setIsChecked: (object) => void;
  setIsAddedToCart: (boolean) => void;
  setIsOpen: (boolean) => void;
  setIsLoading: (boolean) => void;
  resetState: () => void;
}

export const [useTypefaceAddToCart, Provider] = createSafeContext<TypefaceConsumerProps>();

export const TypefaceProvider = ({ children }: { children: ReactChildren | ReactNode }) => {
  const MAX_USAGE_VALUES = [
    '201+ desktop users',
    '2,000,000+ webpage views/month',
    '10m+ downloads',
    '4+ titles for one year'
  ];

  const initialState = {
    maxUsageValues: MAX_USAGE_VALUES,
    isChecked: {},
    step: 0,
    selectedLicences: [],
    isAddedToCart: false,
    multipliers: [],
    multiplierValue: 0,
    cartFontsList: [],
    cartSubTotalAmount: 0,
    cartTotalAmount: 0,
    addToCartResponse: {},
    addtoCartError: false,
    maxUsageError: false,
    loading: false,
    addToCartResult: '',
    isOpen: false
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'setStep':
        return { ...state, step: action.payload };
      case 'setSelectedLicences':
        return { ...state, selectedLicences: action.payload };
      case 'setMultipliers':
        return { ...state, multipliers: action.payload };
      case 'setMultiplierValue':
        return { ...state, multiplierValue: action.payload };
      case 'setCartFontsList':
        return { ...state, cartFontsList: action.payload };
      case 'setCartSubTotalAmount':
        return { ...state, cartSubTotalAmount: action.payload };
      case 'setCartTotalAmount':
        return { ...state, cartTotalAmount: action.payload };
      case 'setAddToCartResponse':
        return { ...state, addToCartResponse: action.payload };
      case 'setAddToCartError':
        return { ...state, addtoCartError: action.payload };
      case 'setMaxUsageAmountError':
        return { ...state, maxUsageError: action.payload };
      case 'setAddToCartResult':
        return { ...state, addToCartResult: action.payload };
      case 'setIsChecked':
        return { ...state, isChecked: action.payload };
      case 'setIsAddedToCart':
        return { ...state, isAddedToCart: action.payload };
      case 'setIsOpen':
        return { ...state, isOpen: action.payload };
      case 'setIsLoading':
        return { ...state, loading: action.payload };
      case 'resetState':
        return initialState;
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const setStep = (value) => dispatch({ type: 'setStep', payload: value });
  const setSelectedLicences = (value) => dispatch({ type: 'setSelectedLicences', payload: value });
  const setMultipliers = (value) => dispatch({ type: 'setMultipliers', payload: value });
  const setMultiplierValue = (value) => dispatch({ type: 'setMultiplierValue', payload: value });

  const setCartFontsList = (value) => dispatch({ type: 'setCartFontsList', payload: value });
  const setCartSubTotalAmount = (value) =>
    dispatch({ type: 'setCartSubTotalAmount', payload: value });
  const setCartTotalAmount = (value) => dispatch({ type: 'setCartTotalAmount', payload: value });

  const setAddToCartResponse = (value) =>
    dispatch({ type: 'setAddToCartResponse', payload: value });
  const setAddToCartError = (value) => dispatch({ type: 'setAddToCartError', payload: value });
  const setMaxUsageAmountError = (value) =>
    dispatch({ type: 'setMaxUsageAmountError', payload: value });
  const setAddToCartResult = (value) => dispatch({ type: 'setAddToCartResult', payload: value });
  const setIsChecked = (value) => dispatch({ type: 'setIsChecked', payload: value });
  const setIsAddedToCart = (value) => dispatch({ type: 'setIsAddedToCart', payload: value });
  const setIsOpen = (value) => dispatch({ type: 'setIsOpen', payload: value });
  const resetState = () => dispatch({ type: 'resetState' });
  const setIsLoading = (value) => dispatch({ type: 'setIsLoading', payload: value });

  const updateMultiplier = (multipliers, licences) => {
    const licenceData = JSON.parse(licences);
    const { licenceVariant } = licenceData;
    const multiplierInState = multipliers.some(
      (multiplier) => multiplier.licenceVariant === licenceVariant
    );

    let newMultipliers;

    if (multiplierInState) {
      newMultipliers = [
        ...multipliers.filter((multiplier) => multiplier.licenceVariant !== licenceVariant),
        licenceData
      ];
    } else {
      newMultipliers = [...multipliers, licenceData];
    }

    setMultipliers(newMultipliers);
  };

  const removeLicenceMultiplier = (multipliers, licence) => {
    const newMultipliers = multipliers.filter(
      (multiplier) => multiplier.licenceName !== licence.name
    );
    setMultipliers(newMultipliers);
  };

  const providerValues: TypefaceConsumerProps = {
    state: state,
    setStep,
    setSelectedLicences,
    setMultipliers,
    setMultiplierValue,
    setCartFontsList,
    setCartSubTotalAmount,
    setCartTotalAmount,
    setAddToCartResponse,
    setAddToCartError,
    setMaxUsageAmountError,
    setAddToCartResult,
    updateMultiplier,
    setIsChecked,
    setIsAddedToCart,
    removeLicenceMultiplier,
    setIsOpen,
    setIsLoading,
    resetState
  };

  return <Provider value={providerValues}>{children}</Provider>;
};
