import { usePaths } from '@/lib/paths';
import { translate } from '@/lib/translations';
import { notNullable } from '@/lib/util';
import { ProductDetailsFragment } from '@/saleor/api';

import Dropdown from '../global/Dropdown';

export interface IVariantSelectorProps {
  product: ProductDetailsFragment;
  selectedVariantID?: string;
}

export const VariantSelector = ({ product, selectedVariantID }: IVariantSelectorProps) => {
  const paths = usePaths();

  const variants = product.variants;
  if (!variants || variants.length === 1) {
    return null;
  }

  const selectedVariant = variants.find((variant) => variant.id === selectedVariantID);

  return (
    <>
      <Dropdown
        defaultValue={{ name: selectedVariant ? selectedVariant.name : variants[0].name }}
        items={variants.map((variant) => {
          if (!notNullable(variant)) {
            return null;
          }

          return {
            name: translate(variant, 'name'),
            ...(variant.quantityAvailable === 0 && { label: 'Sold Out' }),
            path: paths.goods._slug(product.slug).$url({ query: { variant: variant.id } })
          };
        })}
        inline
        size="small"
        fullWidth
        scrollOnChange={false}
      />
    </>
  );
};

export default VariantSelector;
