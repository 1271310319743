import { getMetaDataValueByKey } from '../productHelpers';

const ignoredPropertiesMap = {
  ignoredAxes: 'ignored-axes',
  ignoredOpentype: 'ignored-opentype',
  ignoredOpentypeCustomiser: 'ignored-opentype-customiser'
};

export const getIgnoredAxes = (variant) => {
  const ignoredAxes: string = getMetaDataValueByKey({ item: variant })(
    ignoredPropertiesMap.ignoredAxes
  );
  const ignoredAxesArray = (ignoredAxes && ignoredAxes.split(',')) || [];

  return ignoredAxesArray;
};

export const getIgnoredOpentype = (variant, { isCustomiser = false } = {}) => {
  const ignoredPropertiesKey = isCustomiser ? 'ignoredOpentypeCustomiser' : 'ignoredOpentype';

  const ignoredOpentypeString: string = getMetaDataValueByKey({ item: variant })(
    ignoredPropertiesMap[ignoredPropertiesKey]
  );
  const ignoredOpentype = ignoredOpentypeString ? ignoredOpentypeString.split(',') : '';

  const ignoredOpentypeArray = Array.isArray(ignoredOpentype) ? ignoredOpentype : [ignoredOpentype];
  const ignoredOpentypeArrayFormatted = ignoredOpentypeArray.map((item) =>
    item.toLowerCase().trim()
  );

  return ignoredOpentypeArrayFormatted;
};

export const getFilteredAxes = (fontAxes, ignoredAxes) => {
  return fontAxes.filter((axis) => !ignoredAxes.includes(axis.id.toLowerCase()));
};
