import { TypefaceLicence } from 'types/common';

import { VariantGroup, VariantsPublicAttrs } from '@/lib/fonts/types';
import { isFamilyVariant, isNotFamilyVariant } from '@/lib/productHelpers';
import { CheckoutDetailsFragment } from '@/saleor/api';

export const capitalizeFirstLetter = (string) => {
  let input = string.replace(/\W/g, '');
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const getFontS3Sku = (fontSku: string) => {
  const isPro = fontSku.endsWith('-pro');
  const fontFileSku = isPro ? fontSku.replace('-pro', '') : fontSku;
  return fontFileSku;
};

/* Would like to use ProductVariant type, but requires privateMetaData */
export const getFontGroups = (variants) => {
  const fontGroups = variants.reduce((acc, variant) => {
    const fontStyleGroup = variant?.metadata.find((metadata) => metadata.key === 'group')?.value;

    if (!fontStyleGroup) return acc;

    const fontStyleGroupLowerCase = fontStyleGroup.toLowerCase();

    if (acc.includes(fontStyleGroupLowerCase)) return acc;

    return [...acc, fontStyleGroupLowerCase];
  }, []);

  return fontGroups;
};

export const nonFamilyGroupVariants = (variants: Array<VariantsPublicAttrs>) =>
  variants.filter((variant) => isNotFamilyVariant(variant));

export const inStockVariants = (variants: Array<VariantsPublicAttrs>) =>
  variants.filter((variant) => variant.quantityAvailable > 0);

export const availableVariantsForSelection = (
  variants: Array<VariantsPublicAttrs>,
  filterOutFamilies: boolean
) =>
  filterOutFamilies ? inStockVariants(nonFamilyGroupVariants(variants)) : inStockVariants(variants);

type FontsByGroup = {
  variants: Array<VariantsPublicAttrs>;
  variantGroup: VariantGroup;
  groupVariantsByMeta?: boolean;
};

const getFontVariantGroupMeta = (variant) =>
  variant?.metadata.find((metadata) => metadata.key === 'group')?.value.toLowerCase();

export const fontsByGroup = ({
  variants,
  variantGroup,
  groupVariantsByMeta = false
}: FontsByGroup) => {
  const fontVariantsGrouped = variants.filter((variant) => variant.sku.includes(variantGroup));
  const fontVariantsGroupedByMeta = groupVariantsByMeta
    ? variants.filter((variant) => {
        return getFontVariantGroupMeta(variant) === variantGroup;
      })
    : [];

  return fontVariantsGroupedByMeta.length ? fontVariantsGroupedByMeta : fontVariantsGrouped;
};

export const variantExtractor = (obj: TypefaceLicence) => {
  return Object.entries(obj.variants).map(
    ([_key, value]) => `licence${capitalizeFirstLetter(value.name)}`
  );
};

type FontFamilyGroups = {
  variants: Array<VariantsPublicAttrs>;
  checkout: CheckoutDetailsFragment;
};

export const fontFamilyGroups = ({ variants, checkout }: FontFamilyGroups) => {
  return inStockVariants(variants)
    .filter((variant) => variant.quantityAvailable > 0)
    .filter((variant) => isFamilyVariant(variant));
};
