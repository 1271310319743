import Link from 'next/link';
import { forwardRef, KeyboardEventHandler, MouseEvent } from 'react';

import { useScreenSize } from '@/lib/hooks/mediaQuery';
import { getFontMetaS3Family } from '@/lib/productHelpers';

import styles from './Button.module.scss';
import generateClasses from './helpers';

interface Props {
  /**
   * Url for the button.
   */
  href?: any;
  /**
   * Content of the button.
   */
  children: any;
  /**
   * Block button.
   */
  block?: boolean;
  /**
   * Block button.
   */
  align?: 'left' | 'center' | 'right';
  fit_width_to_content?: boolean;
  /**
   * Extra className of the button.
   */
  className?: string;
  /**
   * Button type- primary, secondary...
   */
  variant?: string;
  title?: string;
  /**
   *    CSS-variable Colour names
   */
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  size?: 's' | 'l' | 'xl' | 'none';
  dynamicSize?: boolean;
  /**
   * Changes button opacity.
   */
  muted?: boolean;
  disabled?: boolean;
  /**
   * Button type
   */
  type?: 'button' | 'submit' | 'reset';
  /**
   * Button callback function
   */
  callBack?: (event: MouseEvent<HTMLButtonElement>) => void;
  onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
  textColor?: 'white';
  isActive?: boolean;
  id?: string;
  role?: string;
  tabIndex?: number;
  ariaLabel?: string;
  target?: '_blank' | '_parent' | '_self' | '_top';
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'primary',
      size = 's',
      align,
      block = false,
      color,
      children,
      href = '',
      fit_width_to_content = false,
      muted = false,
      type,
      className = '',
      title = '',
      callBack,
      disabled,
      textColor,
      isActive,
      backgroundColor,
      id,
      role,
      tabIndex,
      ariaLabel,
      target,
      dynamicSize = true,
      ...otherProps
    },
    ref
  ) => {
    const classes = generateClasses({
      variant,
      size,
      muted,
      align,
      fit_width_to_content,
      className,
      textColor,
      isActive
    });

    return href ? (
      <Link href={href}>
        <a
          title={title}
          className={classes}
          style={{ backgroundColor }}
          target={target}
          aria-label={ariaLabel}
          // @ts-ignore
          onClick={callBack}
        >
          <span className={styles.iconWrapper}>{children}</span>
        </a>
      </Link>
    ) : (
      <button
        ref={ref}
        id={id !== undefined ? id : null}
        role={role !== undefined ? role : null}
        tabIndex={tabIndex !== undefined ? tabIndex : null}
        title={title}
        type={type}
        className={[classes, className].join(' ')}
        // @ts-ignore
        disabled={disabled}
        onClick={callBack}
        style={{ backgroundColor }}
        aria-label={ariaLabel}
        {...otherProps}
      >
        <span className={styles.iconWrapper}>{children}</span>
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
