import { FontAxes, FontStyleVersion } from '@/components/global/PanelFont/types';

import { CustomiserForm, CustomiserFormNames, FontStylesActiveByTypeByVersion } from '../types';

export const getStepActiveIndexFromQuery = (queryStep: string | string[]): number => {
  const step = Array.isArray(queryStep) ? queryStep[0] : queryStep;
  const stepAsNumber = parseInt(step, 10);

  if (!stepAsNumber) return 0;

  return stepAsNumber;
};

export const getFormDefaultValues = (
  form: CustomiserForm,
  formState?: { [key in CustomiserFormNames]?: any },
  customFormStateKey?: string
): { [key in CustomiserFormNames]?: any } => {
  const formFields = Object.values(form);
  const formDefaultValues = formFields.reduce((acc, formField) => {
    return {
      ...acc,
      [formField.name]: formState ? formState[formField.name] : formField.defaultValue
    };
  }, {});

  // Some values of the form state are grouped by a custom key and nested
  // and need to be accessed via that key
  return customFormStateKey ? formDefaultValues[customFormStateKey] : formDefaultValues;
};

export const getFontStylesFeaturesGroups = (fontStyles) => {
  const fontStylesFeaturesGroups = fontStyles.reduce((acc, fontStyleCart) => {
    if (!fontStyleCart.fondue.featuresGroups) return acc;

    return [...acc, ...fontStyleCart.fondue.featuresGroups];
  }, []);

  return fontStylesFeaturesGroups;
};

// ensure only unique features across all font styles returned
export const getFontStylesFeaturesGroupsWithUniqueFeaturesOnly = (
  featureGroups
): {
  [key: string]: { name: string; features: { [key: string]: any }[] };
} => {
  const fontStylesFeaturesGroupsWithUniqueFeaturesOnly = featureGroups.reduce(
    (acc, featureGroup) => {
      if (!featureGroup.features) return acc;

      const featureGroupNameAsSlug = featureGroup.name.toLowerCase().replace(' ', '-');

      const features = acc[featureGroupNameAsSlug]
        ? [...acc[featureGroupNameAsSlug].features, ...featureGroup.features]
        : [...featureGroup.features];
      const uniqueFeatures = Array.from(new Set(features.map((feature) => feature.tag))).map(
        (tag) => {
          return features.find((feature) => feature.tag === tag);
        }
      );

      return {
        ...acc,
        [featureGroupNameAsSlug]: {
          name: featureGroup.name,
          features: Array.from(uniqueFeatures)
        }
      };
    },
    {}
  );

  return fontStylesFeaturesGroupsWithUniqueFeaturesOnly;
};

// Get groupings if they're provided
// Ref: AddTypefaceToCart.tsx and variantGroupings
// We have to treat this differently as font bases don't match what is being shown in the cart
// following conversation with MG.
export const getFontBases = (fontStyles) => {
  const fontBases = fontStyles.reduce((acc, fontStyle) => {
    const fontStyleBase = fontStyle?.metadata.find((metadata) => metadata.key === 'base')?.value;

    if (!fontStyleBase) return acc;

    const fontStyleBaseLowerCase = fontStyleBase.toLowerCase();

    if (acc.includes(fontStyleBaseLowerCase)) return acc;

    return [...acc, fontStyleBaseLowerCase];
  }, []);

  return fontBases;
};

const getFontStylesVariableAxes = (fontStyles) => {
  const fontStylesVariableAxes = fontStyles.reduce((acc, fontStyle) => {
    const fontStyleIsVariable = fontStyle.fondue.isVariable;
    const fontStyleVariableAxes = fontStyleIsVariable ? fontStyle.fondue.variable.axes : [];

    return [...acc, ...fontStyleVariableAxes];
  }, []);

  const uniqueFontStylesVariableAxes = Array.from(
    new Map(fontStylesVariableAxes.map((fontAxes) => [fontAxes.id, fontAxes])).values()
  );

  return uniqueFontStylesVariableAxes;
};

// Format fontStyles data to be grouped by base style
export const getFontStylesByBase = (fontStyles, fontBases) => {
  const fontStylesVariableAxes = getFontStylesVariableAxes(fontStyles);

  const fontStylesByBases = fontBases.reduce((acc, fontBase) => {
    const fontBaseSlug = fontBase.toLowerCase().replace(' ', '-').trim();

    const fontStylesByBase = fontStyles.filter((fontStyle) => {
      const fontStyleBase = fontStyle?.metadata.find((metadata) => metadata.key === 'base')?.value;

      if (!fontStyleBase) return false;

      return fontBase.toLowerCase() === fontStyleBase.toLowerCase();
    });

    return {
      ...acc,
      [fontBaseSlug]: {
        name: fontBase,
        slug: fontBaseSlug,
        styles: fontStylesByBase,
        axes: fontStylesVariableAxes
      }
    };
  }, {});

  return fontStylesByBases;
};

export const getFontStylesByTypeByVersion = (fontStyles) =>
  fontStyles.reduce((acc, fontStyle): FontStylesActiveByTypeByVersion => {
    // Type
    const fontStyleType = fontStyle.fondue.isVariable ? 'variable' : 'static';

    // Version
    const fontStyleVersion = fontStyle.sku.match(/-pro$/) ? 'pro' : 'std';
    // Accumulator by version
    const fontStyleByVersion = {
      [fontStyleVersion]:
        acc[fontStyleType] && acc[fontStyleType][fontStyleVersion]
          ? [...acc[fontStyleType][fontStyleVersion], fontStyle]
          : [fontStyle]
    };

    // Accumulator by type, by version
    const fontStyleByTypeByVersion = {
      [fontStyleType]: acc[fontStyleType]
        ? {
            ...acc[fontStyleType],
            ...fontStyleByVersion
          }
        : {
            ...fontStyleByVersion
          }
    };

    return {
      ...acc,
      ...fontStyleByTypeByVersion
    };
  }, {});

// Try to return the "best" layout features
// ref: https://github.com/Wakamai-Fondue/wakamai-fondue-site/blob/master/src/components/report/FeatureControls.js#L22
export const getFeatureChars = (font) => {
  if (!font.featureChars) return {};

  if ('DFLT' in font.featureChars && 'dflt' in font.featureChars['DFLT']) {
    return font.featureChars['DFLT']['dflt'];
  } else if ('latn' in font.featureChars && 'dflt' in font.featureChars['latn']) {
    return font.featureChars['latn']['dflt'];
  } else if (Object.keys(font.featureChars).length > 0) {
    // If all else fails, return first
    const first = Object.keys(font.featureChars)[0];
    return Object.values(font.featureChars[first])[0];
  } else {
    return {};
  }
};

export const getFeatureGroupFeaturesByFeatureChars = (featureChars) => (features) => {
  const featureGroupFeatures = features.reduce((acc, feature) => {
    if (!featureChars[feature.tag]) return acc;

    if (!featureChars[feature.tag]['lookups'].length) return acc;

    const chars = featureChars[feature.tag]['lookups'].map((lookup) => {
      return {
        type: lookup.type,
        typeName: lookup.typeName,
        chars: lookup.input
      };
    });

    return [
      ...acc,
      {
        ...feature,
        chars
      }
    ];
  }, []);

  return featureGroupFeatures;
};

export const filterFontStylesByBaseAndVersionAndType = (
  fontStyles,
  fontBase,
  fontVersion
): FontStyleVersion[] => {
  const fontStylesByVersionAndType = fontStyles.reduce((acc, fontStyle) => {
    const isVariable = fontStyle.fondue.isVariable;
    const baseMetadata = fontStyle?.metadata.find(({ key }) => key === 'base');
    const version = fontStyle.sku.match(/-pro$/) ? 'pro' : 'std';

    const base = baseMetadata?.value.toLowerCase();

    if (isVariable || base !== fontBase || version !== fontVersion) return acc;

    return [
      ...acc,
      {
        fondue: {
          summary: {
            'Font subfamily': fontStyle.fondue.summary['Font subfamily']
          },
          meta: {
            path: fontStyle.fondue.meta.path
          }
        }
      }
    ];
  }, []);

  return fontStylesByVersionAndType;
};

export const getFontAxesMidValue = (fontAxes: FontAxes) => {
  return Math.round(fontAxes.min + (fontAxes.max - fontAxes.min) / 2);
};
