import invariant from 'ts-invariant';

import {
  CHECKOUT_CUSTOMER_ATTACH,
  CHECKOUT_CUSTOMER_DETACH,
  PASSWORD_RESET,
  TOKEN_CREATE,
  TOKEN_REFRESH
} from '@/lib/auth/mutations';
import { CheckoutDetailsFragment } from '@/saleor/api';

import { SaleorAuthStorageHandler } from './SaleorAuthStorageHandler';
import {
  CustomerAttachResponse,
  CustomerDetachResponse,
  CustomerDetachVariables,
  Fetch,
  PasswordResetResponse,
  PasswordResetVariables,
  TokenCreateResponse,
  TokenCreateVariables,
  TokenRefreshResponse
} from './types';
import { getRequestData, isExpiredToken } from './utils';

export interface SaleorAuthClientProps {
  onAuthRefresh?: (isAuthenticating: boolean) => void;
  saleorApiUrl: string;
  storage: Storage;
}

export class SaleorAuthClient {
  private accessToken: string | null = null;
  private tokenRefreshPromise: null | Promise<Response> = null;
  private onAuthRefresh?: (isAuthenticating: boolean) => void;
  private saleorApiUrl: string;
  private storageHandler: SaleorAuthStorageHandler;
  /**
   * Use ths method to clear event listeners from storageHandler
   *  @example
   *  ```jsx
   *  useEffect(() => {
   *    return () => {
   *      SaleorAuthClient.cleanup();
   *    }
   *  }, [])
   *  ```
   */
  cleanup: SaleorAuthStorageHandler['cleanup'];

  constructor({ saleorApiUrl, storage, onAuthRefresh }: SaleorAuthClientProps) {
    this.storageHandler = new SaleorAuthStorageHandler(storage);
    this.onAuthRefresh = onAuthRefresh;
    this.saleorApiUrl = saleorApiUrl;

    this.cleanup = this.storageHandler.cleanup;
  }

  private hasValidAccessToken = () => !!this.accessToken && !isExpiredToken(this.accessToken);

  private runAuthorizedRequest: Fetch = (input, init) => {
    // technically we run this only when token is there
    // but just to make typescript happy

    if (!this.hasValidAccessToken()) {
      return fetch(input, init);
    }

    const headers = init?.headers || {};

    return fetch(input, {
      ...init,
      headers: { ...headers, Authorization: `Bearer ${this.accessToken as string}` }
    });
  };

  private handleRequestWithTokenRefresh: Fetch = async (input, init) => {
    const refreshToken = this.storageHandler.getRefreshToken();

    invariant(refreshToken, 'Missing refresh token in token refresh handler');

    // the refresh already finished, proceed as normal
    if (this.hasValidAccessToken()) {
      return this.fetchWithAuth(input, init);
    }

    this.onAuthRefresh?.(true);

    // if the promise is already there, use it
    if (this.tokenRefreshPromise) {
      const response = await this.tokenRefreshPromise;

      const res: TokenRefreshResponse = await response.json();

      const {
        errors: graphqlErrors = [],
        data: {
          tokenRefresh: { errors, token }
        }
      } = res;

      this.onAuthRefresh?.(false);

      if (errors.length || graphqlErrors.length || !token) {
        this.tokenRefreshPromise = null;
        this.storageHandler?.clearAuthStorageExpiry();
        return fetch(input, init);
      }

      this.storageHandler.setAuthState('signedIn');
      this.accessToken = token;
      this.tokenRefreshPromise = null;
      return this.runAuthorizedRequest(input, init);
    }

    // this is the first failed request, initialize refresh
    this.tokenRefreshPromise = fetch(
      this.saleorApiUrl,
      getRequestData(TOKEN_REFRESH, { refreshToken })
    );
    return this.fetchWithAuth(input, init);
  };

  private checkoutCustomerAttach = async (checkout: CheckoutDetailsFragment) => {
    if (checkout?.id && !checkout?.user) {
      const response = await this.runAuthorizedRequest(
        this.saleorApiUrl,
        getRequestData(CHECKOUT_CUSTOMER_ATTACH, { checkoutId: checkout?.id })
      );

      const readResponse: CustomerAttachResponse = await response.json();

      const {
        data: { checkoutCustomerAttach: { errors = [] } = {} }
      } = readResponse;

      if (errors?.length) {
        // @todo - how do we want to handle an error on attach?
        // to confirm with Saleor. Probably just fail silently.
      }
    }

    return;
  };

  fetchWithAuth: Fetch = async (input, init) => {
    const refreshToken = this.storageHandler?.getRefreshToken();

    // access token is fine, add it to the request and proceed
    if (this.hasValidAccessToken()) {
      return this.runAuthorizedRequest(input, init);
    }

    // refresh token exists, try to authenticate if possible
    if (refreshToken) {
      return this.handleRequestWithTokenRefresh(input, init);
    }

    // any regular mutation, no previous sign in, proceed
    return fetch(input, init);
  };

  resetPassword = async (variables: PasswordResetVariables) => {
    const response = await fetch(this.saleorApiUrl, getRequestData(PASSWORD_RESET, variables));

    return this.handleSignIn<PasswordResetResponse>(response);
  };

  signIn = async (variables: TokenCreateVariables) => {
    const response = await fetch(this.saleorApiUrl, getRequestData(TOKEN_CREATE, variables));

    return this.handleSignIn<TokenCreateResponse>(response, variables?.checkout);
  };

  private handleSignIn = async <TOperation extends TokenCreateResponse | PasswordResetResponse>(
    response: Response,
    checkout?: CheckoutDetailsFragment
  ): Promise<TOperation> => {
    const readResponse: TOperation = await response.json();

    const responseData =
      'tokenCreate' in readResponse.data
        ? readResponse.data.tokenCreate
        : readResponse.data.setPassword;

    if (!responseData) {
      return readResponse;
    }

    const { errors, token, refreshToken } = responseData;

    if (!token || errors.length) {
      this.storageHandler.setAuthState('signedOut');
      return readResponse;
    }

    if (token) {
      this.accessToken = token;
    }

    if (refreshToken) {
      this.storageHandler.setRefreshToken(refreshToken);
    }

    this.storageHandler.setAuthState('signedIn');

    // attach if there's a checkout
    if (checkout?.id) await this.checkoutCustomerAttach(checkout);
    return readResponse;
  };

  signOut = () => {
    this.accessToken = null;
    this.storageHandler.clearAuthStorage();
  };

  checkoutSignOut = async (variables: CustomerDetachVariables) => {
    // customer detach needs auth so run it and then remove all the tokens
    const response = await this.runAuthorizedRequest(
      this.saleorApiUrl,
      getRequestData(CHECKOUT_CUSTOMER_DETACH, variables)
    );

    const readResponse: CustomerDetachResponse = await response.json();

    const {
      data: {
        checkoutCustomerDetach: { errors }
      }
    } = readResponse;

    if (!errors?.length) {
      this.signOut();
    }

    return readResponse;
  };
}
