import Checkbox from '@/components/global/Checkbox';
import CustomFont from '@/components/global/CustomFont';
import { useCheckout } from '@/components/providers/CheckoutProvider';
import variantNameFormatted from '@/lib/fonts/variantName';

import styles from './FontGroup.module.scss';
import { getFontS3Sku } from './helpers';

const FontGroup = ({ variantGroup, register, errors, fonts, disable }) => {
  if (fonts.length === 0) {
    return (
      <div className="Alert">
        Sorry, no fonts available. You may already have these fonts in your cart. If you wish to
        license the same fonts multiple times, please purchase separately.
      </div>
    );
  }

  return (
    <div>
      <ul className={disable ? styles.fontGroupDisabled : styles.fontGroup}>
        {fonts.map((font) => {
          const variantTypeAttribute = font.attributes.find(
            (attribute) => attribute.attribute.slug === 'variant-type'
          );
          const variantTypeAttributeValues = variantTypeAttribute.values;

          let nonPurchaseable = false;
          if (variantTypeAttributeValues.length > 0) {
            const isNoPurchase = variantTypeAttributeValues.some(
              (value) => value.name === 'NoPurchase'
            );
            nonPurchaseable = isNoPurchase;
          }

          return (
            <li key={font.id}>
              <CustomFont uuid={font.id} fontName={getFontS3Sku(font.sku)}>
                <Checkbox
                  value={font.sku}
                  id={font.sku}
                  name="fonts"
                  {...register('fonts', { required: true })}
                  label={variantNameFormatted(font, variantGroup)}
                  error={errors.fonts && 'This field is Required'}
                  isPanel
                  boxContent="Aa"
                  tooltip={variantNameFormatted(font, variantGroup)}
                  isDisabled={disable || nonPurchaseable}
                  hideCheckbox={nonPurchaseable}
                />
              </CustomFont>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FontGroup;
