import React, { FC, useState } from 'react';

import Button from '@/components/global/Button';
import Tag from '@/components/global/Tag';

import Modal from '../../../global/Modal';
import {
  BasicToastContent,
  ToastMessageBody,
  ToastMessageScrollCarousel
} from '../../../global/Toast';
import infoModalStyles from '../InfoModal.module.scss';

interface LicenceTypeModalProps {}

export const LicenceVersionModal: FC<LicenceTypeModalProps> = ({}) => {
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);

  const hideModal = () => {
    setIsShowingModal(false);
  };

  const showModal = () => {
    setIsShowingModal(true);
  };

  const handleClose = () => {
    hideModal();
    document.body.classList.remove('ReactModal__Body--open');
  };

  const LicenseModalData = [
    {
      title: (
        <div className={infoModalStyles.LicenseModalHeader}>
          <h5>
            Professional <span></span>
          </h5>
          <p>
            <Tag label="Recommended" />
          </p>
        </div>
      ),
      body: (
        <p>
          Professional (PRO) variants include the full CF-Standard character set, but may also
          contain support for Cyrillic and Greek scripts. Furthermore, extension stylistic sets and
          OpenType features will be included within PRO versions to give further flexibility and
          options for all type users. Every family has different stylistic sets and OpenType
          features within their Standard and Professional variants. Please check individual family
          pages to see the extent of features available.
        </p>
      ),
      video: { id: 123123, filename: '/videos/PRO.mp4' }
    },
    {
      title: (
        <div className={infoModalStyles.LicenseModalHeader}>
          <h5 className={infoModalStyles.title}>Standard</h5>
        </div>
      ),
      body: (
        <p>
          Standard (STD) variants contain the CF-Standard character map and no additional OpenType
          features. Most Standard variants will only contain basic ligatures.
          <br />
          <br />
          Please check individual family pages to see the extent of features available.
        </p>
      ),
      video: { id: 123321123, filename: '/videos/STD.mp4' }
    }
  ];

  return (
    <div className={infoModalStyles.infoModalContainer}>
      <p>Only certain styles are available with the standard character sets.</p>
      <Button callBack={showModal} variant="asLink" type="button">
        Learn more about Professional and Standard
      </Button>
      {isShowingModal && (
        <Modal
          asWrapper
          controlled
          controlledOpen={isShowingModal}
          controlledSetOpen={handleClose}
          maxWidth="100%"
          modalPadding="0"
        >
          <BasicToastContent
            position="center"
            handleClose={handleClose}
            type="basic"
            title="Font Versions"
            toastMessage={
              <>
                <ToastMessageBody borderBottom>
                  <p>
                    Colophon offers two core versions of each font family which is selected as part
                    of the checkout and licensing process. Below we highlight differences between
                    these types.
                  </p>
                </ToastMessageBody>

                <ToastMessageScrollCarousel
                  className={infoModalStyles.LicenseVersionModal__ScrollCarousel}
                  slides={LicenseModalData}
                />
              </>
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default LicenceVersionModal;
