import { SITE_URL } from '@/lib/constants';

interface Props {
  getSbImage: (image: string, size: number, quality?: number) => string;
  isSVG: (image: string) => boolean;
}

export const useSbImage = (): Props => {
  const isSVG = (image) => image.endsWith('.svg');

  const getSbImage = (image, size, quality = 100) => {
    const host = `${SITE_URL}cdn/`;

    return isSVG(image)
      ? image.replace('https://a.storyblok.com/', host)
      : image.replace('https://a.storyblok.com/', host) +
          `/m/${size}x0/filters:quality(${quality})`;
  };

  return {
    getSbImage,
    isSVG
  };
};

export default useSbImage;
