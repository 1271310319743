import clsx from 'clsx';

import Button from '@/components/global/Button';
import CustomFont from '@/components/global/CustomFont';
import Icon from '@/components/global/Icon';
import { useTypefaceAddToCart } from '@/components/providers/TypefaceProvider';
import variantNameFormatted, { variantNameWithVersionSuffix } from '@/lib/fonts/variantName';
import { getFamilyMetaSkus, isFamilyVariant, isVariableFont } from '@/lib/productHelpers';

import { getFontS3Sku } from './helpers';
import styles from './Summary.module.scss';

const Summary = ({
  product,
  cartFontsList,
  fontPrice,
  handleRemoveItem,
  formatTotal,
  cartSubTotalAmount,
  allowEditing,
  cartTotalAmount
}) => {
  const isFontFamilyGroup = (cartFont) =>
    cartFont.familySkuNames && cartFont.familySkuNames.length > 0;
  // Some skus will be from other products
  // For these, we just reformat the sku into a namer as best we can
  const formattedSku = ({ sku, productName }) => {
    return sku
      .replace(productName.toLowerCase(), '')
      .replace(/-pro$/, '')
      .replace(/-/g, ' ')
      .trim();
  };

  const {
    state: { maxUsageError }
  } = useTypefaceAddToCart();

  const cartFontsListArray = cartFontsList.map((cartFont) => {
    if (isFamilyVariant(cartFont) || isVariableFont(cartFont)) {
      const familySkus = getFamilyMetaSkus(cartFont);
      const familySkuProductName = familySkus.map((sku) => {
        const skuVariant = product.variants.find((variant) => {
          return variant.sku.trim() === sku.trim();
        });

        const skuName = skuVariant
          ? variantNameFormatted(skuVariant)
          : formattedSku({ sku, productName: cartFont?.product?.name });
        return skuName;
      });
      return {
        ...cartFont,
        familySkuNames: familySkuProductName
      };
    }

    return cartFont;
  });

  const skuDisplayFont = (cartFont) => {
    const isFamilyFont = isFamilyVariant(cartFont);
    return isFamilyFont ? getFamilyMetaSkus(cartFont)[0] : cartFont.sku;
  };

  const fontVariantName = (variant, variantName) =>
    variantNameWithVersionSuffix(variant, variantName);

  return (
    <div className={styles.summary} data-cy="typeface-add-to-cart-summary">
      <div className={styles.summary__header}>
        <div>Summary</div>
      </div>
      <div className={styles.summary__header}>
        <h5 className={styles.summary__title}>{product.name}</h5>
      </div>
      <div className={styles.summary__content}>
        {cartFontsListArray.length > 0 ? (
          <ul className={styles.summary__list}>
            {cartFontsListArray.map((cartFont) => (
              <li className={styles['summary__list-wrapper-item']} key={cartFont.id}>
                <div
                  className={clsx(
                    styles['summary__list-item'],
                    isFontFamilyGroup(cartFont) && styles['summary__list-item__border']
                  )}
                >
                  <CustomFont uuid={cartFont.id} fontName={getFontS3Sku(skuDisplayFont(cartFont))}>
                    <div className={styles.customFont}>Aa</div>
                  </CustomFont>
                  <h6 className={styles.name}>
                    {fontVariantName(cartFont, variantNameFormatted(cartFont))}
                  </h6>
                  <p className={styles.price}>{!maxUsageError ? fontPrice(cartFont) : '-'}</p>
                  {allowEditing && (
                    <Button
                      variant="ghost"
                      title={`Remove ${cartFont.sku}`}
                      callBack={() => handleRemoveItem(cartFont)}
                    >
                      <Icon iconName="close-circle" />
                    </Button>
                  )}
                </div>
                {isFontFamilyGroup(cartFont) && (
                  <div className={clsx(styles.fontFamilyGroup, 'with-scrollbar')}>
                    <ul className={styles.fontFamilyGroup__List}>
                      {cartFont.familySkuNames.map((familySkuName) => (
                        <li className={styles.fontFamilyGroup__ListItem} key={familySkuName}>
                          {familySkuName}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <div style={{ padding: '1rem' }} />
        )}
        <div className={styles.total}>
          <p>Total</p>
          <h4 className={styles.totalValue + ' u-h4'}>
            {!maxUsageError ? formatTotal(cartTotalAmount) : '-'}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Summary;
