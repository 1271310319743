import clsx from 'clsx';

import type { MessageBodyProps } from '../types';
import styles from './ModalContent.module.scss';

export const ModalMessageBodyColumn: React.FC<MessageBodyProps> = ({
  children,
  className,
  borderBottom,
  withScroll
}) => {
  return (
    <div
      className={clsx(styles.ModalContent__MessageBodyColumn, className, {
        [styles['ModalContent__MessageBody--borderBottom']]: borderBottom,
        [styles['ModalContent__MessageBodyColumn--withScroll']]: withScroll,
        ['with-scrollbar']: withScroll
      })}
    >
      {children}
    </div>
  );
};

export default ModalMessageBodyColumn;
