import React, { useEffect, useState } from 'react';

interface Props {
  fontName: string;
  s3Path: string;
  fullPath?: boolean;
  fontDisplay?: 'fallback' | 'swap' | 'block' | 'optional';
}

const FontLoader: React.FC<Props> = ({ fontName, s3Path, fullPath }) => {
  const [fontLoaded, setFontLoaded] = useState(false);
  const fontNameNoSpaces = fontName.replace(/\s+/g, '');

  useEffect(() => {
    if (!fontLoaded) {
      const font = new FontFace(
        fontNameNoSpaces,
        `url(${fullPath ? process.env.NEXT_PUBLIC_S3_WEBFONT_BUCKET_PATH : ''}${s3Path})`
      );

      document.fonts.add(font);

      font
        .load()
        .then(() => {
          setFontLoaded(true);
        })
        .catch(() => {
          setFontLoaded(true);
        });
    }
  }, [fullPath, s3Path, fontNameNoSpaces]);

  return <></>;
};

export default FontLoader;
