import clsx from 'clsx';
import React from 'react';

import styles from './Grid.module.scss';

interface IColumn {
  sm: number;
  md: number;
  lg: number;
  xl?: number;
  className?: string;
  desktopOrder?: number;
  noColumnPadding?: boolean;
}

export const Column: React.FC<IColumn> = ({
  children,
  sm,
  md,
  lg,
  xl,
  className,
  desktopOrder,
  noColumnPadding
}) => {
  const nodeStyles = {
    ...(noColumnPadding && { padding: `0` })
  } as React.CSSProperties;

  return (
    <div
      className={clsx(
        styles[`column-sm-${sm}`],
        styles[`column-md-${md}`],
        styles[`column-lg-${lg}`],
        styles[`column-xl-${xl}`],
        styles[`column-order-d-${desktopOrder}`],
        className
      )}
      style={nodeStyles}
    >
      {children}
    </div>
  );
};
