import clsx from 'clsx';
import React from 'react';

import styles from './Panel.module.scss';

type Props = {
  variant?: 'md' | 'lg' | 'zero' | 'checkout';
};

const PanelContent: React.FC<Props> = ({ children, variant = 'md' }) => {
  return <div className={clsx(styles.panelContent, styles[variant])}>{children}</div>;
};

export default PanelContent;
