import styles from '../Toast.module.scss';
import type { MessageBodyProps } from '../types';

export const ModalMessageTwoColumnBody: React.FC<MessageBodyProps> = ({ children, className }) => {
  return (
    <div className={[styles.Toast__MessageTwoColumnBody, className].join(' ')}>{children}</div>
  );
};

export default ModalMessageTwoColumnBody;
