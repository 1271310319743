import { fontVersion } from './fontVersion';

function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ');
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(' ');
}

const variantNameFormatted = (variant, variantGroup = '', lowerCase = true) => {
  const { metadata } = variant;
  const { name: variantName = '' } = variant;

  const formattedVariantName = lowerCase ? variantName.toLowerCase() : variantName;
  const metadataName =
    metadata && metadata.some((meta) => meta.key === 'name')
      ? metadata.find((meta) => meta.key === 'name').value
      : null;

  const name = metadataName || formattedVariantName;

  return titleCase(name);
};

export const variantNameWithVersionSuffix = (variant, variantName) => {
  const variantIsPro = fontVersion(variant) === 'pro';

  return variantIsPro ? `${variantName} (Pro)` : `${variantName} (Std)`;
};

export default variantNameFormatted;
