interface AWSEndpoint {
  [key: string]: AWSEndpointMethod;
}

interface AWSEndpointMethod {
  [key: string]: Function;
}

interface AWSEndpointMethodOptions {
  resource?: string;
  method?: string;
  body?: string;
  params?: Object;
  pathAppend?: string;
}

class AWSRequest {
  endpoints: AWSEndpoint;

  constructor() {
    this.endpoints = {
      fontData: {
        fondue: ({ params, pathAppend }) => {
          return {
            method: 'GET',
            resource: `typefaces/${pathAppend}`,
            params
          };
        }
      },
      fontDownload: {
        post: ({ body }) => {
          return {
            method: 'POST',
            resource: 'fonts/download',
            body
          };
        }
      },
      fontGlyphsData: {
        get: ({ params, pathAppend }) => {
          return {
            method: 'GET',
            resource: `typefaces/${pathAppend}`,
            params
          };
        }
      }
    };
  }

  request(endpoint) {
    const apiUrl = process.env.NEXT_PUBLIC_AWS_API_URL;

    let reqUrl = `${apiUrl}/${endpoint.resource}`;
    if (endpoint.params) {
      const qs = Object.keys(endpoint.params)
        .map((key) => `${key}=${endpoint.params[key]}`)
        .join('&');

      reqUrl = `${apiUrl}/${endpoint.resource}?${qs}`;
    }

    return fetch(reqUrl, {
      method: endpoint?.method,
      body: endpoint?.body ? endpoint.body : null
    })
      .then(async (response) => {
        const data = await response.json();
        return data;
      })
      .catch((error) => {
        return error;
      });
  }

  fontData(method: string, options: AWSEndpointMethodOptions) {
    const existingEndpoint = this.endpoints.fontData[method];

    if (existingEndpoint) {
      const endpoint = existingEndpoint(options);
      return this.request(endpoint);
    }
  }

  fontGlyphsData(method: string, options: AWSEndpointMethodOptions) {
    const existingEndpoint = this.endpoints.fontGlyphsData[method];

    if (existingEndpoint) {
      const endpoint = existingEndpoint(options);
      return this.request(endpoint);
    }
  }

  fontDownload(method: string, options: AWSEndpointMethodOptions) {
    const existingEndpoint = this.endpoints.fontDownload[method];

    if (existingEndpoint) {
      const endpoint = existingEndpoint(options);
      return this.request(endpoint);
    }
  }
}

export const AWSClient = new AWSRequest();
