import clsx from 'clsx';
import React, { ChangeEventHandler, forwardRef } from 'react';

import InputErrorMessage from '@/components/global/InputErrorMessage';

import Tag from '../Tag';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
  name: string;
  id?: string;
  value: string;
  label: string | React.ReactNode;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  isRequired?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
  error?: string;
  subLabel?: string;
  boxContent?: string;
  link?: string;
  tag?: string;
  hideLabel?: boolean;
  padding?: 's' | 'm';
  isPanel?: boolean;
  isBordered?: boolean;
  isSmall?: boolean;
  isLarge?: boolean;
  border?: string;
  ariaLabel?: string;
  hideCheckbox?: boolean;
  count?: string;
  discount?: string;
  recommended?: boolean;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      name,
      id,
      value,
      label,
      onChange,
      onFocus,
      onBlur,
      isRequired,
      isChecked,
      isDisabled,
      error,
      subLabel,
      boxContent,
      isPanel,
      isBordered,
      link,
      tag,
      hideLabel,
      padding,
      isSmall,
      isLarge,
      hideCheckbox,
      ariaLabel,
      count,
      discount,
      recommended
    },
    ref
  ) => (
    <>
      <div
        className={clsx(styles.Checkbox, {
          [styles[`padding-${padding}`]]: padding
        })}
        onClick={(ev) => ev.stopPropagation()}
      >
        <input
          className="visually-hidden"
          type="checkbox"
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={isRequired}
          checked={isChecked}
          disabled={isDisabled}
          ref={ref}
          aria-label={typeof label === 'string' ? label : ariaLabel}
        />
        <label
          className={clsx(styles.CheckboxLabel, {
            [styles['CheckboxLabel--Panel']]: isPanel,
            [styles['CheckboxLabel--Border']]: isBordered,
            [styles['CheckboxLabel--Small']]: isSmall,
            [styles['CheckboxLabel--disabled']]: isDisabled,
            [styles['CheckboxLabel--hideCheckbox']]: hideCheckbox,
            [styles['CheckboxLabel--discount']]: count
          })}
          htmlFor={id}
        >
          <div className={styles.CheckboxLabel__Content}>
            {boxContent && <div className={styles.CheckboxLabel__BoxContent}>{boxContent}</div>}

            {!hideLabel && (
              <span>
                {tag && <span className={styles.CheckboxLabel__Tag}>{tag}</span>}
                {label && (
                  <div
                    className={
                      isLarge ? styles.CheckboxLabel__LabelLarge : styles.CheckboxLabel__Label
                    }
                  >
                    {label}
                  </div>
                )}
                {subLabel && <div className={styles.CheckboxLabel__SubLabel}>{subLabel}</div>}
                {link && (
                  <a href={link} className={styles.Checkbox__SubLink}>
                    Learn more
                  </a>
                )}
              </span>
            )}
          </div>
        </label>
        {count && (
          <div
            className={clsx(styles.DiscountWrapper, {
              [styles['DiscountWrapper--recommended']]: recommended
            })}
          >
            <p id={styles.Label}>
              {discount ? `${count} Styles \u2192 ${discount} OFF` : `${count} Styles`}
            </p>
            {recommended && (
              <p id={styles.Label}>
                <Tag label="Recommended" />
              </p>
            )}
          </div>
        )}
      </div>
      {error && <InputErrorMessage error={error} />}
    </>
  )
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
