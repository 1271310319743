import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { PageInfo } from '@/saleor/api';

import { messages } from '../translations';
import Button from './Button';
import styles from './Pagination.module.scss';

export interface IPaginationProps {
  pageInfo?: PageInfo;
  onLoadMore: (cursor) => void;
  loadOnScroll?: boolean;
}

export const Pagination = ({ pageInfo, onLoadMore, loadOnScroll }: IPaginationProps) => {
  const t = useIntl();

  const paginationRef = useCallback(
    (node) => {
      if (node) {
        const observerOptions = {
          root: null,
          rootMargin: '20px',
          threshold: 1
        };

        const handleIntersection = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              onLoadMore(pageInfo?.endCursor);
              observer.unobserve(entry.target);
            }
          });
        };

        const paginationObserver = new IntersectionObserver(handleIntersection, observerOptions);
        paginationObserver.observe(node);
      }
    },
    [pageInfo?.endCursor, onLoadMore]
  );

  if (!pageInfo || !pageInfo?.hasNextPage) {
    return <></>;
  }

  const { hasNextPage = false } = pageInfo;

  if (!hasNextPage) {
    return null;
  }

  return loadOnScroll ? (
    <div ref={paginationRef} />
  ) : (
    <nav className={styles.Pagination}>
      <Button
        type="button"
        callBack={() => {
          onLoadMore(pageInfo?.endCursor);
        }}
      >
        {t.formatMessage(messages.loadMoreButton)}
      </Button>
    </nav>
  );
};
