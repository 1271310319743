import { useCheckout } from '@/components/providers/CheckoutProvider';
import checkoutDataLayer from '@/lib/datalayer/checkout';

import Button from '../../Button';
import Icon from '../../Icon';
import { Toast } from '.././Toast';
import type { SuccessToastProps } from '../types';
import styles from './SuccessToast.module.scss';

export const SuccessToast: React.FC<SuccessToastProps> = ({ successToastAction }) => {
  const { checkout } = useCheckout();

  return (
    <Toast
      className={styles.SuccessToast__wrapper}
      message={
        <div>
          <div className={styles.SuccessToast__header}>
            <Icon iconName="cart" color="var(--header-icon-color)" width="30" height="30" />
          </div>

          <div className={styles.SuccessToast__body}>
            <h3 className={styles.SuccessToast__title}>Success!</h3>
            <p>
              The items you selected have now been added to your cart. You aren’t too far away from
              beginning to utilise the fonts! Please choose to continue shopping with us or checkout
              now.
            </p>
          </div>
        </div>
      }
      actions={
        <>
          <Button
            // add 'view_cart' to the dataLayer
            callBack={() => {
              successToastAction(true);
              checkoutDataLayer.viewCart(checkout?.lines || []);
            }}
            title="Checkout"
            size="l"
          >
            Checkout
          </Button>
          <Button
            variant="secondary"
            callBack={() => successToastAction()}
            title="Open Cart"
            size="l"
          >
            Continue Shopping
          </Button>
        </>
      }
      // size="medium"
      position="center"
      onClose={successToastAction}
      image="success.svg"
      asModal
      type="twoColumn"
      autoHeight
    />
  );
};

export default SuccessToast;
