import InputField from '@/components/global/InputField';
import { inputFieldValidation } from '@/lib/util';

import styles from './ModalStyles.module.scss';

const LicenceHolder = ({ content, register, errors }) => {
  return (
    <div className={styles.licenceHolder} data-cy="licenceHolders">
      <div className={styles.stepContent}>{content}</div>
      <div className={styles.licenceHolderField}>
        <InputField
          hasLabel
          type="text"
          name="licenceHolderName"
          {...register('licenceHolderName', { required: 'This is required' })}
          label="License Holder Name ⁕"
          error={!!errors.licenceHolderName && errors.licenceHolderName.message}
        />
      </div>
      <div className={styles.licenceHolderField}>
        <InputField
          hasLabel
          type="email"
          name="licenceHolderEmail"
          {...register('licenceHolderEmail', {
            required: 'This is required',
            ...inputFieldValidation.emailFormat
          })}
          label="License Holder Email ⁕"
          error={!!errors.licenceHolderEmail && errors.licenceHolderEmail.message}
        />
      </div>
      {/* <div className={styles.licenceHolderField}>
        <InputField
          hasLabel
          type="text"
          name="licenceHolderCompany"
          {...register('licenceHolderCompany', {
            required: false
          })}
          label="License Holder Company"
        />
      </div> */}
    </div>
  );
};

export default LicenceHolder;
