import { useUserQuery } from '@/saleor/api';

export const useUser = () => {
  const { data, loading, refetch: fetchUserData, fetchMore } = useUserQuery();

  const user = data?.user;

  const authenticated = !!user?.id;

  return { user, loading: loading, authenticated, fetchUserData, fetchMore };
};
