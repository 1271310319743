import { XMLParser } from 'fast-xml-parser/src/fxp';

import { getCountryVatCode } from '@/lib/vat/helpers';

export const viesVatChecker = async ({ countryCode, vatNumber }) => {
  const regex = new RegExp(
    `^(ATU[0-9]{8}|BE0[0-9]{9}|BG[0-9]{9,10}|CY[0-9]{8}L|CZ[0-9]{8,10}|DE[0-9]{9}|DK[0-9]{8}|EE[0-9]{9}|(EL|GR)[0-9]{9}|ES[0-9A-Z][0-9]{7}[0-9A-Z]|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|GB([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|HU[0-9]{8}|HR[0-9]{11}|IE[0-9]S[0-9]{5}L|IT[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|LV[0-9]{11}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SE[0-9]{12}|SI[0-9]{8}|SK[0-9]{10})$`
  );

  const validate = async ({ countryCode, vatNumber }) => {
    const headers = new Headers();
    headers.append('Content-Type', 'text/xml');

    const soapRequest = `<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"
    xmlns:tns1="urn:ec.europa.eu:taxud:vies:services:checkVat:types"
    xmlns:impl="urn:ec.europa.eu:taxud:vies:services:checkVat">
    <soap:Header>
    </soap:Header>
    <soap:Body>
      <tns1:checkVat xmlns:tns1="urn:ec.europa.eu:taxud:vies:services:checkVat:types"
       xmlns="urn:ec.europa.eu:taxud:vies:services:checkVat:types">
       <tns1:countryCode>${getCountryVatCode(countryCode)}</tns1:countryCode>
       <tns1:vatNumber>${vatNumber}</tns1:vatNumber>
      </tns1:checkVat>
    </soap:Body>
  </soap:Envelope>`;

    return fetch('http://ec.europa.eu/taxation_customs/vies/services/checkVatService', {
      method: 'POST',
      headers,
      mode: 'cors',
      body: soapRequest
    })
      .then((response) => {
        return response.text();
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  };

  // Check format against regex to avoid unncessary VIES calls
  var result = regex.test(`${countryCode}${vatNumber}`);
  if (!result) {
    return {
      errors: [{ message: 'VAT Number is an invalid format' }],
      success: false
    };
  }

  const validateVATResult = await validate({ countryCode, vatNumber });

  // If promise returns false, return false
  if (!validateVATResult) {
    return {
      errors: [{ message: 'Your VAT number could not be checked. Please try again in a moment.' }],
      success: false
    };
  }

  const parser = new XMLParser();
  const JSONObject = parser.parse(`${validateVATResult}`);

  // Return false if there's a VIES error
  const fault = JSONObject['env:Envelope']['env:Body']['env:Fault'];
  if (fault) {
    return {
      errors: [{ message: 'Your VAT number could not be checked. Please try again in a moment.' }],
      success: false
    };
  }

  const valid = JSONObject['env:Envelope']['env:Body']['ns2:checkVatResponse']['ns2:valid'];

  return {
    errors: valid ? [] : [{ message: 'VAT Number is invalid' }],
    success: valid
  };
};

export const getVatValidity = ({
  countryCode,
  vatNumber,
  checkoutToken,
  checkoutId,
  email,
  channel,
  userId
}: any) => {
  let origin;
  if (typeof window !== 'undefined') {
    origin = window.location.origin;
  }

  return fetch(`${origin}/api/saleor-app/vat-check`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      vatCountry: countryCode,
      inputVatNumber: vatNumber,
      checkoutToken,
      checkoutId,
      email,
      channel,
      userId
    })
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const resetVatNumber = ({ checkoutToken, checkoutId, email, channel, userId }) => {
  let origin;
  if (typeof window !== 'undefined') {
    origin = window.location.origin;
  }

  return fetch(`${origin}/api/saleor-app/vat-reset`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ checkoutToken, checkoutId, email, channel, userId })
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const toggleVatRemoval = ({
  countryCode,
  vatNumber,
  checkoutToken,
  checkoutId,
  email,
  channel,
  userId
}) => {
  let origin;
  if (typeof window !== 'undefined') {
    origin = window.location.origin;
  }

  return fetch(`${origin}/api/saleor-app/vat-removal`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      vatCountry: countryCode,
      inputVatNumber: vatNumber,
      checkoutToken,
      checkoutId,
      email,
      channel,
      userId
    })
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log(error);
    });
};
