const beginCheckout = (lines) => {
  if (typeof window === 'undefined') return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      items: lines.map((line) => ({
        item_id: line?.id,
        item_name: line.metadata.length > 0 ? line.variant.sku : line.variant.product.name,
        item_type: line.metadata.length === 0 ? 'goods' : 'typefaces',
        price: `${line?.totalPrice.gross.amount}`,
        quantity: `${line.quantity}`
      }))
    }
  });
};

const addShippingInfo = (shipping_tier, currency = '£') => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'add_shipping_info',
    ecommerce: {
      shipping_tier: `${shipping_tier.name} - ${currency}${shipping_tier.price.amount}`
    }
  });
};

const viewItemList = (lines, item_type) => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: lines.map((line) => {
        const itemCategory = line.metadata.length === 0 ? 'goods' : 'typefaces';

        return {
          item_id: line?.id,
          item_name: line.metadata.length > 0 ? line.name : line?.variant?.product?.name,
          item_category: itemCategory,
          ...(itemCategory === 'typefaces' && {
            item_subcategory: line.category.name
          })
        };
      })
    }
  });
};

const selectItem = (item) => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'select_item',
    ecommerce: {
      item: {
        item_id: item?.id,
        item_name: item.metadata.length > 0 ? item.name : item.variant.product.name
      }
    }
  });
};

const viewItem = (item) => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      item: {
        item_id: item?.id,
        item_name: item.name
      }
    }
  });
};

const removeFromCart = (item) => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      item_id: item?.id,
      item_name: item.metadata.length > 0 ? item.variant.sku : item.variant.product.name,
      item_type: item.metadata.length === 0 ? 'goods' : 'typefaces',
      price: `${item?.totalPrice.gross.amount}`,
      quantity: `${item.quantity}`
    }
  });
};

const viewCart = (lines) => {
  if (typeof window === 'undefined') return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_cart',
    ecommerce: {
      items: lines.map((line) => ({
        item_id: line?.id,
        item_name: line.metadata.length > 0 ? line.variant.sku : line.variant.product.name,
        item_type: line.metadata.length === 0 ? 'goods' : 'typefaces',
        price: `${line?.totalPrice.gross.amount}`,
        quantity: `${line.quantity}`
      }))
    }
  });
};

const checkoutDataLayer = {
  beginCheckout: beginCheckout,
  addShippingInfo: addShippingInfo,
  viewItemList: viewItemList,
  selectItem: selectItem,
  viewItem: viewItem,
  removeFromCart: removeFromCart,
  viewCart: viewCart
};

export default checkoutDataLayer;
