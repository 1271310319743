import { isNotFamilyVariant } from '../productHelpers';

export const fontFamilyGroupDisplayFont = (group) => {
  const familySkus = group.metadata.find((meta) => meta.key === 'familySkus');

  if (familySkus) {
    const skusArray = familySkus.value.split(',');
    return skusArray.length > 0 ? skusArray[0] : null;
  }

  return false;
};

export const fontDisplayAttribute = (attributes) => {
  const attributeData = attributes?.find(
    (attribute) => attribute.attribute.slug === 'display-font'
  );

  return attributeData?.values?.length > 0 ? attributeData.values[0].name.toLowerCase() : null;
};

export const getFontVersion = (variants) => {
  const firstVariant = variants[0];
  const firstVariantSku = firstVariant?.sku;
  const skuIsPro = firstVariantSku.endsWith('pro');
  return skuIsPro ? 'pro' : 'std';
};

export const generateVariantS3Path = (productSlug: string, sku: string) => {
  if (!productSlug || !sku) return;

  const fontVersion = sku.endsWith('pro') ? 'pro' : 'std';
  const normalizedSku = sku?.replace(/-std$/, '');

  const result = normalizedSku
    ? `/${productSlug.replaceAll(/\s+/g, '-').toLowerCase()}/${fontVersion}/${normalizedSku}.woff2`
    : '';

  return result;
};

export const generateFamilyVariantS3Path = (productSlug, fontFamilyVariant) => {
  const familyDisplayFont = fontFamilyGroupDisplayFont(fontFamilyVariant);
  return generateVariantS3Path(productSlug, familyDisplayFont);
};

export const generateS3PathFromVariants = (productSlug, variants, attributes) => {
  if (!variants || variants.length === 0) {
    return '';
  }

  const nonFamilyGroupVariants = variants.filter((variant) => isNotFamilyVariant(variant));

  if (!nonFamilyGroupVariants || nonFamilyGroupVariants.length === 0) {
    return '';
  }

  // By default the sku we use is the first variant
  let { sku = '' } = nonFamilyGroupVariants[0];

  // If the display font has been set, instead we use that
  // Allows control over the font used
  if (fontDisplayAttribute(attributes)) {
    sku = fontDisplayAttribute(attributes);
  }

  return generateVariantS3Path(productSlug, sku);
};
