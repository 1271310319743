import React, { useState } from 'react';

import Button from '@/components/global/Button';

import Icon from '../Icon';
import styles from './InfoModal.module.scss';
import Modal from './Modal';

const InfoModal = ({ title, children }) => {
  const [open, setOpen] = useState(false);

  const modalChildren = React.Children.map(children, (child) => {
    return React.cloneElement(child, { className: styles.infoBlock });
  });

  return (
    <>
      <Button
        className={styles.InfoModal__button}
        type="button"
        variant="ghost"
        callBack={() => setOpen(true)}
        ariaLabel="Open info modal"
      >
        <span className={styles.InfoModal__buttonHitArea}>
          <Icon iconName="info" />
        </span>
      </Button>
      <Modal controlled controlledOpen={open} controlledSetOpen={setOpen} maxWidth="100%">
        <div className={styles.InfoModal__content}>
          <h5 className={styles.InfoModal__contentTitle}>{title}</h5>
          {modalChildren}
        </div>
      </Modal>
    </>
  );
};

export default InfoModal;
