import clsx from 'clsx';

import type { MessageBodyProps } from '../types';
import styles from './ModalContent.module.scss';

export const ModalMessageBody: React.FC<MessageBodyProps> = ({
  children,
  className,
  borderBottom
}) => {
  return (
    <div
      className={clsx(styles.ModalContent__MessageBody, className, {
        [styles['ModalContent__MessageBody--borderBottom']]: borderBottom
      })}
    >
      {children}
    </div>
  );
};

export default ModalMessageBody;
