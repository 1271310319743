import Icon from '../../Icon';
import { Toast } from '../Toast';
import type { SuccessToastProps } from '../types';
import styles from './SuccessToast.module.scss';

export const GetInTouchToast: React.FC<SuccessToastProps> = ({ successToastAction }) => {
  debugger;
  return (
    <Toast
      message={
        <div>
          <div className={styles.SuccessToast__header}>
            <Icon iconName="globe" color="var(--header-icon-color)" />
          </div>

          <div className={styles.SuccessToast__body}>
            <h3 className={styles.SuccessToast__title}>Get in Touch!</h3>
            <p>
              Email
              <br />
              <a href="mailto:abc@colophon-foundry.org">
                <span>abc@colophon-foundry.org</span>
              </a>
              <br />
              <br />
              Address
              <br />
              141-143 Shoreditch High Street,
              <br />
              3rd Floor London,
              <br />
              England, E1 6JE
            </p>
          </div>
        </div>
      }
      position="center"
      onClose={successToastAction}
      image="get-in-touch.svg"
      asModal
      type="twoColumn"
    />
  );
};

export default GetInTouchToast;
