import { AddressDetailsFragment } from '@/saleor/api';

import SelectField from '../global/SelectField';
import styles from './SavedAddressSelectionList.module.scss';

interface SavedAddressSelectionListProps {
  billing?: Boolean;
  savedAddresses: Array<any>;
  setSavedAddress?: (address: any) => void;
  selectedAddress?: AddressDetailsFragment;
}

export const SavedAddressSelectionList = ({
  savedAddresses,
  setSavedAddress,
  selectedAddress
}: SavedAddressSelectionListProps) => {
  if (savedAddresses.length === 0) return null;

  const onSelectSavedAddress = (addressId: String) => {
    if (!addressId) {
      return;
    }

    const address = savedAddresses.find((thisAddress) => thisAddress.id === addressId);
    setSavedAddress({
      firstName: address.firstName,
      lastName: address.lastName,
      companyName: address.companyName,
      streetAddress1: address.streetAddress1,
      streetAddress2: address.streetAddress2,
      city: address.city,
      cityArea: address.cityArea,
      postalCode: address.postalCode,
      country: address.country.code,
      countryArea: address.countryArea,
      phone: address.phone
    });
  };

  const addressIsSelected = (address) => {
    if (!selectedAddress) {
      return false;
    }

    const firstNameMatch = selectedAddress.firstName === address.firstName;
    const lastNameMatch = selectedAddress.lastName === address.lastName;
    const streetMatch = selectedAddress.streetAddress1 === address.streetAddress1;
    const postCodeMatch = selectedAddress.postalCode === address.postalCode;
    const countryMatch = selectedAddress.country.code === address.country.code;
    const cityMatch = selectedAddress.city === address.city;

    return (
      firstNameMatch && lastNameMatch && streetMatch && postCodeMatch && countryMatch && cityMatch
    );
  };

  const activeAddress = savedAddresses.find((savedAddress) => addressIsSelected(savedAddress));

  return (
    <div className={styles.savedAddress}>
      <p>Choose a saved address:</p>
      <SelectField
        name="savedAddress"
        label="savedAddress"
        onChange={(e) => onSelectSavedAddress(e.target.value)}
        defaultValue={activeAddress && activeAddress.id}
        options={[
          ...savedAddresses.map((address) => {
            return {
              value: address.id,
              text: `${address?.firstName} ${address?.lastName}, ${address?.postalCode} ${address?.city}, ${address?.country.country}`
            };
          })
        ]}
      />
    </div>
  );
};

export default SavedAddressSelectionList;
