import clsx from 'clsx';

import Icon from '../../Icon';
import styles from '../Toast.module.scss';
import type { DefaultToastContentProps } from '../types';
import { CloseButton } from './CloseButton';

export const BasicToastContent: React.FC<DefaultToastContentProps> = ({
  size,
  position,
  isError,
  toastMessage,
  actions,
  handleClose,
  type,
  title,
  asModal
}) => {
  return (
    <>
      <div
        className={clsx(styles.Toast__inner, styles['Toast__inner--basic'], {
          [styles['Toast__inner--medium']]: size === 'medium',
          [styles['Toast__inner--top']]: position === 'top'
        })}
      >
        <div className={styles.Toast__header}>
          <h3>{title}</h3>
        </div>
        <div
          className={clsx(styles.Toast__message, styles['Toast__message--basic'], {
            [styles['Toast__message--medium']]: size === 'medium'
          })}
        >
          {isError && <Icon iconName="close-circle" color="var(--error)" />}
          {toastMessage}
          {actions && (
            <div className={clsx(styles.Toast__actions, styles['Toast__actions--basic'])}>
              {actions}
            </div>
          )}
        </div>
        {!isError && !asModal && <CloseButton type={type} handleClose={handleClose} />}
      </div>
    </>
  );
};
