import React from 'react';
import {
  FieldErrorsImpl,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError
} from 'react-hook-form';

import { useCheckoutAddPromoCodeMutation, useCheckoutRemovePromoCodeMutation } from '@/saleor/api';

import Button from '../global/Button';
import InputField from '../global/InputField';
import Panel from '../global/Panel';
import PanelContent from '../global/Panel/PanelContent';
import PanelHeader from '../global/Panel/PanelHeader';
import { useRegions } from '../providers/RegionsProvider';
import styles from './CartDiscount.module.scss';
import { IPromoCodeFormData } from './CartSummary';

export interface CartDiscountProps {
  panelVariant?: 'md' | 'lg' | 'zero';
  promoCode: string;
  checkoutToken: string;
  handleSubmitForm: UseFormHandleSubmit<IPromoCodeFormData>;
  registerForm: UseFormRegister<IPromoCodeFormData>;
  setErrorForm: UseFormSetError<IPromoCodeFormData>;
  formErrors: FieldErrorsImpl<{ promoCode: string }>;
}

export const CartDiscount: React.FC<CartDiscountProps> = ({
  panelVariant,
  promoCode,
  checkoutToken,
  handleSubmitForm,
  registerForm,
  setErrorForm,
  formErrors
}) => {
  const { query } = useRegions();
  const [checkoutAddPromoCodeMutation] = useCheckoutAddPromoCodeMutation();
  const [checkoutRemovePromoCodeMutation] = useCheckoutRemovePromoCodeMutation();

  const onAddPromoCode = handleSubmitForm(async (formData: IPromoCodeFormData) => {
    const { data: promoMutationData } = await checkoutAddPromoCodeMutation({
      variables: {
        promoCode: formData.promoCode,
        token: checkoutToken,
        locale: query.locale
      }
    });
    const errors = promoMutationData?.checkoutAddPromoCode?.errors;
    if (!!errors && errors.length > 0) {
      setErrorForm('promoCode', { message: errors[0].message || 'Error' });
    }
  });

  const onRemovePromoCode = handleSubmitForm(async (formData: IPromoCodeFormData) => {
    const { data: promoMutationData } = await checkoutRemovePromoCodeMutation({
      variables: {
        promoCode: formData.promoCode,
        token: checkoutToken,
        locale: query.locale
      }
    });
    const errors = promoMutationData?.checkoutRemovePromoCode?.errors;
    if (!!errors && errors.length > 0) {
      setErrorForm('promoCode', { message: errors[0].message || 'Error' });
    }
  });

  return (
    <div className={styles.CartDiscount}>
      <Panel>
        <PanelHeader variant="xs">
          <h3 className="u-h6">Discount Code / Gift Card</h3>
        </PanelHeader>
        <PanelContent variant={panelVariant}>
          <section>
            <form onSubmit={onAddPromoCode}>
              <p className={styles.FormDescription}>
                {promoCode
                  ? 'Your discount code has been applied!'
                  : 'If you have a discount code, please apply the code here.'}
              </p>
              <div className={styles.DiscountField}>
                <InputField
                  type="text"
                  name="discount-code"
                  label="Discount Code"
                  hasBorder
                  className={styles.Input}
                  placeholder="ILOVEFONTS"
                  isDisabled={!!promoCode}
                  {...registerForm('promoCode', {
                    required: true
                  })}
                />
                <Button
                  className={styles.button}
                  size="l"
                  type={promoCode ? 'button' : 'submit'}
                  {...(promoCode && {
                    callBack: onRemovePromoCode
                  })}
                >
                  {promoCode ? 'Remove' : 'Apply'} Discount
                </Button>
              </div>
              {!!formErrors.promoCode && <p>{formErrors.promoCode?.message}</p>}
            </form>
          </section>
        </PanelContent>
      </Panel>
    </div>
  );
};
