import clsx from 'clsx';

import styles from '../Toast.module.scss';
import type { MessageBodyProps } from '../types';

export const ToastMessageBodyBlock: React.FC<MessageBodyProps> = ({
  children,
  className,
  borderBottom,
  title
}) => {
  return (
    <article
      className={clsx(styles.Toast__MessageBodyBlok, className, {
        [styles['Toast__MessageBody--borderBottom']]: borderBottom
      })}
    >
      <div className={styles.Toast__MessageBodyBlokTitle}>{title}</div>
      <div className={styles.Toast__MessageBodyBlokBody}>{children}</div>
    </article>
  );
};

export default ToastMessageBodyBlock;
