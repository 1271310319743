import Head from 'next/head';
import React from 'react';

interface Props {
  fontName: string;
  uuid: string;
}

const CustomFont: React.FC<Props> = ({ children, fontName, uuid }) => {
  function createStyles() {
    return {
      __html: `
      .custom-font-${fontName.replace(/\s+/g, '')} {
        --custom-font: '${fontName.replace(/\s+/g, '')}';
      }
    `
    };
  }

  return (
    <>
      <Head>
        <style dangerouslySetInnerHTML={createStyles()} />
      </Head>
      <div className={`custom-font-${fontName.replace(/\s+/g, '')}`}>{children}</div>
    </>
  );
};

export default CustomFont;
