import Icon from '../../Icon';
import { ToastMessageBodyBlock, TwoColumnToastContent } from '../../Toast';
import type { ModalTemplateProps } from '../types';
import styles from './GetInTouchModalTemplate.module.scss';

export const GetInTouchModalTemplate: React.FC<ModalTemplateProps> = ({ closeModal, children }) => {
  return (
    <TwoColumnToastContent
      position="center"
      handleClose={closeModal}
      title="Your Licensing"
      image="get-in-touch.svg"
      type="twoColumn"
      toastMessage={
        <div>
          <div className={styles.ModalTemplate__header}>
            <Icon iconName="globe" color="var(--header-icon-color)" />
          </div>

          <div className={styles.ModalTemplate__body}>
            <ToastMessageBodyBlock
              className={styles.ModalTemplate__bodyBlock}
              title={<h4>Get in Touch!</h4>}
            >
              {children}
            </ToastMessageBodyBlock>
          </div>
        </div>
      }
    />
  );
};

export default GetInTouchModalTemplate;
