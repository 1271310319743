import type { OptionalQuery as OptionalQuery0 } from '../pages/account/forgot-password';
import type { OptionalQuery as OptionalQuery1 } from '../pages/account/login';
import type { OptionalQuery as OptionalQuery2 } from '../pages/account/new-password';
import type { OptionalQuery as OptionalQuery4 } from '../pages/sharecart/[cartId]';
import type { OptionalQuery as OptionalQuery5 } from '../pages/tools/cf-creator';
import type { OptionalQuery as OptionalQuery7 } from '../pages/typefaces/[slug]';
import type { OptionalQuery as OptionalQuery6 } from '../pages/typefaces/[slug]/glyphs/[[...fontStyle]]';

// import type { OptionalQuery as OptionalQuery3 } from '../pages/goods/[slug]';
export type OptionalQuery3 = {
  variant?: string;
};

export const pagesPath = {
  $404: {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  _slug: (slug: string | number) => ({
    $url: (url?: { hash?: string }) => ({
      pathname: '/[slug]' as const,
      query: { slug },
      hash: url?.hash
    })
  }),
  account: {
    confirm: {
      $url: (url?: { hash?: string }) => ({
        pathname: '/account/confirm' as const,
        hash: url?.hash
      })
    },
    confirm_account_delete: {
      $url: (url?: { hash?: string }) => ({
        pathname: '/account/confirm-account-delete' as const,
        hash: url?.hash
      })
    },
    confirm_email_change: {
      $url: (url?: { hash?: string }) => ({
        pathname: '/account/confirm-email-change' as const,
        hash: url?.hash
      })
    },
    forgot_password: {
      $url: (url?: { query?: OptionalQuery0; hash?: string }) => ({
        pathname: '/account/forgot-password' as const,
        query: url?.query,
        hash: url?.hash
      })
    },
    login: {
      $url: (url?: { query?: OptionalQuery1; hash?: string }) => ({
        pathname: '/account/login' as const,
        query: url?.query,
        hash: url?.hash
      })
    },
    new_password: {
      $url: (url?: { query?: OptionalQuery2; hash?: string }) => ({
        pathname: '/account/new-password' as const,
        query: url?.query,
        hash: url?.hash
      })
    },
    orders: {
      _token: (token: string | number) => ({
        $url: (url?: { hash?: string }) => ({
          pathname: '/account/orders/[token]' as const,
          query: { token },
          hash: url?.hash
        })
      })
    },
    register: {
      $url: (url?: { hash?: string }) => ({
        pathname: '/account/register' as const,
        hash: url?.hash
      })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/account' as const, hash: url?.hash })
  },
  articles_guides: {
    _slug: (slug: string | number) => ({
      $url: (url?: { hash?: string }) => ({
        pathname: '/articles-guides/[slug]' as const,
        query: { slug },
        hash: url?.hash
      })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/articles-guides' as const, hash: url?.hash })
  },
  cart: {
    $url: (url?: { hash?: string }) => ({ pathname: '/cart' as const, hash: url?.hash })
  },
  checkout: {
    $url: (url?: { hash?: string }) => ({ pathname: '/checkout' as const, hash: url?.hash })
  },
  custom_projects: {
    _slug: (slug: string | number) => ({
      $url: (url?: { hash?: string }) => ({
        pathname: '/custom-projects/[slug]' as const,
        query: { slug },
        hash: url?.hash
      })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/custom-projects' as const, hash: url?.hash })
  },
  goods: {
    _slug: (slug: string | number) => ({
      $url: (url?: { query?: OptionalQuery3; hash?: string }) => ({
        pathname: '/goods/[slug]' as const,
        query: { slug, ...url?.query },
        hash: url?.hash
      })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/goods' as const, hash: url?.hash })
  },
  in_use: {
    $url: (url?: { hash?: string }) => ({ pathname: '/in-use' as const, hash: url?.hash })
  },
  in_use_folder: {
    $url: (url?: { hash?: string }) => ({ pathname: '/in-use-folder' as const, hash: url?.hash })
  },
  sharecart: {
    _cartId: (cartId: string | number) => ({
      $url: (url?: { query?: OptionalQuery4; hash?: string }) => ({
        pathname: '/sharecart/[cartId]' as const,
        query: { cartId, ...url?.query },
        hash: url?.hash
      })
    })
  },
  test: {
    icons: {
      $url: (url?: { hash?: string }) => ({ pathname: '/test/icons' as const, hash: url?.hash })
    }
  },
  tools: {
    _slug: (slug: string | number) => ({
      $url: (url?: { hash?: string }) => ({
        pathname: '/tools/[slug]' as const,
        query: { slug },
        hash: url?.hash
      })
    }),
    cf_creator: {
      $url: (url?: { query?: OptionalQuery5; hash?: string }) => ({
        pathname: '/tools/cf-creator' as const,
        query: url?.query,
        hash: url?.hash
      })
    },
    colophon_standard: {
      $url: (url?: { hash?: string }) => ({
        pathname: '/tools/colophon-standard' as const,
        hash: url?.hash
      })
    },
    proofer: {
      $url: (url?: { hash?: string }) => ({ pathname: '/tools/proofer' as const, hash: url?.hash })
    }
  },
  typefaces: {
    _slug: (slug: string | number) => ({
      $url: (url?: { query?: OptionalQuery7; hash?: string }) => ({
        pathname: '/typefaces/[slug]' as const,
        query: { slug, ...url?.query },
        hash: url?.hash
      }),
      glyphs: {
        _fontStyle: (fontStyle?: string[]) => ({
          $url: (url?: { query?: OptionalQuery6; hash?: string }) => ({
            pathname: '/typefaces/[slug]/glyphs/[[...fontStyle]]' as const,
            query: { slug, fontStyle, ...url?.query },
            hash: url?.hash
          })
        })
      },
      in_use: {
        $url: (url?: { hash?: string }) => ({
          pathname: '/typefaces/[slug]/in-use' as const,
          query: { slug },
          hash: url?.hash
        })
      },
      info: {
        $url: (url?: { hash?: string }) => ({
          pathname: '/typefaces/[slug]/info' as const,
          query: { slug },
          hash: url?.hash
        })
      },
      opentype: {
        _fontStyle: (fontStyle?: string[]) => ({
          $url: (url?: { hash?: string }) => ({
            pathname: '/typefaces/[slug]/opentype/[[...fontStyle]]' as const,
            query: { slug, fontStyle },
            hash: url?.hash
          })
        })
      },
      proofer: {
        _fontStyle: (fontStyle?: string[]) => ({
          $url: (url?: { hash?: string }) => ({
            pathname: '/typefaces/[slug]/proofer/[[...fontStyle]]' as const,
            query: { slug, fontStyle },
            hash: url?.hash
          })
        })
      },
      variable: {
        _fontStyle: (fontStyle?: string[]) => ({
          $url: (url?: { hash?: string }) => ({
            pathname: '/typefaces/[slug]/variable/[[...fontStyle]]' as const,
            query: { slug, fontStyle },
            hash: url?.hash
          })
        })
      }
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/typefaces' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;
