// Env vars that can be overridden
export const SITE_URL = process.env.SITE_URL || 'https://www.colophon-foundry.org/';
export const SITE_NAME = process.env.SITE_NAME || 'Colophon Foundry';
export const TRAILING_SLASH = true;

export const CHECKOUT_TOKEN = 'checkoutToken';
export const API_URI = process.env.NEXT_PUBLIC_API_URI || '';

export const STORYBLOK_IMAGE_QUALITY = 75;

export const STORYBOOK_PREVIEW_KEY = process.env.NEXT_PUBLIC_STORYBLOK_PREVIEW_TOKEN;
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT || 'production';
export const VERSION = ENVIRONMENT === 'development' ? 'draft' : 'published';

// Constants that aren't expected to differ between environments
export const SOCIAL_FACEBOOK_ID = 'null';
export const SOCIAL_INSTAGRAM_ID = 'https://www.instagram.com/colophonfoundry/';
export const SOCIAL_TWITTER_ID = 'https://twitter.com/colophonfoundry';
export const SOCIAL_PINTEREST_ID = 'https://www.pinterest.co.uk/colophonfoundry';
export const SOCIAL_LINKEDIN_ID = null;
export const SOCIAL_YOUTUBE_ID = null;

export const IMAGE_SIZES = [];

// Ensure these match: styles/_sass-variables.scss
export const SCREEN_SIZE_MOBILE_MAX = '767px';
export const SCREEN_SIZE_TABLET_MIN = '768px';
export const SCREEN_SIZE_TABLET_MAX = '1023px';
export const SCREEN_SIZE_DESKTOP_MIN = '1024px';
export const SCREEN_SIZE_DESKTOP_MAX = '1239px';
export const SCREEN_SIZE_LARGEDESKTOP_MIN = '1240px';
export const SCREEN_SIZE_LARGEDESKTOP_MAX = '1599px';
export const SCREEN_SIZE_SUPERLARGEDESKTOP_MIN = '1600px';

// Add Google Analytics 4 and/or GTM scripts by configuring the below
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID || null;
export const GA_4_TRACKING_ID = process.env.NEXT_PUBLIC_GA_4_TRACKING_ID || null;
export const LOGROCKET_ID = process.env.NEXT_PUBLIC_LOGROCKET_ID || null;
export const COOKIE_BOT_ID = process.env.NEXT_PUBLIC_COOKIE_BOT_ID;

// Saleor item constants
export const PRODUCTS_GOODS_CATEGORY = 'Q2F0ZWdvcnk6NjE=';
export const DEFAULT_TYPEFACE_PRODUCTS_CATEGORY =
  process.env.NEXT_PUBLIC_TYPEFACE_PRODUCTS_CATEGORY;

export const CUSTOMISER_PRODUCT_VARIANT_ID_DEFAULT =
  process.env.NEXT_PUBLIC_CUSTOMISER_PRODUCT_VARIANT_ID_DEFAULT;

export const TRANSACTIONAL_TEST_MODE = process.env.NEXT_PUBLIC_TRANSACTIONAL_TEST_MODE || false;
