import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import Icon from '../../Icon';
import type { DefaultToastContentProps } from '../types';
import { CloseButton } from './CloseButton';
import styles from './ModalContent.module.scss';

export const TwoColumnModalContent: React.FC<DefaultToastContentProps> = ({
  size,
  position,
  icon,
  image,
  isError,
  toastMessage,
  actions,
  handleClose,
  type,
  autoHeight
}) => {
  const ToastIcons = dynamic(() => import('./ModalIcons'));

  return (
    <div
      className={clsx(styles.ModalContent__inner, {
        [styles['ModalContent__inner--medium']]: size === 'medium',
        [styles['ModalContent__inner--top']]: position === 'top',
        [styles['ModalContent__inner--twoColumn']]: type === 'twoColumn',
        [styles['ModalContent__inner--autoHeight']]: autoHeight
      })}
    >
      {icon ? (
        <div className={styles.ModalContent__Icon}>
          <ToastIcons iconName={icon} />
        </div>
      ) : null}
      {image ? (
        <div
          className={clsx(styles.ModalContent__Icon, {
            [styles['ModalContent__Icon--twoColumn']]: type === 'twoColumn'
          })}
        >
          <Image src={`/img/${image}`} alt="" width="537" height="399" loading="eager" priority />
        </div>
      ) : null}
      <div
        className={clsx(styles.Toast__message, {
          [styles['Toast__message--medium']]: size === 'medium',
          [styles['Toast__message--twoColumn']]: type === 'twoColumn'
        })}
      >
        {isError && <Icon iconName="close-circle" color="var(--error)" />}
        {toastMessage}
        {actions && (
          <div className={clsx(styles.Toast__actions, styles['Toast__actions--twoColumn'])}>
            {actions}
          </div>
        )}
      </div>
      {!isError && <CloseButton handleClose={handleClose} type="twoColumn" />}
    </div>
  );
};
