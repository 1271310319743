import { useState } from 'react';

import InputField from '@/components/global/InputField';
import SelectField from '@/components/global/SelectField';
import { useTypefaceAddToCart } from '@/components/providers/TypefaceProvider';
import { getMaxUsageApplies } from '@/lib/util';

import styles from '../ModalStyles.module.scss';

export const LicenceVariantInput = ({
  register,
  capitalizeFirstLetter,
  licenceVariant,
  licence,
  errors
}) => {
  const [maxUsageError, setMaxUsageError] = useState<boolean>(false);
  const { state, setMaxUsageAmountError, updateMultiplier } = useTypefaceAddToCart();

  const { maxUsageValues, multipliers } = state;

  const valueOnChange = (optionItems, value) => {
    setMaxUsageAmountError(false);
    setMaxUsageError(false);

    const optionItem = optionItems.find((item) => item.value === value);

    if (!optionItem) {
      return false;
    }

    const maxUsageApplies = getMaxUsageApplies(maxUsageValues, optionItem, multipliers);

    if (maxUsageApplies) {
      setMaxUsageAmountError(true);
    }

    if (maxUsageValues.includes(optionItem.text)) setMaxUsageError(true);

    return updateMultiplier(multipliers, value);
  };

  const { options } = licenceVariant;
  const optionItems = options.map((option) => {
    return {
      value: `{"licenceName": "${licence.name}","licenceVariant": "${licenceVariant.name}","licenceMultiplier": ${option.multiply}, "licenceMultiplierName": "${option.name}"}`,
      text: option.name,
      default: option.default
    };
  });

  const initial = optionItems.find((item) => item.default === true);

  return (
    <div key={licenceVariant.slug} className={styles.selectedLicenceItem}>
      <SelectField
        name={licence.name}
        key={licenceVariant.slug}
        hasLabel
        label={licenceVariant.text}
        options={optionItems}
        defaultLabel="Choose..."
        defaultValue={initial?.value || ''}
        {...register(`licence${capitalizeFirstLetter(licenceVariant.name)}`, {
          required: true,
          onChange: (e) => {
            valueOnChange(optionItems, e.target.value);
          }
        })}
        error={maxUsageError}
      />
    </div>
  );
};

export default LicenceVariantInput;
