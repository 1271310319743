import clsx from 'clsx';
import React from 'react';

import { getIgnoredAxes } from '@/lib/customiser/getIgnoredProperties';

import styles from './CustomisedFontMetadata.module.scss';

const CustomisedFontMetadata = ({ metadata, variant, className = '' }) => {
  const customisedFontMetadata = metadata.find(({ key }) => key === 'itemCustomiser')?.value;

  if (!customisedFontMetadata) {
    return;
  }

  let parsedMetadata = [];

  try {
    parsedMetadata = customisedFontMetadata && JSON.parse(customisedFontMetadata);
  } catch (error) {
    console.error("Error: Can't parse custom font metadata");
  }

  const fontFamily = parsedMetadata?.[0]?.fontFamily || '';
  const fontStyles = parsedMetadata.reduce((acc, item) => {
    return [...acc, item.fontPathSrc, ...item.fontsAdditional];
  }, []);
  const stylisticSets = parsedMetadata?.[0]?.stylisticSets || [];
  const variableAxes = parsedMetadata?.[0]?.variableAxes || {};
  const base: string = parsedMetadata?.[0]?.fontBase || '';
  const ignoredAxes = getIgnoredAxes(variant);

  return (
    <ul className={clsx(styles.CustomisedFontMetadata__list, className)}>
      {fontFamily && (
        <li className={styles.CustomisedFontMetadata__item}>
          <span className={styles.CustomisedFontMetadata__propertyName}>Family</span>
          <span className={styles.CustomisedFontMetadata__baseValue}>{fontFamily}</span>
        </li>
      )}
      {base && (
        <li className={styles.CustomisedFontMetadata__item}>
          <span className={styles.CustomisedFontMetadata__propertyName}>Base</span>
          <span className={styles.CustomisedFontMetadata__baseValue}>{base}</span>
        </li>
      )}
      {fontStyles && fontStyles.length >= 1 && (
        <li className={styles.CustomisedFontMetadata__item}>
          <span className={styles.CustomisedFontMetadata__propertyName}>Styles</span>
          <span className={styles.CustomisedFontMetadata__baseValue}>{fontStyles.length}</span>
        </li>
      )}
      {!!Object.keys(variableAxes).length &&
        Object.entries(variableAxes).map(([fontAxesName, fontAxesValue]) => {
          // Ignore axis
          if (ignoredAxes.includes(fontAxesName.toLowerCase())) {
            return;
          }

          const value = String(fontAxesValue);
          const splitValue = value.split(':');

          return (
            <li className={styles.CustomisedFontMetadata__item} key={fontAxesName}>
              <span className={styles.CustomisedFontMetadata__propertyName}>{fontAxesName}</span>
              {splitValue.length > 1 ? `${splitValue[0]} to ${splitValue[1]}` : fontAxesValue}
            </li>
          );
        })}
      {!!stylisticSets.length && (
        <li className={styles.CustomisedFontMetadata__item}>
          <span className={styles.CustomisedFontMetadata__propertyName}>Alternatives</span>
          {`${stylisticSets.length} set${stylisticSets.length > 1 ? 's' : ''}`}
        </li>
      )}
    </ul>
  );
};

export default CustomisedFontMetadata;
