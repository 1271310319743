import clsx from 'clsx';
import { ChangeEventHandler, forwardRef, ReactNode } from 'react';

import InputErrorMessage from '@/components/global/InputErrorMessage';
import InputLabel from '@/components/global/InputLabel';

import styles from './SelectField.module.scss';

interface SelectFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  onFocus?: ChangeEventHandler<HTMLSelectElement>;
  onBlur?: ChangeEventHandler<HTMLSelectElement>;
  hasLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  hasBorder?: boolean;
  error?: string | ReactNode;
  options?: Array<any>;
  defaultLabel?: string;
  defaultValue?: string;
}

const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      name,
      label,
      placeholder,
      onChange,
      onFocus,
      onBlur,
      hasLabel,
      isRequired,
      isDisabled,
      hasBorder,
      options,
      defaultLabel,
      defaultValue,
      error
    },
    ref
  ) => (
    <>
      <InputLabel name={name} label={label} isVisible={hasLabel} error={error} />
      <div>
        <select
          className={clsx(styles.SelectField, {
            [styles.SelectFieldError]: error,
            [styles.SelectFieldBorder]: hasBorder && !error,
            [styles.SelectFieldIsDisabled]: isDisabled
          })}
          id={name}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue || (defaultLabel ? '' : null)}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={isRequired}
          disabled={isDisabled}
          ref={ref}
          data-cy={name}
        >
          <>
            {defaultLabel && (
              <option disabled value="">
                {defaultLabel}
              </option>
            )}
            {options.map((option, i) => (
              <option key={i} value={option.value} selected={option.selected}>
                {option.text}
              </option>
            ))}
          </>
        </select>
      </div>

      {error && <InputErrorMessage error={error} />}
    </>
  )
);

SelectField.displayName = 'SelectField';

export default SelectField;
