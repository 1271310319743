import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import { Column, Grid } from '@/components/global/Grid';
import { Pagination } from '@/components/global/Pagination';
import { useRegions } from '@/components/providers/RegionsProvider';
import { ProductFilterInput, useProductCollectionLazyQuery } from '@/saleor/api';

import { filterProducts } from '../../lib/productHelpers';
import ProductCard from '../product/ProductCard';
import { Spinner } from '../Spinner';
import { messages } from '../translations';
import styles from './ProductCollection.module.scss';

export interface IProductCollectionProps {
  filter?: ProductFilterInput;
  allowMore?: boolean;
  serverProductsData?: any;
}

const productsPerPage = 10;

export const ProductCollection = ({
  filter,
  serverProductsData,
  allowMore = true
}: IProductCollectionProps) => {
  const t = useIntl();
  const { query } = useRegions();
  const [category, setCategory] = useState('All Categories');
  const [language, setLanguage] = useState('All Languages');
  const [productsToShow, setProductsToShow] = useState(9);
  const [productData, setProductData] = useState<any>();
  const searchState = useState('');
  const [search] = searchState;

  const [getProducts, { loading }] = useProductCollectionLazyQuery({
    fetchPolicy: 'cache-and-network'
  });

  const onLoadMore = () => {
    getProducts({
      variables: {
        filter: filter,
        first: productsToShow + productsPerPage,
        ...query
      }
    }).then((data) => {
      setProductData(data?.data?.products);
      setProductsToShow(productsToShow + productsPerPage);
    });
  };

  const productRenderData = productData || serverProductsData;
  const products = productRenderData?.edges.map((edge) => edge.node) || [];

  if (products.length === 0) {
    return <p>{t.formatMessage(messages.noProducts)}</p>;
  }

  const filteredProducts = filterProducts(products, { search, category, language });

  const featuredProducts = filteredProducts.filter((product) => {
    const featuredAttribute =
      product.attributes.length > 0
        ? product.attributes.find((attribute) => attribute.attribute.slug === 'featured-goods')
        : null;

    if (!featuredAttribute) {
      return false;
    }

    const isFeatured = featuredAttribute.values.some(
      (value) => value.name === 'Featured Goods: Yes'
    );
    return isFeatured;
  });

  const firstProduct = featuredProducts.length > 0 ? featuredProducts[0] : null;
  const lastProduct = featuredProducts.length > 1 ? featuredProducts[1] : null;

  const productsArray = filteredProducts.filter((item) => {
    const firstProductId = firstProduct ? firstProduct.id : null;
    const lastProductId = lastProduct ? lastProduct.id : null;

    return item.id !== firstProductId && item.id !== lastProductId;
  });

  const isOdd = productsArray?.length % 2 !== 0;

  return (
    <div className={styles.ProductCollection}>
      {loading && <Spinner />}
      <Grid className={styles.ProductCollection__Grid}>
        <Column sm={12} md={12} lg={12}>
          {/* <ProductHeader
            products={products}
            initialCategory={category}
            initialLanguage={language}
            setCategory={(val) => setCategory(val)}
            setLanguage={(val) => setLanguage(val)}
            searchState={searchState}
            isComparing={viewComparedProducts}
          /> */}

          {firstProduct && (
            <div className={styles.ProductCollection__First}>
              <ProductCard key={firstProduct.id} product={firstProduct} letterbox />
            </div>
          )}

          <ul role="list" className={styles.ProductCollection__List}>
            {productsArray?.map((product, i) => (
              <li
                className={[
                  styles.ProductCollection__ListItem,
                  isOdd && i === productsArray?.length - 1
                    ? styles.ProductCollection__ListOddItem
                    : ''
                ].join(' ')}
                key={product.id}
              >
                <ProductCard key={product.id} product={product} />
              </li>
            ))}
          </ul>

          {lastProduct && (
            <div className={styles.ProductCollection__Last}>
              <ProductCard key={lastProduct.id} product={lastProduct} letterbox />
            </div>
          )}

          {productsArray.length === 0 && (
            <div className={styles.noresults}>
              <h4>No results</h4>
            </div>
          )}

          {allowMore && <Pagination onLoadMore={onLoadMore} pageInfo={products?.pageInfo} />}
        </Column>
      </Grid>
    </div>
  );
};

export default ProductCollection;
