import clsx from 'clsx';

import type { MessageBodyProps } from '../types';
import styles from './ModalContent.module.scss';

export const ModalMessageBodyBlock: React.FC<MessageBodyProps> = ({
  children,
  className,
  borderBottom,
  title
}) => {
  return (
    <article
      className={clsx(styles.MessageBodyBlok, className, {
        [styles['Toast__MessageBody--borderBottom']]: borderBottom
      })}
    >
      <div className={styles.MessageBodyBlok__Title}>{title}</div>
      <div className={styles.MessageBodyBlok__Body}>{children}</div>
    </article>
  );
};

export default ModalMessageBodyBlock;
