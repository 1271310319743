import { useRouter } from 'next/router';

import Button from '@/components/global/Button';
import { Table, TableRow } from '@/components/global/Table';
import { useRegions } from '@/components/providers/RegionsProvider';
import { isMigratedOrder } from '@/lib/checkout/migratedOrders';
import { useScreenSize } from '@/lib/hooks/mediaQuery';
import { usePaths } from '@/lib/paths';
import { OrderDetailsFragment } from '@/saleor/api';

import styles from './OrdersTable.module.scss';

interface OrdersTableProps {
  orders: OrderDetailsFragment[];
}

export const OrdersTable = ({ orders }: OrdersTableProps) => {
  const router = useRouter();
  const paths = usePaths();
  const { formatPrice } = useRegions();
  const screen = useScreenSize();

  const migratedOrder = (order) => isMigratedOrder(order);

  const InvoiceRow = ({ order }) => (
    <TableRow className={styles.OrdersTable}>
      {screen === 'Mobile' && (
        <div className={styles.OrdersTableMobile}>
          <h6>Order #{order?.number}</h6>
          <p>{order.created.slice(0, 10)}</p>
          {formatPrice(order.total.gross)}
        </div>
      )}
      <h6>
        Order #<span className={styles.digits}>{order?.number}</span>
      </h6>
      <p className={styles.digits}>{order.created.slice(0, 10)}</p>
      <Button
        variant="secondary"
        backgroundColor="transparent"
        callBack={() => router.push(paths.account.orders._token(order.token).$url())}
      >
        {migratedOrder(order) ? 'MIGRATED ORDER' : order.status}
      </Button>
      {screen !== 'Mobile' && (
        <span className={styles.digits}>{formatPrice(order.total.gross)}</span>
      )}
    </TableRow>
  );

  return (
    <div className={styles.OrdersTableContainer}>
      <Table
        cssVars={{
          '--set-table-row-padding': '24px'
        }}
      >
        {orders.map((order, i) => (
          <InvoiceRow key={i} order={order} />
        ))}
      </Table>
    </div>
  );
};

export default OrdersTable;
