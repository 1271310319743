/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from 'next/router';
import React, { useCallback, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import AddToCartModal from 'react-modal';
import { TypefaceLicences, TypefaceVariant } from 'types/common';

import Button from '@/components/global/Button';
import FontLoader from '@/components/global/FontLoader';
import { Column, Grid } from '@/components/global/Grid';
import Icon from '@/components/global/Icon';
import Toast from '@/components/global/Toast';
import { useCheckout } from '@/components/providers/CheckoutProvider';
import { useRegions } from '@/components/providers/RegionsProvider';
import { useTypefaceAddToCart } from '@/components/providers/TypefaceProvider';
import { Spinner } from '@/components/Spinner';
import { useSaleorAuthContext } from '@/lib/auth';
import pushAddToCartDataLayer from '@/lib/datalayer/addtocart';
import fontLicences from '@/lib/fonts/fontLicences';
import { fontIsPro } from '@/lib/fonts/fontVersion';
import { generateFamilyVariantS3Path, generateVariantS3Path } from '@/lib/fonts/s3FontPath';
import { useUser } from '@/lib/hooks';
import { useScreenSize } from '@/lib/hooks/mediaQuery';
import { getFontMetaS3Family, isFamilyVariant, isNotFamilyVariant } from '@/lib/productHelpers';
import { getMaxUsageApplies } from '@/lib/util';
import { ProductDetailsFragment } from '@/saleor/api';

import AddToCartInstruction from './AddToCartInstruction';
import {
  availableVariantsForSelection,
  capitalizeFirstLetter,
  fontFamilyGroups,
  fontsByGroup,
  getFontGroups,
  getFontS3Sku,
  variantExtractor
} from './helpers';
import LicenceHolder from './LicenceHolder';
import LicenceStep from './LicenceStep';
import LicenceVersions from './LicenceVersions';
import styles from './ModalStyles.module.scss';
import StepNavigation from './StepNavigation';
import Summary from './Summary';
import VariantGroupings from './VariantGroupings';

AddToCartModal.setAppElement('#root');

interface AddTypefaceToCartProps {
  product: ProductDetailsFragment;
  productLicences?: TypefaceLicences;
  setClosed: Function;
  isOpen?: boolean;
}

export const AddTypefaceToCart = ({
  product,
  productLicences,
  setClosed,
  isOpen
}: AddTypefaceToCartProps) => {
  const router = useRouter();
  const screen = useScreenSize();
  const { signOut } = useSaleorAuthContext();
  const isTouchDevice = screen === 'Mobile' || screen === 'Tablet';
  const { checkoutToken, checkout, setCheckoutToken, refetch, resetCheckoutToken } = useCheckout();

  const { user } = useUser();
  const { currentChannel, formatPrice } = useRegions();

  const { variants } = product;
  const licenceArray = Object.values(productLicences);

  const singleVersionFont = !variants.some((variant) => variant.sku.endsWith('-pro'));

  const {
    state,
    setStep,
    setSelectedLicences,
    setMultipliers,
    setMultiplierValue,
    setCartFontsList,
    setCartSubTotalAmount,
    setCartTotalAmount,
    setAddToCartResponse,
    setAddToCartError,
    setAddToCartResult,
    removeLicenceMultiplier,
    resetState,
    setIsOpen,
    setIsChecked,
    setIsAddedToCart,
    setIsLoading,
    setMaxUsageAmountError
  } = useTypefaceAddToCart();

  const {
    step,
    selectedLicences,
    multipliers,
    multiplierValue,
    cartFontsList,
    cartSubTotalAmount,
    cartTotalAmount,
    addtoCartError,
    isChecked,
    isAddedToCart,
    loading,
    maxUsageValues
  } = state;

  const steps = [
    {
      label: 'Font Styles',
      content: (productName) => {
        return `Which ${productName} styles do you need to purchase?`;
      },
      id: 'fontStyles'
    },
    { label: 'Font Version', content: () => 'Which version do you require?', id: 'fontVersion' },
    {
      label: 'License Platforms',
      content: () => 'Which platforms do you need a license for?',
      id: 'licencePlatform'
    },
    { label: 'License Holder', content: () => 'License holder details?', id: 'licenceHolder' }
  ];

  const requiredLicenceFields = licenceArray
    .map((licence) => [variantExtractor(licence).map((key) => key)])
    .flat(2) // flat the array then map it back for Object.fromEntries
    .map((value) => [value]);

  const obj = Object.fromEntries(requiredLicenceFields);

  const initialValues = { fonts: [], fontVersion: 'Pro', ...obj };

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: initialValues
  });

  const watchFonts = useWatch({ name: ['fonts'], control });
  const watchFontVersion = useWatch({ name: 'fontVersion', control });

  const resetForm = () => {
    setSelectedLicences([]);
    setStep(0);
    setMultipliers([]);
    setMultiplierValue(0);
    setIsChecked({});
    reset(initialValues);
  };

  /**
   * @deprecated
   */
  const haveVariantGroupings = product.metadata.some(
    (metadata) => metadata.key === 'variant-groups'
  );

  const variantDataGroups = getFontGroups(variants);
  const haveVariantDataGroups = variantDataGroups.length > 0;

  /**
   * @deprecated
   */
  const variantGroupings = haveVariantGroupings
    ? product.metadata.find((metadata) => metadata.key === 'variant-groups').value.split(',')
    : [];

  // Filter out pro - for display purposes as user chooses pro/std in step 4
  const variantsNotPro = variants.filter((variant) => !fontIsPro(variant));
  const variantsPro =
    variants.filter((variant) => fontIsPro(variant)).length > 0
      ? variants.filter((variant) => fontIsPro(variant))
      : variants.filter((variant) => !fontIsPro(variant));

  const variantsToFilter = watchFontVersion === 'Pro' ? variantsPro : variantsNotPro;
  const opposingFamiliesVariantsToFilter =
    watchFontVersion === 'Pro' ? variantsNotPro : variantsPro;
  const availableProFonts = availableVariantsForSelection(variantsPro, false);
  const variantsForSelection = availableVariantsForSelection(variantsToFilter, true);
  const variantsAndFamiliesForSelection = availableVariantsForSelection(variantsToFilter, false);
  const opposingFamiliesVariantsForSelection = availableVariantsForSelection(
    opposingFamiliesVariantsToFilter,
    false
  );

  const singleVersionFonts = cartFontsList.filter(
    (variant) =>
      !opposingFamiliesVariantsForSelection.some(
        (oppVariant) => oppVariant.sku.replace(/-pro$/, '') === variant.sku.replace(/-pro$/, '')
      )
  );

  const versionSelectionDisabled = singleVersionFonts.length === cartFontsList.length;

  // Remove the Licence Version step if either the font is single version entirely
  // OR the variants selected are single-version
  if (singleVersionFont || versionSelectionDisabled) {
    steps.splice(1, 1);
  }

  const stepIndex = (stepId) => steps.findIndex((step) => step.id === stepId);
  const stepActive = (stepId) => stepIndex(stepId) === step;

  useEffect(() => {
    let newValue;

    if (multipliers.length > 1) {
      newValue = multipliers.reduce((prevMultiplier, multiplier) => {
        return prevMultiplier + multiplier.licenceMultiplier;
      }, 0);
    } else if (multipliers.length === 1) {
      newValue = multipliers[0].licenceMultiplier;
    }

    if (newValue < 1) {
      newValue = 1;
    }

    setMultiplierValue(newValue);
  }, [multipliers]);

  useEffect(() => {
    const isPro = watchFontVersion === 'Pro';

    // Manipulate whether we're returning pro/std fonts
    const cartFontsArray = variants.filter((variant) =>
      watchFonts[0].some((font) => {
        const exists = variants.some((variant) => {
          return variant.sku === font;
        });

        if (!exists) {
          return font.replace(/-pro$/, '') === variant.sku.replace(/-pro$/, '');
        }

        return isPro ? font === variant.sku : font.replace(/-pro$/, '') === variant.sku;
      })
    );

    setCartFontsList(cartFontsArray);

    const totalAmount = cartFontsArray.reduce(
      (n, cartFontsArray) => n + cartFontsArray.pricing.price.net.amount,
      0
    );

    // pricing > price > net > amount
    setCartSubTotalAmount(totalAmount);
    setCartTotalAmount(totalAmount);
  }, [watchFontVersion, variants, watchFonts]);

  useEffect(() => {
    if (watchFontVersion !== 'Pro') {
      const watchFontsRevised = watchFonts[0].map((item) => item.replace(/-pro$/, ''));
      setValue('fonts', watchFontsRevised);
    } else {
      const watchFontsRevised = watchFonts[0].map((item) => item.concat('-pro'));
      setValue('fonts', watchFontsRevised);
    }
  }, [watchFontVersion]);

  // Close the modal on browser back button and prevent from moving to another page
  const onRouteChangeStart = useCallback(() => {
    if (!isOpen) {
      return true;
    }

    setClosed();
    router.events.emit('routeChangeError'); // This will signal next.js to cancel the route change
    router.replace(router.asPath); // Preserve the url
    throw 'Route change aborted';
  }, [isOpen]);

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [onRouteChangeStart]);

  // Reset form values on route change
  useEffect(() => {
    resetForm();
  }, [router.asPath]);

  const fontPrice = (product) => {
    const price = {
      ...product.pricing.price.net,
      amount: product.pricing.price.net.amount * (multiplierValue || 1)
    };
    return formatPrice(price);
  };

  /**
   * Raw price of font
   * @param product
   * @returns price
   */
  const fontPriceRaw = (product) => {
    const price = {
      amount: product.pricing.price.net.amount * (multiplierValue || 1)
    };
    return price.amount;
  };

  /**
   * Format Total Price
   * @param value
   * @returns price
   */
  const formatTotal = (value) => {
    const price = {
      ...product.pricing.priceRange.start.net,
      amount: value * (multiplierValue || 1)
    };
    return formatPrice(price);
  };

  const updateMultipliersDefault = (licence) => {
    const initialVal = Object.values(licence.variants).map(
      //@ts-ignore
      (el) => el.options.find((item) => item?.default === true)
    );
    if (initialVal.filter(Boolean).length > 0) {
      initialVal.map((val) => {
        setMultipliers([
          ...multipliers,
          {
            licenceMultiplier: val.multiply,
            licenceMultiplierName: val.name,
            licenceName: licence.name,
            licenceVariant: licence.name
          }
        ]);
      });
    }
  };

  /**
   * Update the licence data
   * @param licence
   */
  const updateSelectedLicences = (licence) => {
    // Needs to be written better
    const licenceInState = selectedLicences.some((variant) => variant.slug === licence.slug);
    if (!licenceInState) {
      updateMultipliersDefault(licence);
      setSelectedLicences([...selectedLicences, licence]);
      setIsChecked({
        ...isChecked,
        [licence.slug]: {
          checked: true,
          name: licence.slug
        }
      });
    } else {
      const updatedLicences = selectedLicences.filter((variant) => variant.slug !== licence.slug);
      const maxUsageApplies = getMaxUsageApplies(
        maxUsageValues,
        null,
        multipliers,
        updatedLicences
      );
      removeLicenceMultiplier(multipliers, licence);
      setSelectedLicences(updatedLicences);
      setMaxUsageAmountError(maxUsageApplies);

      setIsChecked({
        ...isChecked,
        [licence.slug]: {
          checked: false,
          name: licence.slug
        }
      });

      handleRemoveLicenceInForm(licence);
    }
  };

  const onErrorAlertCallback = async () => {
    resetForm();
    await signOut();
    await resetCheckoutToken();
    setIsOpen(false);
    resetState();
  };

  /**
   * Handle Add to Cart
   */
  const onAddToCartSubmit = handleSubmit(async (formData) => {
    setIsAddedToCart(false);
    setIsLoading(true);

    const handleFail = (err = null) => {
      if (err) setAddToCartResponse(err);
      setIsAddedToCart(false);
      setIsLoading(false);
      setAddToCartError(true);
      setAddToCartResult('error');
    };

    try {
      const filteredVariants = variants.filter((variant) =>
        cartFontsList.some((font) => font.sku === variant.sku)
      );

      const requiredVariants = filteredVariants.map((filteredVariant) => {
        return {
          id: filteredVariant.id,
          sku: filteredVariant.sku,
          basePrice: filteredVariant.pricing.price.net.amount,
          calculatedPriceWithSign: fontPrice(filteredVariant),
          calculatedPrice: fontPriceRaw(filteredVariant)
        };
      });

      const licences = [
        formData.licenceDesktop && JSON.parse(formData.licenceDesktop),
        formData.licenceWeb && JSON.parse(formData.licenceWeb),
        formData.licenceApp && JSON.parse(formData.licenceApp)
        // formData.licenceBroadcast && JSON.parse(formData.licenceBroadcast),
        // formData.licenceElectronic && JSON.parse(formData.licenceElectronic)
      ];

      const filteredLicences = licences.filter((licence) => licence);

      // Ensure add to cart cannot be triggered with no licence data
      if (filteredLicences.length < 1 || !formData.licenceHolderName) {
        handleFail();
        return;
      }

      const addTypefaceToCartBody = JSON.stringify({
        ...formData,
        variants: requiredVariants,
        licences: filteredLicences,
        email: user?.email || 'anonymous@example.com',
        userId: user?.id || null,
        licenceeData: {
          licenceHolderName: formData.licenceHolderName,
          licenceHolderEmail: formData.licenceHolderEmail,
          licenceHolderCompany: formData.licenceHolderCompany || ''
        },
        channel: currentChannel.slug,
        oldCheckout: checkout || {},
        checkoutToken: checkoutToken,
        s3Family: getFontMetaS3Family(product)
      });

      const response = await fetch('/api/saleor-app/add-to-cart', {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: addTypefaceToCartBody
      });

      const responseJson = await response.json();

      const token = responseJson?.checkout?.token;

      if (token) {
        setCheckoutToken(token);
        setAddToCartResponse(responseJson);
        setIsAddedToCart(true);
        setAddToCartResult('success');
        refetch();
        resetForm();
        setClosed();
        setIsLoading(false);

        const lineItems = filteredVariants.map((lineItem) => {
          return {
            item_id: lineItem.sku,
            item_name: lineItem.name,
            price: fontPriceRaw(lineItem),
            quantity: 1
          };
        });
        pushAddToCartDataLayer({ lines: lineItems });
      } else {
        handleFail();
      }
    } catch (err) {
      handleFail(err);
    }
  });

  // Remove font items in the cart.
  const handleRemoveItem = (cartFont) => {
    const remainingFonts = cartFontsList
      .filter((item) => item.sku !== cartFont.sku)
      .map((item) => item.sku);
    setValue('fonts', remainingFonts);
  };

  const handleRemoveLicenceInForm = (licence) => {
    const variantArray = Object.values(licence.variants);
    variantArray.map((licenceVariant: TypefaceVariant) => {
      setValue(`licence${capitalizeFirstLetter(licenceVariant.name)}`, undefined);
    });
  };

  if (variants.length < 1) {
    return <div>No Variants!</div>;
  }

  const FontFamilyRender = ({ variantGroupings, availableProFonts }) => {
    const availableProFontsNotVariable = availableProFonts.filter(
      (variant) => !variant.sku.includes('variable')
    );

    const availableProFontsVariable = availableProFonts.filter((variant) =>
      variant.sku.includes('variable')
    );

    return (
      <>
        {variantGroupings.map((variantGroup, i) => {
          // Load all the web fonts
          // Use pro versions
          const fonts = fontsByGroup({
            variants: availableProFontsNotVariable,
            variantGroup,
            groupVariantsByMeta: true
          }).filter((variant) => isNotFamilyVariant(variant));

          const fontFamilyGroups = availableProFontsNotVariable.filter((variant) =>
            isFamilyVariant(variant)
          );

          return (
            <React.Fragment key={i}>
              <>
                {fonts.map((font) => {
                  return (
                    <FontLoader
                      key={font.name}
                      fontName={getFontS3Sku(font.sku)}
                      fullPath
                      s3Path={generateVariantS3Path(product.slug, font.sku)}
                    />
                  );
                })}
                {availableProFontsVariable.map((variableFont) => {
                  return (
                    <FontLoader
                      key={variableFont.name}
                      fontName={getFontS3Sku(variableFont.sku)}
                      fullPath
                      s3Path={generateVariantS3Path(product.slug, variableFont.sku)}
                    />
                  );
                })}
                {fontFamilyGroups.map((fontFamilyGroup) => (
                  <FontLoader
                    key={fontFamilyGroup.sku}
                    fontName={fontFamilyGroup.sku}
                    fullPath
                    s3Path={generateFamilyVariantS3Path(product.slug, fontFamilyGroup)}
                  />
                ))}
              </>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const ErrorModalContent = () => (
    <div className={styles.errorModal}>
      <p>Sorry, an error occured. Please close, and try again.</p>
      <Button type="button" callBack={onErrorAlertCallback}>
        Close
      </Button>
    </div>
  );

  return (
    <>
      <FontFamilyRender
        variantGroupings={haveVariantDataGroups ? variantDataGroups : variantGroupings}
        availableProFonts={availableProFonts}
      />
      <AddToCartModal
        isOpen={isOpen}
        closeTimeoutMS={500}
        shouldReturnFocusAfterClose
        className={styles.addToCartModal__Content}
        bodyOpenClassName={styles.addToCartModalBody}
        overlayClassName={styles.addToCartModal}
      >
        <div
          className={styles.addToCartModal__ContentContainer}
          data-cy="typeface-add-to-cart-modal"
        >
          {loading && <Spinner />}
          {addtoCartError && (
            <Toast isError message={<ErrorModalContent />} size="regular" position="top" />
          )}

          <div className={styles.closeModal}>
            <Button variant="ghost" callBack={() => setClosed()}>
              <Icon iconName="close" />
            </Button>
          </div>

          <div className={styles.progressBar} data-cy="typeface-add-to-cart-progress">
            {steps.map((stepper, index) => (
              <div key={stepper.label}>
                <div className={styles.progressBarItems}>
                  <div
                    className={
                      step === index
                        ? styles.stepperCircleActive
                        : step > index
                        ? styles.stepperCircleDone
                        : styles.stepperCircle
                    }
                  >
                    <span>{index + 1}</span>
                  </div>
                  <div className={styles.stepperLabel + ' u-h6'}>{stepper.label}</div>
                </div>
              </div>
            ))}
          </div>

          <div className={styles.progressBarMobile}>
            {steps.map((stepper, index) => (
              <div
                key={stepper.label}
                className={step === index ? styles.stepperLabelMobile : styles.hidden}
              >
                {stepper.label}
              </div>
            ))}
          </div>

          <Grid>
            <Column sm={0} md={0} lg={0} xl={1} />
            <Column sm={12} md={12} lg={6} xl={6}>
              <form className={styles.addTypefaceForm}>
                {stepActive('fontStyles') && (
                  <VariantGroupings
                    product={product}
                    content={steps[stepIndex('fontStyles')].content(product.name)}
                    variantGroupings={haveVariantDataGroups ? variantDataGroups : variantGroupings}
                    variants={variantsForSelection}
                    groupVariantsByMeta={haveVariantDataGroups}
                    register={register}
                    errors={errors}
                    fontsByGroup={fontsByGroup}
                    fontFamilyGroups={fontFamilyGroups({ variants: variantsToFilter, checkout })}
                    watchFonts={watchFonts}
                  />
                )}
                {stepActive('fontVersion') && (
                  <>
                    <LicenceVersions
                      content={steps[stepIndex('fontVersion')].content(null)}
                      register={register}
                    />
                    <AddToCartInstruction
                      variants={variantsAndFamiliesForSelection}
                      cartFontsList={cartFontsList}
                      watchFontVersion={watchFontVersion}
                    />
                  </>
                )}
                {stepActive('licencePlatform') && (
                  <LicenceStep
                    register={register}
                    content={steps[stepIndex('licencePlatform')].content(null)}
                    isChecked={isChecked}
                    licenceArray={licenceArray}
                    capitalizeFirstLetter={capitalizeFirstLetter}
                    updateSelectedLicences={updateSelectedLicences}
                    errors={errors}
                  />
                )}
                {stepActive('licenceHolder') && (
                  <LicenceHolder
                    content={steps[stepIndex('licenceHolder')].content(null)}
                    register={register}
                    errors={errors}
                  />
                )}
              </form>
            </Column>
            {!isTouchDevice && (
              <Column sm={0} md={12} lg={6} xl={5}>
                <Summary
                  product={product}
                  cartFontsList={cartFontsList}
                  fontPrice={fontPrice}
                  handleRemoveItem={handleRemoveItem}
                  formatTotal={formatTotal}
                  cartSubTotalAmount={cartSubTotalAmount}
                  cartTotalAmount={cartTotalAmount}
                  allowEditing={stepActive('fontStyles')}
                />
              </Column>
            )}
          </Grid>
          <div className={styles.bottomSpacer}></div>
        </div>
        <StepNavigation
          steps={steps}
          onAddToCartSubmit={onAddToCartSubmit}
          isAddedToCart={isAddedToCart}
          watchFonts={watchFonts}
          control={control}
          isTouchDevice={isTouchDevice}
          stepActive={stepActive}
          setValue={setValue}
        >
          {isTouchDevice && (
            <Summary
              product={product}
              cartFontsList={cartFontsList}
              fontPrice={fontPrice}
              handleRemoveItem={handleRemoveItem}
              formatTotal={formatTotal}
              cartSubTotalAmount={cartSubTotalAmount}
              cartTotalAmount={cartTotalAmount}
              allowEditing={stepActive('fontStyles')}
            />
          )}
        </StepNavigation>
      </AddToCartModal>
    </>
  );
};
