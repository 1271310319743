import { useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';

import Button from '@/components/global/Button';
import Icon from '@/components/global/Icon';
import { DEFAULT_ARROW_ICON_SIZES } from '@/components/global/Icon/defaultIconSizes';
import { useTypefaceAddToCart } from '@/components/providers/TypefaceProvider';
import fontLicences from '@/lib/fonts/fontLicences';

import styles from './StepNavigation.module.scss';

const StepNavigation = ({
  steps,
  onAddToCartSubmit,
  isAddedToCart,
  watchFonts,
  isTouchDevice,
  control,
  stepActive,
  setValue,
  children
}) => {
  const [summaryVisible, setSummaryVisible] = useState(false);
  const summaryRef = useRef<HTMLDivElement>();

  const { state, setStep } = useTypefaceAddToCart();

  const { maxUsageValues, step, selectedLicences } = state;

  const watchLicenceDesktop = useWatch({ name: 'licenceDesktop', control });
  const watchLicenceWeb =
    useWatch({ name: 'licenceWeb', control }) ||
    `{"licenceName": ${fontLicences.web?.name},"licenceVariant": ${fontLicences.web?.name},"licenceMultiplier": ${fontLicences.web?.variants?.web?.options[1]?.multiply}, "licenceMultiplierName":${fontLicences.web?.variants?.web?.options[1]?.name}}`;
  const watchLicenceApp = useWatch({ name: 'licenceApp', control });
  // const watchLicenceAppBroadcast = useWatch({ name: 'licenceBroadcast', control });
  // const watchLicenceAppElectronic = useWatch({ name: 'licenceElectronic', control });
  const watchLicenceHolderName = useWatch({ name: 'licenceHolderName', control });
  const watchLicenceHolderEmail = useWatch({ name: 'licenceHolderEmail', control });

  const nextDisabled = () => {
    if (stepActive('fontStyles')) {
      return watchFonts[0].length < 1;
    }

    if (stepActive('licencePlatform')) {
      const watchList = [
        watchLicenceDesktop,
        watchLicenceWeb,
        watchLicenceApp
        // watchLicenceAppBroadcast,
        // watchLicenceAppElectronic
      ];
      const formList = watchList.filter((licenceData) => {
        if (licenceData) {
          let licenceValue;
          try {
            const itemData = JSON.parse(licenceData);
            licenceValue = itemData?.licenceMultiplierName;
          } catch {
            // fail silently... shh
          }

          const maxUsageApplies = !maxUsageValues.includes(licenceValue);
          return licenceData !== undefined && licenceData !== '' && maxUsageApplies;
        }
      });
      const selected = selectedLicences.reduce((p, n) => p.concat(Object.keys(n.variants)), []);

      // Checks if selected licence matches the length of selected values from the select options
      if (selected.length > 0 && formList.length > 0) {
        return selected.includes('web')
          ? !(selected.length === formList.length)
          : !(selected.length === formList.length - 1);
      }
      // Disable
      return true;
    }
  };

  const addToCartDisabled = () => {
    if (!watchLicenceHolderName || !watchLicenceHolderEmail) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.container}>
      <div className={styles.stepNavigation}>
        <div className={styles.back}>
          {step > 0 && (
            <Button
              type="button"
              variant="secondary"
              size="xl"
              callBack={() => {
                setStep(step - 1);
              }}
              data-cy="back"
            >
              <span>←</span>
              <span className={styles.backLabel}> Back</span>
            </Button>
          )}
        </div>
        {isTouchDevice && (
          <div className={styles.summaryButton}>
            <Button
              type="button"
              variant="secondary"
              size="l"
              align="center"
              callBack={() => {
                setSummaryVisible(true);
              }}
            >
              &nbsp;&nbsp;&nbsp; Summary &nbsp;&nbsp;&nbsp;
            </Button>
          </div>
        )}
        <div className={styles.next}>
          {step < steps.length - 1 ? (
            <Button
              type="button"
              variant="primary"
              size="xl"
              disabled={nextDisabled()}
              callBack={() => {
                if (stepActive('fontStyles') && watchFonts[0].length > 0) {
                  return setStep(step + 1);
                }

                if (stepActive('licencePlatform') && watchFonts[0].length > 0) {
                  if (
                    watchLicenceDesktop ||
                    watchLicenceWeb ||
                    watchLicenceApp
                    // || watchLicenceAppElectronic ||
                    // watchLicenceAppBroadcast
                  ) {
                    return setStep(step + 1);
                  }

                  return false;
                }

                return setStep(step + 1);
              }}
              data-cy="next"
            >
              <div className={styles.nextLabel}>Continue </div>
              <span>→</span>
            </Button>
          ) : (
            <Button
              disabled={addToCartDisabled() || isAddedToCart}
              type="button"
              variant="primary"
              size="xl"
              className={styles.addtocart}
              callBack={() => {
                if (stepActive('licenceHolder') && watchFonts[0].length > 0) {
                  if (
                    watchLicenceDesktop ||
                    watchLicenceWeb ||
                    watchLicenceApp
                    // ||
                    // watchLicenceAppElectronic ||
                    // watchLicenceAppBroadcast
                  ) {
                    onAddToCartSubmit();
                  }
                }
              }}
            >
              <div className={styles.nextLabel}>Add to Cart</div>
              {isTouchDevice && <Icon iconName="cart" />}
            </Button>
          )}
        </div>
      </div>
      {summaryVisible && (
        <div ref={summaryRef} className={styles.navigationSummary}>
          <div className={styles.navigationSummaryClose}>
            <Button
              type="button"
              variant="ghost"
              callBack={() => {
                summaryRef.current.classList.add(styles.closeAnimation);
                setTimeout(() => setSummaryVisible(false), 200);
              }}
            >
              <Icon iconName="close" />
            </Button>
          </div>
          {children}
        </div>
      )}
    </div>
  );
};

export default StepNavigation;
