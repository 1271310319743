import clsx from 'clsx';
import dynamic from 'next/dynamic';
import Image from 'next/image';

import Icon from '../../Icon';
import styles from '../Toast.module.scss';
import type { DefaultToastContentProps } from '../types';
import { CloseButton } from './';

export const DefaultToastContent: React.FC<DefaultToastContentProps> = ({
  size,
  position,
  icon,
  image,
  isError,
  toastMessage,
  actions,
  handleClose,
  type
}) => {
  const ToastIcons = dynamic(() => import('./ToastIcons'));

  return (
    <div
      className={clsx(styles.Toast__inner, {
        [styles['Toast__inner--medium']]: size === 'medium',
        [styles['Toast__inner--top']]: position === 'top'
      })}
    >
      {icon ? (
        <div className={styles.Toast__icon}>
          <ToastIcons iconName={icon} />
        </div>
      ) : null}
      {image ? (
        <div className={styles.Toast__icon}>
          <Image src={`/img/${image}`} alt="" width="337" height="299" loading="eager" priority />
        </div>
      ) : null}
      <div
        className={clsx(styles.Toast__message, {
          [styles['Toast__message--medium']]: size === 'medium'
        })}
      >
        {isError && <Icon iconName="close-circle" color="var(--error)" />}
        {toastMessage}
        {actions && <div className={styles.Toast__actions}>{actions}</div>}
      </div>

      {!isError && <CloseButton type={type} handleClose={handleClose} />}
    </div>
  );
};
