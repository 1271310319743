import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';

import Modal from '../Modal/Modal';
import styles from './Card.module.scss';

type NextUrlType = {
  pathname: string;
  hash?: string;
  query?: any;
};

interface CardModal {
  modalTitle?: string;
  modalContent: React.ReactNode;
  modalButtonLabel?: string;
  maxWidth?: string;
  contentOutsideModal?: React.ReactNode;
}

interface IProps {
  children: React.ReactNode;
  tags?: React.ReactNode;
  variant?: string;
  layout?: 'flex' | 'flex-center';
  link?: string | NextUrlType;
  growChild?: string;
  cardHoverEffect?: boolean;
  fullHeight?: boolean;
  cssVars?: string;
  modal?: CardModal;
  renderCardAsLink?: boolean;
  disableBackground?: boolean;
  imageAsBackground?: boolean;
  modalControlled?: boolean;
  modalControlledOpen?: boolean;
  modalControlledSetOpen?: Function;
  grayscaleEffect?: boolean;
}

const parseCSSvars = (vars) => {
  if (!vars) return;
  const style = {};
  const lines = vars
    .replace(/(\r\n|\r|\n)/gm, '')
    .replace(/(\s)/gm, ' ') // Replace the spaces so they won't turn to &nbsp; when applied to the style
    .split(';');
  lines.forEach((line) => {
    if (!line) return;
    const pair = line.split(':');
    if (pair.length === 2) style[pair[0]] = pair[1].trim();
  });
  return style;
};

const Card: React.FC<IProps> = ({
  children,
  tags,
  variant,
  layout,
  growChild,
  link,
  cardHoverEffect,
  fullHeight,
  cssVars,
  modal,
  disableBackground = false,
  imageAsBackground = false,
  modalControlled = false,
  modalControlledOpen = false,
  modalControlledSetOpen,
  grayscaleEffect = false
}) => {
  const [showModal, setShowModal] = useState(false);

  function openModal() {
    modalControlled ? modalControlledSetOpen(true) : setShowModal(true);
  }

  function closeModal() {
    modalControlled ? modalControlledSetOpen(false) : setShowModal(false);
  }

  const _style = parseCSSvars(cssVars);

  let cardContent;
  // wrap card in link
  if (link)
    cardContent = (
      <Link href={link}>
        <a
          className={clsx(
            styles.link,
            styles.content,
            layout && styles[`layout-${layout}`],
            growChild && styles[`grow-${growChild}`]
          )}
        >
          {children}
        </a>
      </Link>
    );

  // wrap in button for modal
  if (modal)
    cardContent = (
      <>
        <button
          type="button"
          style={_style}
          className={clsx(
            styles.content,
            styles.buttonWrapper,
            layout && styles[`layout-${layout}`],
            growChild && styles[`grow-${growChild}`]
          )}
          onClick={modal ? () => openModal() : null}
        >
          {children}
        </button>
        {modal.contentOutsideModal}
      </>
    );

  if (!cardContent)
    cardContent = (
      <div
        className={clsx(
          styles.content,
          layout && styles[`layout-${layout}`],
          growChild && styles[`grow-${growChild}`]
        )}
      >
        {children}
      </div>
    );

  return (
    <>
      <article
        className={clsx(
          styles.container,
          variant && styles[`v-${variant}`],
          cardHoverEffect && styles.cardHoverEffect,
          fullHeight && styles.fullHeight,
          disableBackground && styles.disableBackground,
          imageAsBackground && styles.cardImageAsBackground,
          grayscaleEffect && styles.grayscaleEffect
        )}
        style={_style}
      >
        {cardContent}
        {tags && <div className={styles.tags}>{tags}</div>}
      </article>
      {modal && (
        <Modal
          cardModal
          maxWidth={modal.maxWidth || '1140px'}
          controlled
          controlledOpen={modalControlled ? modalControlledOpen : showModal}
          controlledSetOpen={modalControlled ? modalControlledSetOpen : setShowModal}
          ModalTitle={modal.modalTitle}
          ModalButtonLabel={modal.modalButtonLabel}
        >
          {modal.modalContent}
        </Modal>
      )}
    </>
  );
};

export default Card;
