import { useCallback, useEffect, useState } from 'react';

import {
  SCREEN_SIZE_DESKTOP_MAX,
  SCREEN_SIZE_DESKTOP_MIN,
  SCREEN_SIZE_LARGEDESKTOP_MIN,
  SCREEN_SIZE_MOBILE_MAX,
  SCREEN_SIZE_TABLET_MAX,
  SCREEN_SIZE_TABLET_MIN
} from '@/lib/constants';

let didInit = false;

/* eslint-disable react-hooks/rules-of-hooks */
const useMediaQuery = (media: string): boolean => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    if (!didInit) {
      didInit = true;
    }

    const matchMedia = window.matchMedia(media);

    if (matchMedia?.addEventListener) {
      matchMedia.addEventListener('change', (e) => updateTarget(e));
    } else {
      matchMedia.addListener(updateTarget);
    }

    // Check on mount (callback is not called until a change occurs)
    if (matchMedia && matchMedia.matches) {
      setTargetReached(true);
    }

    return () => {
      didInit = false;

      if (matchMedia?.addEventListener) {
        return matchMedia.removeEventListener('change', (e) => updateTarget(e));
      } else {
        return matchMedia.removeListener(updateTarget);
      }
    };
  }, []);

  return targetReached;
};

export const useScreenSize = () => {
  const screenSizes = [
    {
      name: 'Mobile',
      matching: useMediaQuery(`(max-width: ${SCREEN_SIZE_MOBILE_MAX})`)
    },
    {
      name: 'Tablet',
      matching: useMediaQuery(
        `(min-width: ${SCREEN_SIZE_TABLET_MIN}) and (max-width: ${SCREEN_SIZE_TABLET_MAX})`
      )
    },
    {
      name: 'Desktop',
      matching: useMediaQuery(
        `(min-width: ${SCREEN_SIZE_DESKTOP_MIN}) and (max-width: ${SCREEN_SIZE_DESKTOP_MAX})`
      )
    },
    {
      name: 'LargeDesktop',
      matching: useMediaQuery(`(min-width: ${SCREEN_SIZE_LARGEDESKTOP_MIN})`)
    }
  ];

  const matchingScreen = screenSizes.find((size) => size.matching);
  return matchingScreen ? matchingScreen.name : '';
};
/* eslint-enable react-hooks/rules-of-hooks */

export default useMediaQuery;
