import { ReactNode, useEffect, useState } from 'react';

interface Props {
  ref?: React.RefObject<HTMLDivElement>;
  options?: {
    threshold: number;
  };
}

export function useIsVisible({ ref, options }: Props): Boolean {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting),
      options
    );
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
