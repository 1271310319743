import clsx from 'clsx';

import styles from './Tag.module.scss';

type Props = {
  label: string;
  variant?: 'error' | 'default';
  eventHandler?: (string) => void;
  className?: string;
};
const Tag = ({ label, variant, eventHandler, className }: Props) => {
  return eventHandler ? (
    <button
      onClick={() => {
        eventHandler(label);
      }}
      type="button"
      className={clsx(styles.tag, styles.tagAsButton, {
        [styles.error]: variant === 'error',
        [className]: !!className
      })}
    >
      {label}
    </button>
  ) : (
    <span className={clsx(styles.tag, { [styles.error]: variant === 'error' })}>{label}</span>
  );
};

export default Tag;
