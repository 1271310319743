import Radio from '@/components/global/Radio';

import styles from './ModalStyles.module.scss';
import { LicenceVersionModal } from './partials';

const LicenceVersions = ({ content, register }) => {
  return (
    <div>
      <h5 className={styles.stepContent}>{content}</h5>
      <div className={styles.stepContainer}>
        <div>
          <Radio
            name="fontVersion"
            value="Pro"
            tag="Recommended"
            id="Pro"
            label="Professional (Pro)"
            isPanel
            subLabel="Professional ('PRO') fonts contain further stylistic sets, OpenType features and can also include further scripts, such as Cyrillic and Greek."
            {...register('fontVersion')}
          />
        </div>
        <div>
          <Radio
            name="fontVersion"
            value="Std"
            id="Std"
            label="Standard (Std)"
            isPanel
            subLabel="Standard ('STD') fonts contain our base CF-Standard character map that supports Eastern and Western European latin languages."
            {...register('fontVersion')}
          />
        </div>
        <LicenceVersionModal />
      </div>
    </div>
  );
};

export default LicenceVersions;
