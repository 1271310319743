import clsx from 'clsx';
import { ChangeEventHandler, forwardRef, useRef, useState } from 'react';

import Icon from '@/components/global/Icon';
import InputErrorMessage from '@/components/global/InputErrorMessage';
import InputLabel from '@/components/global/InputLabel';

import Button from '../Button';
import styles from './InputField.module.scss';

type InputTypes = 'text' | 'number' | 'password' | 'date' | 'email' | 'tel' | 'url';

interface InputFieldProps {
  type: InputTypes;
  name: string;
  label?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: any;
  hasLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  hasBorder?: boolean;
  error?: string;
  value?: string;
  min?: any;
  pattern?: string;
  onWhite?: boolean;
  hasMobileKeyboard?: boolean;
  mobileKeyboardClickEvent?: (method: string) => void;
  disablePointerEvents?: boolean;
  valuePrefix?: string;
  className?: string;
  defaultValue?: string;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      type,
      name,
      label,
      placeholder,
      onChange,
      onFocus,
      onKeyPress,
      onBlur,
      hasLabel,
      isRequired,
      isDisabled,
      hasBorder,
      error,
      value,
      min,
      pattern,
      onWhite,
      hasMobileKeyboard,
      mobileKeyboardClickEvent,
      disablePointerEvents = false,
      valuePrefix,
      defaultValue,
      className
    },
    ref
  ) => {
    const [showPass, setShowPass] = useState(true);
    const toggleShow = () => setShowPass((show) => !show);
    const inputRef = useRef(null);

    if (inputRef?.current?.type && type === 'password') {
      if (!showPass) {
        inputRef.current.type = 'text';
      } else {
        inputRef.current.type = 'password';
      }
    }

    const input = (
      <div className={clsx(valuePrefix && styles.InputPrefixWrapper)}>
        {valuePrefix && <span className={styles.InputPrefix}>{valuePrefix}</span>}
        <input
          className={clsx(styles.InputField, className, {
            [styles.InputFieldError]: error,
            [styles.InputFieldBorder]: hasBorder && !error,
            [styles.InputFieldIsDisabled]: isDisabled,
            [styles.InputFieldIsOnWhite]: onWhite,
            [styles.InputFieldPointerEventsDisabled]: disablePointerEvents
          })}
          type={type}
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          required={isRequired}
          disabled={isDisabled}
          ref={(element) => {
            inputRef.current = element;
            // This expression is not callable.
            // Not all constituents of type 'ForwardedRef<HTMLInputElement>' are callable.
            // Type 'MutableRefObject<HTMLInputElement>' has no call signatures.
            // @ts-ignore
            return ref ? ref(element) : null;
          }}
          value={value}
          min={min}
          pattern={pattern}
          data-cy={name}
          defaultValue={defaultValue}
        />
      </div>
    );

    return (
      <div className={styles.InputFieldContainer}>
        {label && <InputLabel name={name} label={label} isVisible={hasLabel} error={error} />}
        {hasMobileKeyboard && type === 'number' ? (
          <div className={styles.InputMobileKeyboardWrapper}>
            {input}
            <Button variant="ghost" callBack={() => mobileKeyboardClickEvent('plus')}>
              <Icon iconName="plus-circle" />
              <span className="sr-only">Plus</span>
            </Button>
            <Button variant="ghost" callBack={() => mobileKeyboardClickEvent('minus')}>
              <Icon iconName="minus-circle" />
              <span className="sr-only">Minus</span>
            </Button>
          </div>
        ) : (
          input
        )}
        {type === 'password' && (
          <div
            className={clsx(styles.InputIcon, {
              [styles.InputIcon__Error]: error
            })}
            onClick={toggleShow}
          >
            <Icon iconName={showPass ? 'eye-shut' : 'eye'} />
          </div>
        )}
        {error && <InputErrorMessage error={error} />}
      </div>
    );
  }
);

InputField.displayName = 'InputField';

export default InputField;
