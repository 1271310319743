import { useState } from 'react';

import Button from '@/components/global/Button';
import Modal, {
  BasicModalContent,
  ModalMessageBody,
  ModalMessageScrollCarousel
} from '@/components/global/Modal';
import {
  BasicToastContent,
  ToastMessageBody,
  ToastMessageScrollCarousel
} from '@/components/global/Toast';

import styles from './InfoModal.module.scss';

const LicenceTypeModal = () => {
  const [isShowingModal, setIsShowingModal] = useState<boolean>(false);

  const hideModal = () => {
    setIsShowingModal(false);
  };

  const showModal = () => {
    setIsShowingModal(true);
  };

  const handleClose = () => {
    hideModal();
    document.body.classList.remove('ReactModal__Body--open');
  };

  return (
    <div className={styles.infoModalContainer}>
      <Button callBack={showModal} variant="asLink" type="button">
        Learn about licensing from Colophon
      </Button>
      {isShowingModal && (
        <Modal
          asWrapper
          controlled
          controlledOpen={isShowingModal}
          controlledSetOpen={handleClose}
          maxWidth="100%"
          modalPadding="0"
          ModalButtonLabel="Learn about licensing from Colophon"
          buttonVariant="asLink"
        >
          <BasicToastContent
            position="center"
            handleClose={handleClose}
            type="basic"
            title="License Platforms"
            toastMessage={
              <>
                <ToastMessageBody borderBottom>
                  <p>
                    Our licenses are available based on usage. You can upgrade at any time, and
                    increase the tier level of the license. Need licensing beyond what&apos;s
                    available here? Contact us at{' '}
                    <a href="mailto:abc@colophon-foundry.org">abc@colophon-foundry.org</a>.
                  </p>
                </ToastMessageBody>

                <ToastMessageScrollCarousel
                  className={styles.ScrollCarousel}
                  slides={getLicenseModalData()}
                />
              </>
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default LicenceTypeModal;

export const getLicenseModalData = () => [
  {
    title: (
      <div className={styles.LicenseModalHeader}>
        <h5>Desktop License</h5>
      </div>
    ),
    body: (
      <p>
        A desktop license enables a font to be installed on a computer. This license is measured on
        the number of users who have the font installed. Create and print documents, as well as
        static images (.jpeg, .tiff, .png), even if the images are used on a website or within a
        mobile app.
        <br />
        <br />
        File Formats Delivered
        <br />
        <span className={styles.fileTypes}>.OTF</span>
        <br />
        <br />
        <Button
          size="s"
          className={styles.LicenseModalButton}
          variant="primary"
          href="/docs/Colophon-Foundry-Desktop-EULA.pdf"
          target="_blank"
        >
          Download Desktop EULA
        </Button>
      </p>
    ),
    img: '/icon/desktop.svg'
  },
  {
    title: (
      <div className={styles.LicenseModalHeader}>
        <h5>Web License</h5>
      </div>
    ),
    body: (
      <p>
        A web license (or self-hosting web license) enables the font to be utilized in live text on
        a website, by uploading the typeface to a web server and referencing it via @font-face. This
        is an annual license, and is measured on the number of page views per month one website
        receives. Each license is for one website.
        <br />
        <br />
        File Formats Delivered
        <br />
        <span className={styles.fileTypes}>.WOFF2 .WOFF .TTF</span>
        <br />
        <br />
        <Button
          size="s"
          className={styles.LicenseModalButton}
          variant="primary"
          href="/docs/Colophon-Foundry-Web-EULA.pdf"
          target="_blank"
        >
          Download Web EULA
        </Button>
      </p>
    ),
    img: '/icon/web.svg'
  },
  {
    title: (
      <div className={styles.LicenseModalHeader}>
        <h5>App License</h5>
      </div>
    ),
    body: (
      <p>
        An app license is for when you are developing an app for iOS, Android, or Windows Phone, and
        you will be embedding the font file in your mobile application&apos;s code. This is an
        annual license for apps with up to 25,000 registered users. Need licensing beyond
        what&apos;s available online? Contact us at{' '}
        <a href="mailto:abc@colophon-foundry.org">abc@colophon-foundry.org</a>
        .
        <br />
        <br />
        File Formats Delivered
        <br />
        <span className={styles.fileTypes}>.OTF</span>
        <br />
        <br />
        <Button
          size="s"
          className={styles.LicenseModalButton}
          variant="primary"
          href="/docs/Colophon-Foundry-App-EULA.pdf"
          target="_blank"
        >
          Download App EULA
        </Button>
      </p>
    ),
    img: '/icon/app.svg'
  }
];
